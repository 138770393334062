import { action, observable, reaction, makeObservable } from "mobx";

export default class ProductsStore
{
  store;
  currency;
  products;
  assetCount = 0;
  disposers = [];

  _updatePromise = undefined;

  constructor(store, products)
  {
    makeObservable(this, {
      products: observable,
      currency: observable,
      assetCount: observable,
      update: action,
      hydrate: action,
    });

    this.store = store;
    this.disposers.push(reaction(
      () => store.content.items.length,
      () => {
        this.update();
      }
    ));

    if(products)
      this.hydrate(products);
  }

  hydrate(data)
  {
    this.currency = data.currency
    this.products = data.products;
    this.assetCount = data.assetCount;
  }

  findPhotobook(props)
  {
    const matchValues = Object.entries(props);
    if(this.products===undefined || this.products.photobook===undefined)
      return;

    return this.products.photobook.find(photobook =>
    {
      return matchValues.every(([key, value]) =>
      {
        return photobook[key] === value;
      });
    });
  }

  update()
  {
    if(!this._updatePromise) {
      const url = `${process.env.API_URL}/album/${this.store.album.key}/products`;
      this._updatePromise = this.store.user.fetch(url);

      this._updatePromise
      .then(response => this.hydrate(response.data))
      .catch(() => {
        this.hydrate({
          currency: "EUR",
          products: {},
          assetCount: 0,
        });
      })
      .finally(() => {
        this._updatePromise = undefined;
      });
    }
  }

  dispose()
  {
    this.disposers.forEach(dispose => dispose());
  }
}