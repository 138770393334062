import { useContext } from 'react';
import { Observer } from 'mobx-react'
import AlbumContext from '../stores/album/context'
import Head from 'next/head';
import { NextSeo } from 'next-seo';

function AlbumHead() {
  /**
   * @type {import("stores/album/root-store").default}
   */
  const store = useContext(AlbumContext);

  return (
    <Observer>
    {
      () => (
        <>
          <Head>
            <title>{store.album.title}</title>
          </Head>
          <MetaOGTags album={store.album} />
        </>
      )
    }
    </Observer>
  );
}

function coverImage(thumbSizes)
{
  const targetSize = {width: 1200, height: 800};
  const image = {url: "", width: 0, height: 0};

  thumbSizes.forEach(size => {
    if(
      (size.width >= image.width && image.width <= targetSize.width) ||
      (size.height >= image.height && image.height <= targetSize.height)
    )
    {
      image.url = size.url;
      image.width = size.width;
      image.height = size.height;
    }
  });

  return image;
}

/**
 * @param {object} props
 * @param {import("stores/album/album-store").default} props.album
 */
function MetaOGTags(props)
{
  const {album} = props;

  const title = album.title;
  const description = " "; // TODO: Get subtitle?
  const image = (typeof album.thumbs !== "undefined" && album.thumbs !== null && (Object.keys(album.thumbs).length > 0)) ? coverImage(album.thumbs.landscape.sizes) : null;
  const link = `${process.env.SITE_URL}/album/${album.key}/`;

  const noIndexNoFollow = album.settings.searchIndexEnabled === false || album.access?.level === "private";

  return (
    <Observer>
    {
      () => (
        <>
          <NextSeo
            title={title}
            description={description}
            noindex={noIndexNoFollow}
            nofollow={noIndexNoFollow}
            openGraph={{
              title: title,
              description: description,
              url: link,
              images: [{
                url: image?.url,
                width: image?.width,
                height: image?.height,
              }]
            }}
            twitter={{
              handle: album.seo.twitter,
            }}
          />
          <CustomGoogleAnalytics ua={album.seo.analytics} />
        </>
      )
    }
    </Observer>
  );
}

export function CustomGoogleAnalytics(props)
{
  if(!props.ua)
    return null;

  const script = `
    function loadCustomGa()
    {
      if(window.gtag)
        gtag('config', '${props.ua}', {'anonymize_ip': true, 'send_page_view': false});
      else
        setTimeout(function () {
          loadCustomGa();
        }, 100);
    }

    loadCustomGa();
  `;

  return (
    <script async={true} dangerouslySetInnerHTML={{__html: script}} />
  );
}

export default AlbumHead;