import { createRef, Component } from 'react';
import { throttle } from 'throttle-debounce';
import panoStyle from './style';

class Panorama extends Component {
	static defaultProps = {
    onClick: () => {},
		onContextMenu: () => {},
	}

	scrollRef = createRef();
	ref = createRef();

	state = {
		translateX: 0,
		direction: 'left',
		speed: 1,
		manual: false
	}

	constructor(props) {
		super(props);

		this.onScroll = throttle(16, this.onScroll.bind(this));
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onContextMenu = this.onContextMenu.bind(this);

		this.supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
	}

	resize() {
		let bestSize = this.props.imageSizes.slice()[0];
    for(let size of this.props.imageSizes) {
      if(size.width>=this.props.width && size.height>=this.props.height)
      {
        bestSize = size;
        break;
      }
    }

		const imageWidth = (this.props.height / bestSize.height) * bestSize.width;
		this.setState({
			translateX: 0,
			imageWidth: imageWidth,
			imageSize: bestSize
		});
	}

	componentDidUpdate(prevProps) {
		if(prevProps.width!==this.props.width || prevProps.height!==this.props.height)
		{
			this.resize();
		}
	}

	componentDidMount() {
		this.resize();
		this.startAnimating(0);

		this.ref.current.addEventListener('mousemove', this.onMouseMove, {passive: true});
		this.ref.current.addEventListener('mouseleave', this.onMouseLeave, {passive: true});
		this.ref.current.addEventListener('mousedown', this.onMouseDown, {passive: true});
		this.ref.current.addEventListener('click', this.onClick, {passive: true});
		this.ref.current.addEventListener('contextmenu', this.onContextMenu);
	}

	componentWillUnmount() {
		this.stopAnimating();
		if(this.ref.current)
		{
			this.ref.current.removeEventListener('mousemove', this.onMouseMove, {passive: true});
			this.ref.current.removeEventListener('mouseleave', this.onMouseLeave, {passive: true});
			this.ref.current.removeEventListener('mousedown', this.onMouseDown, {passive: true});
			this.ref.current.removeEventListener('click', this.onClick, {passive: true});
			this.ref.current.removeEventListener('contextmenu', this.onContextMenu);
		}
	}

	onContextMenu(e) {
		e.preventDefault();
		e.stopPropagation();

		this.props.onContextMenu({
			event: e
		});
	}

	onMouseMove(e) {
		const isSidebar = (e.target.classList.contains('panorama-sidebar'));
		if(isSidebar)
		{
			const direction = e.target.classList.contains('panorama-sidebar-left') ? 'right' : 'left';
			this.setState({
				direction: direction,
				speed: 2,
				manual: true
			});
			this.startAnimating(0);
		}else
		{
			this.setState({
				speed: 1,
				manual: false
			});
			this.stopAnimating();
			this.startAnimating();
		}
	}

	onMouseLeave() {
		this.setState({
			speed: 1,
			manual: false
		});
	}

	onMouseDown(e) {
		const isSidebar = (e.target.classList.contains('panorama-sidebar'));
		if(isSidebar)
		{
			const direction = e.target.classList.contains('panorama-sidebar-left') ? 'right' : 'left';
			this.setState({
				direction: direction,
				speed: 12,
				manual: true
			});
		}
	}

	onClick(e) {
		const isSidebar = (e.target.classList.contains('panorama-sidebar'));
		if(isSidebar)
		{
			this.setState({
				speed: 2
			});
		}else
		{
			this.props.onMouseUp({
				event: e
			});
		}
	}

	lock(state) {
		this._lock = state;
	}

	animate() {
		if(!this.animationAllowed || this.isAnimating || !this.scrollRef.current)
			return;

		this.isAnimating = true;
		const maxX = this.props.width - this.state.imageWidth;
		const translateX = Math.max(maxX, Math.min(0, this.state.direction==='left' ? this.state.translateX - this.state.speed : this.state.translateX + this.state.speed));
		let direction = this.state.direction;

		// Op max en min richting omdraaien
		if(!this.state.manual)
		{
			if(translateX<=maxX || translateX>=0)
				direction = this.state.direction === 'right' ? 'left' : 'right';
		}

		this.setState({
			translateX,
			direction,
		});

		// Scroll positie ook updaten, ivm performance zit image niet in deze scroller,
		// maar we willen wel native scroll gevoel
		try
		{
			this.scrollRef.current.scrollTo(0 - translateX, 0);
		}
		catch(e) // Fallback when a browser does not support scrollTo
		{
			this.scrollRef.current.scrollLeft = (0 - translateX);
		}

		requestAnimationFrame(() => {
			this.isAnimating = false;
			this.animate();
		});
	}

	stopAnimating() {
		this.animationAllowed = false;
		if(this.animateTimer)
			clearTimeout(this.animateTimer);

		this.animateTimer = undefined;
	}

	startAnimating(delay=2000) {
		this.animateTimer = setTimeout(() => {
			this.animationAllowed = true;
			this.animate();
		}, delay)
	}

	onScroll() {
		if(!this.scrollRef.current)
			return;

		const translateX = 0 - this.scrollRef.current.scrollLeft;
		if(this.state.translateX===translateX)
			return;

		this.stopAnimating();
		this.startAnimating();

		this.setState({
			translateX: translateX
		});
	}

	render() {
    const style = {
      width: this.props.width,
      height: this.props.height,
    }

		let content = null;
		if(this.state.imageSize)
		{
			content = (
				<>
					{/* eslint-disable-next-line @next/next/no-img-element */}
					<img className="panorama-image" alt="panorama" src={this.state.imageSize.url} style={{
						transform: 'translateX(' + this.state.translateX + 'px)'
					}} height={this.props.height} width={this.state.imageWidth} draggable={false} />
					<div ref={this.scrollRef} className="panorama-scroller" style={{height: this.props.height + 50, width: this.props.width}} onScroll={this.onScroll}>
						<div style={{width: this.state.imageWidth, height: this.props.height}}></div>
					</div>

					{!this.supportsTouch && (
					<>
						<div className="panorama-sidebar panorama-sidebar-left">
						</div>
						<div className="panorama-sidebar panorama-sidebar-right">
						</div>
					</>
					)}
				</>
			);
		}

		return (
			<div className={"panorama"} ref={this.ref} style={style}>
				{content}

				<style jsx>{panoStyle}</style>
			</div>
		)
	}
}


export default Panorama;