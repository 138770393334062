import React from 'react';
import style from "./style";

function Floater(props)
{
  return (
    <div className='floater'>
      {props.children}

      <style jsx>{style}</style>
    </div>
  )
}

export default Floater;