import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";

import style from "./style";
import ThemeStore from "../store";
import Color from "utils/Color";

export default function CheckBox(props)
{
  const toggleSelected = () =>
  {
    if(props.onChange)
      props.onChange.call(null, !props.selected);
  }

  const checkColor = props.selected ? `#${Color.rgbToHex(ThemeStore.theme.buttonActiveColor)}` : "transparent";

  return (
    <div className={`ui-checkbox${props.selected ? " selected" : ""}`} onClick={() => toggleSelected()} style={props.style}>
      <div className="ui-checkbox-button" style={props.buttonStyle}>
        <FontAwesomeIcon width={1} color={checkColor} icon={faCheck} />
      </div>
      <div className="ui-checkbox-content" style={props.contentStyle}>{props.children}</div>
      <style jsx>{style}</style>
    </div>
  )
}

CheckBox.defaultProps = {
  selected: false
}