import { useContext, useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import AlbumContext from 'stores/album/context'
import translationStore from 'stores/translation-store';
import Avatar from 'components/Avatar/Avatar';

function ContributorsComponent(props) {
  const {style, url} = props;
  const store = useContext(AlbumContext);
  const albumStore = store.album;
  const [contributors, setContributors] = useState([]);
  const [maxVisibleContributors, setMaxVisibleContributors] = useState(5);

  function showAllContributors() {
    setMaxVisibleContributors(contributors.length);
  }

  useEffect(() => {
    let isMounted = true;
    const [promise] = store.user.FetchJSON(url, {
      cache: true,
    })

    promise.then(({data}) => {
      if(isMounted)
        setContributors(data.contributors);
    });

    return () => {
      isMounted = false;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const maxVisibleContributorNames = (contributors.length>maxVisibleContributors) ? maxVisibleContributors : contributors.length;
  const contributorNames = contributors.map(contributor => { return contributor.name }).slice(0, maxVisibleContributorNames);
  const contributorNamesString = contributorNames.map((name, index) => {
    const append = index < contributorNames.length-1 ? ((index===contributorNames.length-2) ? ' & ' : ', ') : ((contributorNames.length<contributors.length) ? ' ...' : '');
    let shortName = name.substr(0, 20) + (name.length>20 ? '...' : '');
    return shortName + append;
  });

  return (
    <Observer>
    {
      () =>
      {
        if(!albumStore.settings.showContributors) // TODO: Fix me when we get bookmarkers
          return null;

        return (
          <div className="myalbum contributors" style={style}>
            <div className="label">{translationStore.translate('album.madeby')}</div>
            <div className="contributor-names">{contributorNamesString}</div>
            <div className="avatars">
            {
              contributors.slice(0, maxVisibleContributorNames).map((contributor, index) => {

                return <Avatar
                  key={"avatar-"+index}
                  initials={contributor.initials}
                  color={contributor.color}
                  images={contributor.image}

                  data-tip={contributor.name}
                  data-tip-design="dark"
                  data-tip-delay="0"
                  data-tip-align="bottom"
                />
              })
            }
            {
              (contributorNames.length<contributors.length) && (
                <Avatar key={"avatar-more"} initials={"+"+(contributors.length - contributorNames.length)} color={"white"} onClick={showAllContributors} />
              )
            }
            </div>

            <style jsx>
            {`
              .contributors {
                text-align: center;
                color: rgba(var(--albumForegroundColor), 1);
                padding-top: 2%;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              }

              .label {
                font-weight: lighter;
                font-size: 15px;
                padding: 0px;
                margin: 0px;
                user-select: none;
                -webkit-user-select: none;
                cursor: default;
              }

              .avatars {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 25px;
              }

              .avatars :global(.avatar+.avatar) {
                margin-left: 10px;
              }

              .contributor-names {
                font-size: 22px;
                font-weight: lighter;
              }
            `}
            </style>
          </div>
        );
      }
    }
    </Observer>
  );
}

export default ContributorsComponent;