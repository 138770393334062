import { useEffect, useRef } from "react";
import { selectText } from "utils/Utils";
import { CopyButton } from "..";
import styles from './style';

/**
 * @param {object} props
 * @param {string} props.text
 * @param {boolean} [props.fixedFont]
 * @param {object} [props.style]
 * @param {object} [props.textStyle]
 * @param {object} [props.buttonStyle]
 * @param {object} [props.buttonTooltip]
 * @param {function} props.onCopy
 */
export default function CopyInput({text, fixedFont, style, textStyle, buttonStyle, buttonTooltip, onCopy}) {
  const textRef = useRef();
  
  const textClasses = ["copy-input-text"];
  if (fixedFont) {
    textClasses.push("copy-input-fixed-font")
  }

  useEffect(() => {
    if(textRef.current) {
      const ref = textRef.current;
      const _selectText = () => {
        selectText(ref);
      }

      ref.addEventListener("click", _selectText);

      return () => {
        ref.removeEventListener("click", _selectText);
      };
    }

  }, [textRef]);
  
  return (
    <div
      style={style}
      className="copy-input"
    >
      <div
        ref={textRef}
        style={textStyle}
        className={textClasses.join(" ")}
        allow-context-menu="true"
      >
        {text}
      </div>
      <CopyButton
        text={text}
        style={buttonStyle}
        tooltip={buttonTooltip}
        onCopied={onCopy}
      />
      <style jsx>{styles}</style>
    </div>
  );
}
