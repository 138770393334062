export function calculateRotatedSize(width:number, height:number, rotation:number=0):{width:number, height:number} {
  if(rotation===0 || rotation===180)
  {
    return {
      width,
      height
    }
  }
  
  // Convert rotation from degrees to radians
  var rotationRad = rotation * (Math.PI / 180);
  
  // Calculate the absolute value of the cosine and sine of the rotation angle
  var absCosRotation = Math.abs(Math.cos(rotationRad));
  var absSinRotation = Math.abs(Math.sin(rotationRad));
  
  // Calculate the new width and height of the rotated square
  var rotatedWidth = (width * absCosRotation) + (height * absSinRotation);
  var rotatedHeight = (width * absSinRotation) + (height * absCosRotation);
  
  // Return an object containing the rotated width and height
  return {
    width: rotatedWidth,
    height: rotatedHeight
  };
}