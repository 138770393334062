import { useCallback, useContext, useState } from "react";
import {exifGroupStyle} from './embedStyle';
import ExifGroup from 'components/ExifGroup';
import { formatBytes, formatBitrate } from 'utils/Utils';
import translationStore from 'stores/translation-store';
import AlbumContext from '../context'
import { observer } from "mobx-react";
import { useUser } from "utils/user/lib";
import DownloadUpsellModal from "components/DownloadUpsellModal";

let groups = [];
groups.push({
  title: "slideviewdetails.exif",
  fields:
  {
    "title": {
      label: "slideviewdetails.exif.title"
    },
    "dateTaken": {
      label: "slideviewdetails.exif.date"
    },
    "fileName": {
      label: "slideviewdetails.exif.filename"
    },
    "width": {
      label: "slideviewdetails.exif.dimensions",
      suffix: {field: "height", prefix: ' x '}
    },
    "fileSize": {
      label: "slideviewdetails.exif.filesize"
    },
    "software": {
      label: "slideviewdetails.exif.software"
    },
    "cameraBrand": {
      label: "slideviewdetails.exif.cameraBrand"
    },
    "cameraModel": {
      label: "slideviewdetails.exif.camera"
    },
    "lensName": {
      label: "slideviewdetails.exif.lens"
    },
    "focalLength": {
      label: "slideviewdetails.exif.focallength",
      suffix: " mm"
    },
    "shutterSpeed": {
      label: "slideviewdetails.exif.shutterspeed"
    },
    "aperture": {
      label: "slideviewdetails.exif.aperture",
      prefix: "ƒ "
    },
    "duration": {
      label: "slideviewdetails.exif.duration"
    },
    "fps": {
      label: "slideviewdetails.exif.fps",
      suffix: "slideviewdetails.exif.fps.suffix"
    },
    "bitrate": {
      label: "slideviewdetails.exif.bitrate",
    },
    "ISO": {
      label: "slideviewdetails.exif.iso"
    },
    "flashFired": {
      label: "slideviewdetails.exif.flash",
      values: {false: "base.no", true: "base.yes"}
    },
    "exposureBias": {
      label: "slideviewdetails.exif.exposureBias",
      suffix: " EV"
    },
    "comment": {
      label: "slideviewdetails.exif.comment"
    },
    "keywords": {
      label: "slideviewdetails.exif.keywords"
    }
  }
});

groups.push({
  title: "slideviewdetails.contact",
  fields:
  {
    "creatorName": {
      label: "slideviewdetails.contact.name"
    },
    "creatorTitle": {
      label: "slideviewdetails.contact.title"
    },
    "creatorAddress": {
      label: "slideviewdetails.contact.address"
    },
    "creatorZipcode": {
      label: "slideviewdetails.contact.zipcode"
    },
    "creatorCity": {
      label: "slideviewdetails.contact.city"
    },
    "creatorRegion": {
      label: "slideviewdetails.contact.region"
    },
    "creatorCountry": {
      label: "slideviewdetails.contact.country"
    },
    "creatorPhone": {
      label: "slideviewdetails.contact.phone"
    },
    "creatorEmail": {
      label: "slideviewdetails.contact.email"
    },
    "creatorWebsite": {
      label: "slideviewdetails.contact.website"
    }
  }
});

groups.push({
  title: "slideviewdetails.iptc",
  fields:
  {
    "IPTCHeadline": {
      label: "slideviewdetails.iptc.headline"
    },
    "IPTCSubjectCode": {
      label: "slideviewdetails.iptc.subjectcode"
    },
    "IPTCDescriptionWriter": {
      label: "slideviewdetails.iptc.descriptionwriter"
    },
    "IPTCCategory": {
      label: "slideviewdetails.iptc.category"
    },
    "IPTCOtherCategories": {
      label: "slideviewdetails.iptc.otherCatergories"
    }
  }
});

groups.push({
  title: "slideviewdetails.image",
  fields:
  {
    "imageGenre": {
      label: "slideviewdetails.image.genre"
    },
    "imageSceneCode": {
      label: "slideviewdetails.image.sceneCode"
    },
    "imageSublocation": {
      label: "slideviewdetails.image.sublocation"
    },
    "imageRegion": {
      label: "slideviewdetails.image.region"
    },
    "imageCity": {
      label: "slideviewdetails.image.city"
    },
    "imageCountryName": {
      label: "slideviewdetails.image.countryName"
    },
    "imageCountryISO": {
      label: "slideviewdetails.image.countryCode"
    }
  }
});

groups.push({
  title: "slideviewdetails.workflow",
  fields:
  {
    "workflowJobIdentifier": {
      label: "slideviewdetails.workflow.jobIdentifier"
    },
    "workflowInstructions": {
      label: "slideviewdetails.workflow.instructions"
    },
    "workflowCreditLine": {
      label: "slideviewdetails.workflow.creditLine"
    },
    "workflowSource": {
      label: "slideviewdetails.workflow.source"
    }
  }
});

groups.push({
  title: "slideviewdetails.copyright",
  fields:
  {
    "copyright": {
      values: {1: "slideviewdetails.copyright"},
      label: "slideviewdetails.copyright.copyright"
    },
    "copyrightText": {
      label: "slideviewdetails.copyright"
    },
    "copyrightUsage": {
      label: "slideviewdetails.copyright.usage"
    },
    "copyrightUrl": {
      label: "slideviewdetails.copyright.url"
    }
  }
});

function formatDuration(exif)
{
  if(exif.duration)
  {
    if(exif.duration > 3600)
      exif.duration = new Date(exif.duration * 1000).toISOString().substr(11, 8);
    else
      exif.duration = new Date(exif.duration * 1000).toISOString().substr(14, 5)
  }
}

function formatFilesize(exif)
{  
  if(exif.hasOwnProperty("fileSize"))
    exif.fileSize = formatBytes(exif.fileSize * 1024 * 1024, 2);
}

function formatBirate(exif)
{
  if(exif.hasOwnProperty("bitrate"))
    exif.bitrate = formatBitrate(exif.bitrate, 2);
}

function decodeHtml(details) {
  const txt = document.createElement("textarea");

  for(let key in details) {
    txt.innerHTML = details[key];
    details[key] = txt.value;
  }
}

function InfoBar(props) {
  const {details, itemType, SlideView} = props;
  /** @type {import('../store').default} */
  const store = useContext(AlbumContext)
  const user = useUser();

  // Toon download optie als setting aan staat of als je owner bent
  const showDownloadOption = [1, 10].includes(itemType) && (store.settings.albumDownloadsEnabled || store.me.role==='owner');

  // Toon upsell als je owner bent maar nog geen abo met download rechten hebt
  const enableDownloadUpsell = store.me.role==='owner' && !user.subscription.features.download;
  const [showDownloadUpsell, setShowDownloadUpsell] = useState(false);

  decodeHtml(details);
  formatDuration(details);
  formatFilesize(details);
  formatBirate(details);

  const download = useCallback(() => {
    if(enableDownloadUpsell)
    {
      setShowDownloadUpsell(true);
    }else
    {
      SlideView.currentItem()._download();
    }
  }, [enableDownloadUpsell]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="info-bar">
      <h2>{translationStore.translate("base.description")}</h2>
      <div className="description">
        {details.description}
      </div>
      
      {
        showDownloadOption && <div className="download" onClick={download}>{translationStore.translate('context.download-original')}</div>
      }

      {
        groups.map((group) => {
          return <ExifGroup key={group.title} title={group.title} fields={group.fields} data={details}></ExifGroup>
        })
      }

      {
        showDownloadUpsell && 
        <DownloadUpsellModal
          onClose={() => setShowDownloadUpsell(false)}
        />
      }
      <style jsx>{exifGroupStyle}</style>
    </div>
  )
}

export default observer(InfoBar);