import { useContext, useEffect, useState, useCallback } from 'react';
import PageRow from './PageRow';
import Attachment from './Attachment';
import SpreadSpacer from './Spacer';
import { Observer } from 'mobx-react'
import { Menu, Modal } from 'components/UI';
import AlbumContext from '../../stores/album/context'
import translationStore from '../../stores/translation-store';
import {spreadCss, spreadMessageCss} from "./style";

export default function SpreadComponent(props) {
  const { contentItem, prepends, appends, pageRows, style } = props;
  const store = useContext(AlbumContext);
  let dividerHeight = props.style.marginBottom;

  let pageRowHeight = 0;
  for(let row of pageRows)
    pageRowHeight += row.height;

  let spreadProps = {
    style: {
      height: props.style.height,
    }
  };
  return (
    <Observer>
    {
      () => (
        <>
          <div
            spread={contentItem.key}
            className={"spread" + (contentItem.isSelected ? " selected" : "") + (store.ui.editMode ? " edit-mode" : "")}
            {...spreadProps}
            page-number={contentItem.label}
            view-mode={contentItem.contentType.viewMode}
          >
          {
            prepends.map((item, index) => {
              let className = ['prepend'];
              if(index===0)
                className.push('first');
              if(index===prepends.length-1)
                className.push('last');
              if((index!==prepends.length-1) && index>0)
                className.push('middle');

              return (
                <Attachment
                  height={item.height}
                  key={"prepend-"+index}
                  arrow="bottom"
                  index={100-index}
                  style={item.style}
                  className={className.join(' ')}
                >
                  {item.component}
                </Attachment>
              );
            })
          }
          {pageRowHeight>0 && <div className="border-top"></div>}
          {
            pageRows.map((pageRow, index) => {
              let rowKey = props.id+"-row-"+index;
              let rowStyle = {
                width: pageRow.width,
                height: pageRow.height,
                marginTop: pageRow.marginTop,
                marginLeft: (style.width - pageRow.width)/2,
                paddingTop: pageRow.paddingTop
              };

              return (<PageRow
                key={rowKey}
                style={rowStyle}

                editHints={store.ui.editMode}
                spread={props.spread}
                pages={pageRow.pages}
                prepends={pageRow.prepends}
                appends={pageRow.appends}
                eventListener={props.eventListener}
              />)
            })
          }
          {
            props.message && (
              <ErrorMessage
                style={{height: style.height}}
                type={props.message.type}
                msg={props.message.msg}
                contentItem={contentItem}
              />
            )
          }
          {
            props.viewMode==='cover' && (
              <div className='cover-arrow' onClick={(e) => {
                props.eventListener({eventType: 'arrow', event: e})
              }}>
                <svg version="1.1" className="icon" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40" preserveAspectRatio="xMidYMin meet"><path id="path_2" d="M26.506,18.507l-6.007,5.992l-6.008-5.992" transform="translate(0,18) scale(1,-1) translate(0,-18)"></path></svg>
              </div>
            )
          }
          {pageRowHeight>0 && <div className="border-bottom"></div>}
          {
            appends.map((item, index) => {
              let className = ['append'];
              if(index===0)
                className.push('first');
              if(index===prepends.length-1)
                className.push('last');

              return (
                <Attachment
                  height={item.height}
                  key={"append-"+index}
                  arrow="top"
                  style={item.style}
                >
                  {item.component}
                </Attachment>);
            })
          }
          </div>

          <SpreadSpacer height={dividerHeight} spread={props.id} />
          <style jsx>{spreadCss}</style>
        </>
      )
    }
    </Observer>
  );
}

function ErrorMessage(props) {
  const store = useContext(AlbumContext);
  const { contentItem } = props;
  const [isContextMenuOpen, setContextMenuOpen] = Menu.useOpener();
  const [contextMenuEvent, setContextMenuEvent] = useState(null);

  useEffect(() => {
    console.log(props);
    switch(props.type)
    {
      case "error":
        console.error(props.msg);
      break;
      case "warning":
        console.warn(props.msg);
      break;
      default:
        console.log(props.msg);
      break;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function showContextMenu(e) {
    setContextMenuEvent(e);
    setContextMenuOpen(true);
  }

  const onContextMenuClose = useCallback(() => {
    setContextMenuOpen(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="spread-message-wrapper" style={props.style} onContextMenu={showContextMenu}>
      <div className={"spread-message spread-message-"+props.type}><span className="spread-message-type">{props.type}</span> {JSON.stringify(props.msg.message)}</div>
      <style jsx>{spreadMessageCss}</style>

      <Menu
        isOpen={isContextMenuOpen}
        position={{event: contextMenuEvent}}
        onRequestClose={onContextMenuClose}
      >
        {
          (store.ui.editMode && contentItem!==undefined && contentItem.isDeletable) &&
          <Menu.Item
            title={translationStore.translate("context.delete-page", {page: contentItem.label ? ' ' + contentItem.label : ''})}
            onClick={() => {
    
              Modal.Confirm({
                title: translationStore.translate("context.delete-page", {page: contentItem.label ? ' ' + contentItem.label : ''}),
                description: translationStore.translate("context.delete-page-confirmation-msg", {page: contentItem.label ? ' ' + contentItem.label : ''}),
                okButtonText: translationStore.translate("base.delete"),
              })
              .then(() => {
                contentItem.delete();
              })
              .catch(() => {})
            }}
          />
        }
      </Menu>
    </div>
  )
}