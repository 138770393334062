import { CopyInput } from "components/UI";
import styles from "./style";

/**
 * @param {object} props
 * @param {string[]} props.urls
 * @param {function} props.onCopy
 */
export default function PhotoShare({urls, onCopy}) {
  const [large, medium, small] = urls;

  const bb = small || medium || large || undefined;
  const html = large || medium || small || undefined;

  return (
    <>
      {
        large && <ShareRow prefix="L" text={large} onCopy={onCopy} />
      }
      {
        medium && <ShareRow prefix="M" text={medium} onCopy={onCopy} />
      }
      {
        small && <ShareRow prefix="S" text={small} onCopy={onCopy} />
      }
      {
        bb && <ShareRow prefix="[bb]" text={`[img]${bb}[/img]`} onCopy={onCopy} />
      }
      {
        html && <ShareRow prefix="html" text={`<img src="${html}" />`} onCopy={onCopy} />
      }
    </>
  );
}

PhotoShare.defaultProps = {
  onCopy: () => {}
}

function ShareRow({prefix, text, onCopy}) {
  return (
    <div className="share-row">
      <span
        className="share-row-prefix"
      >
        {prefix}
      </span>
      <CopyInput
        text={text}
        onCopy={onCopy}
        style={{
          width: "100%",
          maxWidth: "calc(100% - 50px)",
          marginRight: 0,
          height: 35,
        }}
        textStyle={{
          width: "100%",
        }}
      />
      <style jsx>{styles}</style>
    </div>
  );
}
