import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faClipboard } from "@fortawesome/pro-light-svg-icons/faClipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Observer } from "mobx-react";
import { createRef, useEffect, useState } from "react";

export default function CopyButton(props)
{
  const [copied, setCopied] = useState(false);
  const inputRef = createRef();

  const copyText = () =>
  {
    try
    {
      inputRef.current.select();
      inputRef.current.setSelectionRange(0, props.text.length);

      document.execCommand("copy");
      onCopied();
    }
    catch(e)
    {
      navigator.clipboard.writeText(props.text)
      .then(() => onCopied())
      .catch(() => {});
    }
  }

  const onCopied = () =>
  {
    if(props.onCopied)
      props.onCopied();

    setCopied(true);
  }

  useEffect(() =>
  {
    if(copied)
    {
      const timeout = setTimeout(() =>
      {
        setCopied(false);
      }, 2000);
      return () =>
      {
        clearTimeout(timeout);
      }
    }
  }, [copied]);

  return (
    <Observer>
    {
      () => (
        <>
          <button
            data-tip={props.tooltip}
            data-tip-delay={0}
            data-tip-align="bottom"
            style={props.style}
            onClick={() => copyText()}
          >
            <FontAwesomeIcon fixedWidth icon={copied ? faCheck : faClipboard} />
          </button>
          <input ref={inputRef} type="hidden" value={props.text} />
        </>
      )
    }
    </Observer>
  );
}

CopyButton.defaultProps = {
  tooltip: undefined
}