import ContentType from './content-type';
import ContributorsComponent from 'components/Contributors/Contributors';

class ContributorsModel extends ContentType {
  constructor(contentItem)
  {
    super(contentItem);
  }

  getSize(config) {
    return Promise.resolve({
      width: config.viewPort.width,
      height: 200
    })
  }

  render(key, style) {
    return (
      <ContributorsComponent
        key={"contributors-"+key}
        url={this.contentItem.data.url}
        style={style}
     />);
  }
}

export default ContributorsModel;