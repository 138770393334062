import { observable, action, transaction, makeObservable, runInAction } from "mobx";
import { enableStaticRendering } from 'mobx-react';
enableStaticRendering(typeof window === 'undefined');

class AssetStore {
  url = '';

  items = [];
  isLoading = 0;
  sortBy = 'date';

  constructor(store, data) {
    makeObservable(this, {
      url: observable,
      items: observable.shallow,
      isLoading: observable,
      sortBy: observable,

      add: action,
      addBatch: action,
      delete: action,
      load: action,
    });

    this.store = store;
    this.hydrate(data);
  }

  add = (data) => {
    let asset = this.getByKey(data.key);
    if(!asset)
    {
      this.items.push(data);
    }

    return asset;
  }

  addBatch = (assets) => {
    transaction(() => {
      for(const assetKey in assets) {
        const data = {...assets[assetKey]};
        this.add(data);
      }
    });
  }

  getIndex = (key) => {
    return this.items.findIndex(q => q.key === key);
  }

  getByKey = (key) => {
    const index = this.getIndex(key);
    return this.items[index];
  }

  get sortedItems() {
    return this.items.slice().sort((a, b) => {
      switch(this.sortBy) {
        case 'date':
          return a.dateTime - b.dateTime ? 1 : -1;

        case 'batch':
          if(a.batchNr === b.batchNr){
            return a.dateTime > b.dateTime ? 1 : -1;
          } else {
            return a.batchNr > b.batchNr ? 1 : -1;
          }

        case 'filename':
          if(a.fileName && b.fileName) {
            return a.fileName.localeCompare(b.fileName, undefined, {numeric: true, sensitivity: "base"});
          } else if (a.batchNr === b.batchNr) {
            return a.dateTime > b.dateTime ? 1 : -1;
          } else {
            return a.batchNr > b.batchNr ? 1 : -1;
          }

        default:
          return 0;
      }
    });
  }

  delete = (item) => {
    if(typeof item === 'string')
    {
      item = this.getByKey(item);
      if(!item)
        return;
    }

    this.items.remove(item);
  }

  reloadAll = () => {
    this.loadAllPromise = undefined;
    return this.loadAll();
  }

  loadAll = (url) => {
    if(this.loadAllPromise)
      return this.loadAllPromise;

    if(url===undefined)
      url = this.url + '?length=100&start=0';
    
    let totalAssets = [];
    const _loadAll = (url) => {
      return new Promise(resolve => {
        this.load(url)
          .then(({url, assets}) => {
            totalAssets = totalAssets.concat(assets);

            if(url)
            {
              _loadAll(url).then(() => {
                resolve();
              })
            }else
            {
              resolve();
            }
          })
      });
    }

    this.loadAllPromise = new Promise(resolve => {
      _loadAll(url).then(() => {
        runInAction(() => {
          this.items.replace(totalAssets);
        });

        resolve();
      });
    });

    return this.loadAllPromise;
  }

  // Laad volledige assets (alleen eigenaar kan dit)
  load = (url) => {
    this.isLoading++;
    return new Promise(resolve => {
      const [promise] = this.store.user.FetchJSON(url);
      promise.then(res => {
        
        resolve({url: res.data.links.next, assets: res.data.assets});

        runInAction(() => {
          this.isLoading--;
        });
      })
      .catch((e) => {
        console.warn(e);

        runInAction(() => {
          this.isLoading--;
        });
      })
    });
  }

  hydrate = data => {
    if (!data) return

    this.url = data.url;
  }
}

export default AssetStore;