import { enableStaticRendering } from 'mobx-react'
import { observable, action, runInAction, makeObservable, computed } from "mobx";
import { v4 as uuidv4 } from 'uuid';
import {whenAuthenticated, hideAuthentication} from '../../components/Authentication';
import {textSuggestions, getStyleSheet} from 'utils/TextSuggestions';

// Nodig ivm serverside rendering, die gaat anders lekken omdat er geen unmounts e.d. zijn
enableStaticRendering(typeof window === 'undefined');

class UIStore {
  window = {
    innerWidth: 1600,
    innerHeight: 1200,
    clientWidth: 1600,
    scrollTop: 0,
  }

  mouse = {
    clientY: 0,
    clientX: 0,
  }

  _viewMode = "fill";
  defaultViewMode = "fill";
  oldViewMode = undefined;
  isSmallScreen = false;

  ratio = 1.35;
  interactive = true;
  seperatorSize = 50;
  seperator = {
    max: 1000,
    min: 10,
    scale: 0.05
  }
  styleSheet = {};
  muted = false;
  lockMenuBar = false;
  menuBarEnabled = true;
  menuBarVisible = true;
  scrollTop = 0;

  editMode = false;
  isProcessingEditMode = false;
  components = {};
  editBarHeight = 195;
  dimensions = {
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  };

  constructor(store, data) {
    makeObservable(this, {
      window: observable,
      mouse: observable,

      setWindowSize: action,
      setMousePosition: action,
      setScrollPosition: action,
      setDimensions: action,
      addComponent: action,
      removeComponentByKey: action,

      isSmallScreen: observable,
      _viewMode: observable,
      viewMode: computed,
      showViewModePicker: computed,
      ratio: observable,
      interactive: observable,
      seperatorSize: observable,
      seperator: observable,
      styleSheet: observable,
      muted: observable,
      menuBarEnabled: observable,
      menuBarVisible: observable,
      editMode: observable,
      isProcessingEditMode: observable,
      components: observable.shallow,
      editBarHeight: observable,
      dimensions: observable,
    });

    this.store = store;
    this.hydrate(data);
  }

  get viewMode() {
    if(this.editMode)
      return 'book';
    
    return this._viewMode;
  }

  set viewMode(mode) {
    this._viewMode = mode;
  }

  get showViewModePicker() {
    // Buiten editmode gewoon zichtbaar
    if(!this.editMode)
      return true;
    else
      return false;
  }

  getTextSuggestions = () => {
    return textSuggestions;
  }

  getStyleSheet = (name) => {
    return getStyleSheet(name);
  }

  hydrate = data => {
    if (!data) return

    this.defaultViewMode = data.defaultViewMode;
  }

  setWindowSize(size) {
    const wasSmallScreen = this.isSmallScreen;
    const isSmallScreen = !!(size.clientWidth<700);

    if(isSmallScreen)
    {
      if(!this.isSmallScreen)
      {
        this.oldViewMode = this.viewMode;
        this.viewMode = 'smallscreen';
      }
    }else if(wasSmallScreen)
    {
      this.viewMode = this.oldViewMode ? this.oldViewMode : this.defaultViewMode;
    }

    this.isSmallScreen = isSmallScreen;
    this.window = size;
  }

  setMousePosition(position) {
    Object.assign(this.mouse, {...position});

    if(position.clientY<=60)
      this.menuBarVisible = true;
  }

  setScrollPosition(position) {
    position.scrollTop = position.scrollTop > 0 ? position.scrollTop : 0;

    const direction = position.scrollTop===this.scrollTop ? this.scrollDirection : (position.scrollTop > this.scrollTop ? 'down' : 'up');
    this.scrollDirection = direction;
    this.scrollTop = position.scrollTop;
    const mouseAtTop = this.mouse.clientY<=60;
    const menuBarVisible = direction==='down' && !mouseAtTop ? ((position.scrollTop + 65) < this.window.innerHeight) : true;

    if(!this.editMode && !this.lockMenuBar)
      this.menuBarVisible = menuBarVisible;

    this.window.scrollTop = position.scrollTop;
  }

  resize() {
    const wasSmallScreen = this.isSmallScreen;
    const isSmallScreen = !!(document.documentElement.clientWidth<700);

    if(isSmallScreen)
    {
      if(!this.isSmallScreen)
      {
        this.oldViewMode = this.viewMode;
        this.viewMode = 'smallscreen';
      }
    }else if(wasSmallScreen)
    {
      this.viewMode = this.oldViewMode ? this.oldViewMode : this.defaultViewMode;
    }

    this.isSmallScreen = isSmallScreen;
  }

  // Dimensions van albumviewer, komt vanuit de lazylist
  setDimensions(dimensions) {
    this.dimensions = dimensions;
  }

  getSeperatorLayout(base) {
    const seperatorSize = Math.round(Math.max(this.seperator.min, Math.min(this.seperator.max, base*this.seperator.scale)));

    let height = 0;
    switch(this.viewMode)
    {
      case "smallscreen":
        height = seperatorSize * 2;
      break;
      case "fill":
        height = seperatorSize;
      break;
      default:
        height = seperatorSize;
      break;
    }

    if(this.editMode)
      height = seperatorSize;

    return {
      width: seperatorSize,
      height: height
    }
  }

  toggleEditMode() {
    this.setEditMode(!this.editMode);
  }

  setEditMode(state) {
    if(!state && this._isEditModeAuthenticating)
    {
      hideAuthentication();
      this._isEditModeAuthenticating = false;
    }

    if(state===this.editMode)
      return Promise.resolve(state);

    return new Promise((outerResolve) => {
      runInAction(() => {
        this.isProcessingEditMode = true;
      });

      new Promise((resolve, reject) => {
        if(state)
        {
          this._isEditModeAuthenticating = true;

          whenAuthenticated(this.store.user)
          .then(() => {
            this._isEditModeAuthenticating = false;
            if(this.store.album.me.role!=='owner')
            {
              console.warn("Owner role needed to enable editMode");
              return reject("Owner role required to enable editMode");
            }

            resolve();
          })
          .catch(e => {
            this._isEditModeAuthenticating = false;
            reject(e);
          });
        }else
        {
          // Editmode uit, geen login nodig
          resolve();
        }
      }).then(() => {
        new Promise(resolve => {
          if(state) {
            runInAction(() => {
              this.menuBarVisible = true;
            });
            this.editor.then(() => {
              resolve(state);
            });
          }else
          {            
            resolve(state);
          }
        })
          .then(() => {
            runInAction(() => {
              this.lockMenuBar = true;
              this.editMode = state;
              this.interactive = !state;
            });

            outerResolve(state);
          });

        if(this._editModeLockMenubarTimer)
          clearTimeout(this._editModeLockMenubarTimer);

        this._editModeLockMenubarTimer = setTimeout(() => {
          this.lockMenuBar = false;
        }, 300);
      })
      .catch(() => {
        outerResolve(this.editMode);
      });
    })
    .finally(() => {
      runInAction(() => {
        this.isProcessingEditMode = false;
      });
    })
}

  get editor() {
    return new Promise((resolve) => {
      if(this._editor===undefined)
      {
        this._editor = new Promise(resolve => {
          import('../../utils/Editor').then((Editor) => {
            const editor = new Editor.default(this.store);
            resolve(editor);
          });
        });
      }

      this._editor.then(resolve);
    });
  }

  addComponent(component, key = uuidv4()) {
    this.components[key] = component;

    return component;
  }

  removeComponentByKey(key) {
    if(this.components[key]!==undefined)
    {
      delete this.components[key];
    }
  }

  dispose() {
    if(this._editor) {
      this._editor.then(editor => {
        editor.dispose();
      })
    }
  }
}

export default UIStore;