import { observable, computed, action, transaction, makeObservable, runInAction } from "mobx";
import contentModel from "models/album/content-model";
import { enableStaticRendering } from 'mobx-react';
import Events from 'utils/EventsOld';
import {toJS} from 'mobx';

enableStaticRendering(typeof window === 'undefined');

class ContentStore {
  items = [];
  indexInView = 0;
  selectionIsInView = true;

  constructor(store, data) {
    makeObservable(this, {
      selectionIsInView: observable,
      items: observable,
      insertIndex: computed({keepAlive: true}),
      spreadCount: computed,

      delete: action,
      deSelectAll: action,
      addSpread: action,
      disposeItems: action,
    });

    new Events(this);
    this.store = store;
    this.hydrate(data);
  }

  get spreadCount() {
    let count = 0;
    for(let item of this.items)
    {
      // Spreads, geen cover en we gaan er vanuit dat elke spread 2 pagina's bevat
      if(item.type==='spread')
        count++;
    }

    // Cover halen we er vanaf
    if(count>0)
      count--;

    return count;
  }

  add = (type, data, key, index) => {
    let item = new contentModel(this, type, data, key);

    if(index===undefined)
      index = toJS(this.insertIndex);

    runInAction(() => {
      this.items.splice(index, 0, item);
    });

    return item;
  }

  get insertIndex() {
    for(let index=this.items.length-1; index>-1; index--)
    {
      if(!this.items[index].isPinned || this.items[index].pin==="top")
        return index+1;
    }

    return this.items.length;
  }

  getIndex = (key) => {
    return this.items.findIndex(q => q.key === key);
  }

  getByKey = (key) => {
    const index = this.getIndex(key);
    return this.items[index];
  }

  delete = (item) => {
    item.dispose();
    this.items.remove(item);

    if(item.data.links && item.data.links.delete)
    {
      const [promise] = this.store.user.FetchJSON(item.data.links.delete, {
        method: 'DELETE',
      });
      promise.then(() => {

      })
      promise.catch(e => {
        console.warn(e);
      })
    }
  }

  get selection() {
    const selection = this.items.filter(item => {
      return item.isSelected;
    });

    return selection;
  }

  set selection(selection) {
    transaction(() => {
      this.deSelectAll(selection);
      for(let item of selection) {
        item.isSelected = true;
      }
    });
  }

  deSelectAll = (ignoreItems) => {
    for(let item of this.items)
    {
      if(ignoreItems && ignoreItems.includes(item))
        continue;

      item.isSelected = false;
    }
  }

  addSpread(config) {
    return new Promise((resolve, reject) => {
      const [promise] =  this.store.user.FetchJSON(process.env.API_URL + '/album/' + this.store.album.key + '/spreads', {
        method: 'POST',
        data: config,
      });
      promise.then(({data}) => {
        let cleanData = {...data};
        delete cleanData.key;
        delete cleanData.type;

        const index = this.getIndex(config.insertAfter);
        const item = this.add(data.type, cleanData, data.key, index+1);

        resolve(item);
      })
      .catch(e => {
        reject(e);
      })
    });
  }

  disposeItems = () => {
    for(const item of this.items) {
      item.dispose();
    }

    this.items.clear();
  }

  hydrate = contents => {
    if (!contents) return;

    if(typeof window === 'undefined')
      return;

    this.disposeItems();
    let index = 0;
    for(const content of contents)
    {
      let data = {...content};
      delete data.key;
      delete data.type;

      let contentItem = this.add(content.type, data, content.key);

      // Hack om cover niet deletable te maken
      if(index===0)
        contentItem.isDeletable = false;

      index++;
    }
  }

  createBlankPage = () => {
    const index = this.indexInView > 0 ? this.indexInView - 1 : 0;
    const key = this.items[index].key;
    this.addSpread({
      insertAfter: key
    })
    .then(spread => {
      this.selection = [spread];
    })
    .catch(() => {})
  }

  dispose = () => {
    this.disposeItems();
  }
}

export default ContentStore;