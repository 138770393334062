import { Confirm } from 'components/UI/ModalV2/ModalV2';
import { useCallback } from 'react';
import translationStore from 'stores/translation-store';

function DownloadUpsellModal(props) {
  const onConfirm = useCallback(() => {
    location.href = `${process.env.SITE_URL}/premium?redirect=1&ref=download-popup`;
  }, []);

  return (
    <Confirm
      title={translationStore.translate("upsell.download.title")}
      text={translationStore.translate("upsell.download.text")}
      confirmLabel={translationStore.translate("base.upgrade")}
      onConfirm={onConfirm}
      onCancel={props.onClose}
    />
  )
}

export default DownloadUpsellModal;