import {reaction, runInAction} from 'mobx';
import AlbumStore from './album-store'
import AssetStore from './asset-store'
import UiStore from './ui-store'
import ContentStore from './content-store'
import ThemeStore from 'components/UI/store';
import ProductsStore from './products-store';
import isDev from 'utils/isDev';
import Dom from '@myalbum/dom';
import { throttle } from 'throttle-debounce';

const isSSR = (typeof window === "undefined")

let storeInstanceId = 0;
class RootStore {
  storeInstanceId = null;

  user = null;

  /**
   * @type {import("./album-store").default}
   */
  album = null;

  /**
   * @type {AssetStore}
   */
  assets = null;

  /**
   * @type {ContentStore}
   */
  content = null;

  /**
   * @type {ProductsStore}
   */
  products = null;

  /**
   * @type {UiStore}
   */
  ui = null;
  edit = null;
  disposers = [];

  constructor(config) {
    storeInstanceId++;
    this.storeInstanceId = storeInstanceId;

    this.user = config.user;
    this.album = new AlbumStore(this, config.album);
    this.ui = new UiStore(this);
    this.assets = new AssetStore(this, config.asset);
    this.content = new ContentStore(this, config.content);
    this.products = new ProductsStore(this, config.products);

    this.onScroll = throttle(25, this.onScroll.bind(this));
    this.onMove = throttle(25, this.onMove.bind(this));
    this.setEvents();
    this.onResize();
    this.restorePreferences();

    if(!isSSR && isDev)
    {
      window.album = {};
      window.album.album = this.album;
      window.album.asset = this.asset;
      window.album.content = this.content;
      window.album.ui = this.ui;

      this.disposers.push(() => {
        delete window.album;
      });
    }

    this.disposers.push(
      reaction(
        () => this.ui.editMode,
        editMode => {
          if(!editMode)
          {
            this.content.deSelectAll();
            ThemeStore.setTheme('light');
          }else
          {
            ThemeStore.setTheme('dark');
          }
        }
      )
    )
  }

  onScroll() {
    if(isSSR)
      return;

    this.ui.setScrollPosition({
      scrollTop: Dom(window).scrollTop(),
    })
  }

  onMove(e) {
    if(isSSR)
      return;

    const pos = {
      clientY: e.clientY,
      clientX: e.clientX,
    }

    if(e.clientX===undefined && e.touches!==undefined && e.touches.length>0)
    {
      pos.clientX = e.touches[0].clientX;
      pos.clientY = e.touches[0].clientY;
    }

    this.ui.setMousePosition(pos);
  }

  onResize = () => {
    if(isSSR)
      return;

    this.ui.setWindowSize({
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      clientWidth: document.documentElement.clientWidth,
    })
  }

  onKeyDown = (e) => {
    if((e.metaKey || e.ctrlKey) && e.keyCode===69 && !e.altKey)
    {
      e.preventDefault();
      this.ui.toggleEditMode();
    }
  }

  setEvents() {
    if(isSSR)
      return;

    window.addEventListener('scroll', this.onScroll, {passive: true});
    window.addEventListener('mousemove', this.onMove, {passive: true});
    window.addEventListener('touchmove', this.onMove, {passive: true});
    window.addEventListener('resize', this.onResize);
    window.addEventListener('keydown', this.onKeyDown);

    this.disposers.push(
      reaction(
        () => this.ui._viewMode,
        viewMode => {
          window.localStorage.setItem("viewMode", viewMode)
        }
      )
    );

    this.disposers.push(
      reaction(
        () => this.ui.muted,
        muted => {
          window.localStorage.setItem("muted", muted)
        }
      )
    );

    this.disposers.push(() => {
      window.removeEventListener('scroll', this.onScroll, {passive: true});
      window.removeEventListener('mousemove', this.onMove, {passive: true});
      window.removeEventListener('touchmove', this.onMove, {passive: true});
      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('keydown', this.onKeyDown);
    });
  }

  restorePreferences() {
    if(isSSR)
      return;

    // Oude waarden uit localStorage halen
    let oldViewMode = ((window.localStorage.getItem("viewMode")));

    // oldViewMode is smallscreen, maar dat is dit scherm niet
    if(oldViewMode==='smallscreen' && !this.ui.isSmallScreen)
      oldViewMode = undefined;

    // Onbekende viewMode (legacy b.v.) overslaan en terugvallen op default
    const knownViewModes = ['book', 'fill', 'smallscreen'];
    if(!knownViewModes.includes(oldViewMode))
      oldViewMode = undefined;

    if(oldViewMode && !this.ui.isSmallScreen) {
      runInAction(() => {
        this.ui.viewMode = oldViewMode;
      });
    }

    const oldMuted = ((window.localStorage.getItem("muted")));
    if(oldMuted!==undefined) {
      runInAction(() => {
        this.ui.muted = oldMuted;
      });
    }
  }

  dispose() {
    if(isDev)
      console.debug('disposing rootstore');

    ThemeStore.setTheme('light');
    for(const disposer of this.disposers)
      disposer();

    this.products.dispose();
    this.content.dispose();
    this.ui.dispose();

    if(this.edit)
      this.edit.dispose();
  }
}

export default RootStore;