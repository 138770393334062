import dynamic from "next/dynamic";
import FixedLoader from 'components/FixedLoader';
import { useEffect, useState } from "react";
import { useUser } from "utils/user/lib";
import { fetchAlbumWithUser } from "utils/legacyalbum";

function LegacyAlbumMoreModal(props) {
  const user = useUser();
  const [album, setAlbum] = useState();

  useEffect(() => {

    const promises = [];
    promises.push(fetchAlbumWithUser(user, props.album));
    promises.push(user.FetchJSON(`${process.env.API_URL}/access/album-${props.album}/accessData`)[0]);

    Promise.all(promises)
    .then(([albumData, access]) => {

      setAlbum({
        key: props.album,
        title: access.data.subject.title,
        me: {
          role: access.data.me.role,
          hasBookmarked: albumData.user.following,
        },
        access: access.data,
        settings: {
          ...albumData.settings,
        },
        canContribute: !albumData.user.isAlbumOwner &&albumData.settings.albumContributionsEnabled
      });
      
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(!album)
  {
    return (<FixedLoader />);
  }else
  {
    return <AlbumMoreModalContent {...props} album={album} />
  }
}

const AlbumMoreModalContent = dynamic(() => import("./Modals/LegacyAlbum"), {
  ssr: false,
  loading: () => <FixedLoader />,
});

export default LegacyAlbumMoreModal;