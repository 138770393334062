import { observable, computed, action, reaction, toJS, runInAction, makeObservable } from "mobx";
import { v4 as uuidv4 } from 'uuid';
import Spread from './content-type/spread-model';
import Contributors from './content-type/contributors-model';

const defaultData = {
  html: {
    html: ''
  }
}

class ContentModel {
  data = {};
  _isSelected = false;
  _isDeletable = true;
  pinPosition = "";
  _maxLayout = {
    width: 0,
    height: 0,
    inset: {top: 0}
  };
  _seperatorHeight = 0;
  _seperatorWidth = 0;
  layout = {width: 0, height: 0, bottom: 0, top: 0};
  contentType = undefined;
  disposers = [];

  constructor(store, type, data, key=uuidv4()) {
    makeObservable(this, {
      data: observable,
      _isSelected: observable,
      _isDeletable: observable,
      pinPosition: observable,
      _maxLayout: observable,
      _seperatorHeight: observable,
      _seperatorWidth: observable,
      layout: observable,

      isSelected: computed({keepAlive: true}),
      pin: computed,
      isPinned: computed({keepAlive: true}),
      isDeletable: computed({keepAlive: true}),
      index: computed({keepAlive: true}),
      content: computed,
      size: computed({keepAlive: true}),

      setData: action,
      delete: action,
      moveTo: action,
      touchLayout: action,
      calculateLayout: action,

    });

    if(data===undefined)
      data = {};

    if(data===undefined && defaultData[type])
      data = defaultData[type];

    this.store = store;
    this.type = type;
    this.data = data;
    this.key = key;

    switch(this.type)
    {
      case "spread":
        this.contentType = new Spread(this);
      break;
      case "clone-spread":
        this.clone = this.store.getByKey(this.data.target);
        this.contentType = new Spread(this.clone);
        this.forceViewMode = this.data.forceViewMode;
      break;
      case "contributors":
        this.contentType = new Contributors(this);
      break;
    }

    const uiStore = this.store.store.ui;
    this.disposers.push(
      reaction(
        () => uiStore.viewMode + JSON.stringify(uiStore.seperator) + '-' + uiStore.ratio + '-' + (uiStore.editMode ? 'editMode' : ''),
        () => {
          this.calculateLayout(true);
        }
      )
    );
  }

  get isSelected() {
    return !!this._isSelected;
  }

  set isSelected(state) {
    if(this._isSelected===!!state)
      return;

    this._isSelected = !!state;
    //this.store.checkSelectionChanged();
  }

  set pin(position)
  {
    if(!position) {
      this.pinPosition = "top";
    } else {
    this.pinPosition = position;
    }
  }

  get pin() {
    return this.pinPosition;
  }

  get isPinned() {
    return this.pinPosition!==false && this.pinPosition.length>0;
  }

  get isDeletable() {
    return this._isDeletable;
  }

  set isDeletable(state)
  {
    this._isDeletable = !!state;
  }

  get size() {
    return {
      size: this.layout.height,
      before: this.layout.top,
      after: this.layout.bottom,
    }
  }

  setData = (data) => {
    this.data = Object.assign(this.data, data);
  }

  delete = () => {
    if(this.isDeletable)
      this.store.delete(this);
    else
      console.warn("Tried to delete undeletable content");
  }

  moveTo = (index) => {
    if(index===-1)
      index = this.store.items.length-1;
    if(index==='insertIndex')
    {
      index = this.store.insertIndex;
      if(this.index<=index)
        index--;
    }

    if(this.index<index)
      index--;
    
    // we halen onszelf tijdelijk uit het array en plaatsen ons daarna op de juiste plek terug
    // als we geen remove doen gaat er iets in de rendering mis, mogelijk ziet MobX het niet op tijd ofzo
    this.store.items.remove(this);
    this.store.items.splice(index, 0, this);
  }

  get index() {
    return this.store.getIndex(this.key);
  }

  get label() {
    const index = this.index;
    if(index===0) {
      return '';
    }else {
      const basenr = index * 2;
      return ((basenr-1) + '-' + basenr);
    }
  }

  // De layout wordt zometeen opgevraagd, check ff of we misschien iets moeten updaten
  touchLayout() {
    this.touched = true;
    const uiStore = this.store.store.ui;
    const dimensions = uiStore.dimensions;
    if(dimensions.width===0 || dimensions.height===0)
      return;

    const seperatorLayout = uiStore.getSeperatorLayout(dimensions.width);
    const width = dimensions.width;
    const height = dimensions.height - (uiStore.editMode ? (uiStore.editBarHeight + 65 + 65) : 65); // editbar en toolbar correctie in editmode
    const seperatorHeight = seperatorLayout.height;
    const seperatorWidth = seperatorLayout.width;

    if(width===this._maxLayout.width && height===this._maxLayout.height && seperatorWidth===this._seperatorWidth && seperatorHeight===this._seperatorHeight)
      return;

    this._maxLayout.width = Math.round(width);
    this._maxLayout.height = Math.round(height);
    this._maxLayout.inset = {top: 0};
    this._seperatorWidth = seperatorWidth;
    this._seperatorHeight = seperatorHeight;

    this.calculateLayout(true);
  }

  calculateLayout(force) {
    const uiStore = this.store.store.ui;
    if(!this.touched)
    {
      // UI nog niet aangeraakt dus heeft meten nog geen zin
      return;
    }

    if(force || this.layout.width===0 || this.layout.height===0 || uiStore.viewMode!==this._viewMode || uiStore.ratio!==this._ratio || uiStore.editMode!==this._editMode)
    {
      this._viewMode = uiStore.viewMode;
      this._ratio = uiStore.ratio;
      this._editMode = uiStore.editMode;

      if(this._maxLayout.width<=0 || this._maxLayout.height<=0)
        return;

      // Calculate width & height for this item
      switch(this.type)
      {
        case "html":
          this.layout.width = this._maxLayout.width;
          this.layout.height = 100;
          this.layout.bottom = this._seperatorHeight;
        break;
        case "clone-spread":
        default:
          if(this.data?.onlyViewInBookMode && this.store.store.ui.viewMode!=='book')
          {
            this.layout.width = this._maxLayout.width;
            this.layout.height = 0.0001; // Technisch niet correct, maar anders denkt lazylist dat hij nog op layout van dit item moet wachten
            this.layout.bottom = 0;
            return;
          }

          let viewMode = this._viewMode;

          // Eventueel heeft een page een eigen designType
          const allowedDesignTypes = ['cover'];
          if(
            this.contentType?.pages?.length===1 &&
            allowedDesignTypes.includes(this.contentType.pages[0]?.design?.type) &&
            this.contentType?.pages[0].design.type!==undefined
          )
          {
            if(this.store.store.ui.viewMode==='book')
            {
              viewMode = "book-"+this.contentType.pages[0].design.type;
            }else
            {
              viewMode = this.contentType.pages[0].design.type;
            }
          }

          if(this.contentType!==undefined)
          {
            this.contentType.getSize({
              viewMode: this.data?.forceViewMode ? this.data?.forceViewMode : viewMode,
              viewPort: toJS(this._maxLayout),
              seperatorWidth: this._seperatorWidth,
              seperatorHeight: this._seperatorHeight,
            })
              .then((size) => {
                runInAction(() => {
                  this.layout.width = Math.round(size.width);
                  this.layout.height = Math.round(size.height);
                  this.layout.bottom = this._seperatorHeight;
                });
              });
          }else
          {
            if(!this._unknownWarningTriggered)
            {
              this._unknownWarningTriggered = true;
              console.warn("Unkown content type: '"+this.type+"'");
            }
          }
        break;
      }
    }
  }

  get content() {
    const layout = this.layout;
    let style = {
      width: layout.width,
      height: layout.height,
      marginBottom: layout.bottom,
      position: "relative",
    }

    let returnValue = null;
    switch(this.type)
    {
      case "html":
        if(typeof this.data.html === 'string')
        {
          returnValue = (
            <div
              key={this.key}
              dangerouslySetInnerHTML={{__html: this.data.html}}
              style={style}
            />
          );
        }else
          returnValue = this.data.html;
      break;
      case "clone-spread":
      default:
        if(this.data?.onlyViewInBookMode && this.store.store.ui.viewMode!=='book')
          return null;

        if(this.contentType!==undefined)
        {
          returnValue = this.contentType.render(this.key, style);
        }else
        {
          if(!this._unknownWarningTriggered)
          {
            this._unknownWarningTriggered = true;
            console.warn("Unkown content type: '"+this.type+"'");
          }
        }
      break;
    }

    return returnValue;
  }

  dispose() {
    for(const disposer of this.disposers)
      disposer();

    if(this.contentType)
      this.contentType.dispose();
  }
}

export default ContentModel;