export function uploadStateToView(upload)
{
  switch(upload.state)
  {
    case upload.STATE_WAITING:
      return "title";
    case upload.STATE_IN_PROGRESS:
      return "progress";
    case upload.STATE_COMPLETE:
    case upload.STATE_ERROR:
      return "ready";
  }
}

export function isAndroid()
{
  if(typeof navigator !== "undefined")
  {
    if(navigator.userAgent.toLowerCase().indexOf("android") > -1)
      return true;
  }

  return false;
}

export function isMobileSafariOrAndroid()
{
  if(typeof navigator !== "undefined")
  {
    if(isAndroid())
      return true;
    if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1)
      return true;
    if(navigator.userAgent.toLowerCase().indexOf("ipad") > -1)
      return true;
    // @ts-ignore
    if(typeof window.DocumentTouch !== "undefined" && document instanceof window.DocumentTouch)
      return true;
  }

  return false;
}

type FileSelectAttributes = {
  multiple?: boolean;
  accept?: string[];
}

export function selectFiles(callback = (files: FileList) => {}, attributes: FileSelectAttributes = {})
{
  const fileSelect = document.createElement("input");
  fileSelect.style.position = "absolute";
  fileSelect.style.top = "-100px";
  fileSelect.style.left = "-100px";
  fileSelect.style.width = "1px";
  fileSelect.style.height = "1px";

  fileSelect.setAttribute('type', 'file');
  fileSelect.addEventListener('change', (event) => {
    callback((event.target as HTMLInputElement).files);
  });

  Object.entries(attributes).forEach(([key, value]) => {
    fileSelect.setAttribute(key, String(value));
  });

  document.body.appendChild(fileSelect);
  fileSelect.click();

  return () => {
    fileSelect.remove();
  }
}