import Events from '../../../utils/EventsOld';

class ContentType
{
  disposers = [];

  constructor(contentItem) {
    new Events(this);
    this.contentItem = contentItem;
  }

  render(key, style)
  {
    return (
      <div
        key={"content-type-"+key}
        style={style}
      />
    );
  }

  getSize() {
    return Promise.reject("getSize not implemented (content-type/"+this.contentItem.type+")");
  }

  dispose() {
    for(const disposer of this.disposers)
      disposer();
  }
}

export default ContentType;