import React, { useEffect } from 'react';
import Error from 'next/error'
import { reaction } from 'mobx';
import AlbumMenuBar from 'components/Album/MenuBar';
import Album from 'components/Album/Album';
import AlbumHead from 'components/AlbumHead';
import NotFound from 'pages/404';
import RootStore from 'stores/album/root-store';
import AlbumContext from 'stores/album/context';
import { useUser, createUserFromContext } from 'utils/user/lib';
import translationStore from 'stores/translation-store';
import { fetchAlbumProductsWithUser, fetchAlbumWithUser, logAlbumView } from 'utils/album';
import { fetchAlbumWithUser as fetchLegacyAlbumWithUser } from 'utils/legacyalbum';
import LegacyAlbum from 'components/Album/Legacy';
import {fetchAccessForUser} from 'utils/access';
import NoAccess from 'components/NoAccess/NoAccess';
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { Observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { createTranslationsFromContext } from 'utils/translations/lib';


function AlbumPage(props) {
  const statusCode = props.statusCode;
  const version = props.version;
  const invite = props.invite;
  const user = useUser();
  const router = useRouter();
  const slug = `album-${props.album?.data.key}`;

  useEffect(() => {
    // Alleen als we een album hebben, bij 404 is er b.v. geen album en is dus ook geen retry nodig
    if(!props.album?.data.key)
      return;
    
    const userStateDispose = reaction(
      () => user.isLoggedIn,
      () => {        
        fetchAccessForUser(user, slug, invite)
        .then( access => {
          if(props.access.me.hasAccess && !access.me.hasAccess)
          {
            // We hadden toegang, maar nu  niet meer
            router.replace(router.asPath);
          }else if(!props.access.me.hasAccess && access.me.hasAccess)
          {
            // We hadden geen toegang maar nu wel
            router.replace(router.asPath);
          }
        });
      }
    );

    return () => {
      userStateDispose();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  switch(statusCode) {
    case 200:
    case 401:
      if(!props.access.me?.hasAccess)
      {
        return (
          <Observer>
          {
            () => {
              return (
                <NoAccess
                  access={props.access}
                  slug={slug}
                  invite={invite}
        
                  labels={[
                    {
                      icon: faLock,
                      label: translationStore.translate(`access.${props.access.subject.accessLevel}.title`)
                    },
                    {
                      icon: faUsers,
                      label: translationStore.translate(`followers.title`, {count: props.access.subject.totals.followerCount})
                    }
                  ]}
                />
              )
            }
          }
          </Observer>
        );
      }else
      {
        const cleanProps = {...props};
        delete cleanProps.statusCode;
        delete cleanProps.className;
        delete cleanProps.version;

        let content = null;
        switch(version) {
          case 'v4':
            content = <V4Album {...cleanProps} />
          break;
          case 'legacy':          
            content = <LegacyAlbum {...cleanProps} />
          break;
        }

        return content;
      }
    case 404:
      return <NotFound />;
    default:
      return <Error statusCode={statusCode} />
  }
}



export default AlbumPage;
export async function getServerSideProps(context) {
  const key = context.query.album !== undefined ? context.query.album : null;
  const version = key.length === 12 ? 'legacy' : 'v4';
  const slug = 'album-'+key;
  const user = await createUserFromContext(context);
  const translations = await createTranslationsFromContext(context, user.account?.locale.language);
  const invite = context.query?.invite || null;

  let access;
  try {
    access = await fetchAccessForUser(user, slug, invite);
  }catch (e) {
    return {
      props: {
        statusCode: e.response.status || 500,
        user: user.JSON,
        translations: translations.JSON,
      }
    };
  }
  
  const props = {
    access,
    invite,
    version,
    statusCode: 500,
    album: {
      data: {
        key: key,
      }
    },
  };

  if(access.me.hasAccess)
  {
    const promises = [];
    if(version==='v4')
    {
      // V4 ALBUMS
      promises.push(fetchAlbumWithUser(user, key));
      promises.push(fetchAlbumProductsWithUser(user, key));
      logAlbumView(context.req, user, key);

      await Promise.all(promises)
      .then((values) => {
        const [album, products] = values;

        props.album = album;
        props.statusCode = 200;
        props.products = products;
      })
      .catch(e => {
        // Errors, b.v. 404 doorpassen naar server
        if(e.statusCode!==200 && e.statusCode!==401)
          context.res.statusCode = e.statusCode;

        props.statusCode = e.statusCode;
      })
    }else
    {
      // LEGACY ALBUMS
      promises.push(fetchLegacyAlbumWithUser(user, key)); //data ophalen tov huidige ingelogde gebruiker (voor b.v. isOwner)
      await Promise.all(promises)
      .then(([albumData]) => {        
        props.album.data = albumData.data;
        props.album.settings = albumData.settings;
        props.album.me = albumData?.user ? {
          role: albumData.user.isAlbumOwner ? 'owner' : 'member',
          hasBookmarked: albumData.user.following,
        } : {};
        props.statusCode = 200;
      })
      .catch(e => {      
        // Errors, b.v. 404 doorpassen naar server
        if(e.statusCode!==undefined && e.statusCode!==200 && e.statusCode!==401)
          context.res.statusCode = e.statusCode;

        props.statusCode = e.statusCode;
      });

    }
  }else
  {
    // GEEN 403 anders gaat het uitloggen heel traag. ClientSide refresh wordt dan onmogelijk je 
    // forceert dan een reload van de browser
    context.res.statusCode = 200;
    props.statusCode = 200;
  }

  if(context.req.headers['user-agent']!==undefined && context.req.headers['user-agent'].includes('MyAlbum'))
    props.fromApp = true;

  return {
    props: {
      ...props,
      user: user.JSON,
      translations: translations.JSON,
    }
  };
}



function V4Album(props) {
  const user = useUser();
  const albumKey = props.album.data.key;
  const {contents, ...albumData} = props.album.data;

  const store = React.useRef(
    new RootStore({
      user: user,
      album: {...albumData, settings: props.album.settings},
      products: props.products,
      asset: {
        url: process.env.API_URL + '/album/'+albumKey+'/assets'
      },
      content: contents,
    })
  ).current;

  useEffect(() => {
    const justLoggedInDisposer = reaction(
      () => user.isLoggedIn,
      (state) => {
        if(!state && store.ui.editMode)
          store.ui.setEditMode(false);
      }
    )

    return () => {
      justLoggedInDisposer();
      store.dispose();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Je kan oa hier komen na het uploaden van fotos in editmode
    store.content.hydrate(contents);
  }, [contents]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AlbumContext.Provider value={store}>
      <AlbumHead />
      {
        !props.fromApp &&
        <>
          <AlbumMenuBar />
          <div style={{height: 65}}></div>
        </>
      }

      <Album />
    </AlbumContext.Provider>
  )
}