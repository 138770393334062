import { useRef, useEffect } from 'react';
import MenuBar from './menubar';
import AlbumContext from './context';
import AlbumStore from './store';
import Embed from './embed/embed';
import Dom from '@myalbum/dom';
import { throttle } from 'throttle-debounce';
import AlbumHead from './Head';
import AppInstallBar from 'components/AppInstallBar/AppInstallBar';

function LegacyAlbum(props) {
  const store = useRef(
    new AlbumStore({
      title: props.album.data.title,
      subTitle: props.album.data.subTitle,
      key: props.album.data.key,
      lastUpdate: props.album.data.lastGridUpdate,
      header: props.album.data.header,
      settings: props.album.settings,
      me: props.album.me,
    })
  ).current;

  function onScroll() {
    store.setScrollPosition(Dom(window).scrollTop());
  }

  function onMove(e) {
    const pos = {
      clientY: e.clientY,
      clientX: e.clientX,
    }

    if(e.clientX===undefined && e.touches!==undefined && e.touches.length>0)
    {
      pos.clientX = e.touches[0].clientX;
      pos.clientY = e.touches[0].clientY;
    }

    store.setMousePosition(pos);
  }

  const onScrollThrottle = useRef(throttle(25, onScroll)).current;
  const onMoveThrottle = useRef(throttle(25, onMove)).current;

  useEffect(() => {
    window.addEventListener('scroll', onScrollThrottle, {passive: true});
    window.addEventListener('mousemove', onMoveThrottle, {passive: true});
    window.addEventListener('touchmove', onMoveThrottle, {passive: true});

    return () => {
      window.removeEventListener('scroll', onScrollThrottle, {passive: true});
      window.removeEventListener('mousemove', onMoveThrottle, {passive: true});
      window.removeEventListener('touchmove', onMoveThrottle, {passive: true});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AlbumContext.Provider value={store}>
      <AlbumHead />
      <AppInstallBar />
      <MenuBar />
      <Embed />
    </AlbumContext.Provider>
  )
}

export default LegacyAlbum;