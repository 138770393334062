import css from 'styled-jsx/css'

export const exifGroupStyle = css`
  .info-bar {
    padding: 30px;
  }

  .info-bar h2 {
    font-size: 2.5rem;
    margin: 0px;
    margin-bottom: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
  }

  .info-bar .description {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 16px;
  }

  .info-bar .download {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 14px;

    border: 1px solid white;
    border-radius: 100px;
    line-height: 30px;
    height: 30px;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;

    margin-top: 20px;
    cursor: pointer;
  }
`

export const commentsStyle = css`
  .comments {
    padding: 30px;
  }

  .comments .new-comment {
  }
  .comments .new-comment textarea {
    background-color: rgba(76,76,76, 0.5);
    border: 0px;
    resize: none;
    width: 100%;
    height: 70px;
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 40px;
    color: white;
    font-family: 'MyAlbumMain';
    font-size: 14px !important;
  }
  .comments .new-comment textarea::placeholder {
    color: rgba(255,255,255, 0.5);
  }

  .comments :global(.comment .comment-delete) {
    position: absolute;
    top: 0px;
    right: 6px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: none;
  }
  .comments :global(.comment.deletable:hover .comment-delete) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .comments :global(.comment) {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    position: relative;
  }

  .comments :global(.comment .comment-content) {
    flex: 1;
    margin-left: 10px;
  }

  .comments :global(.comment .comment-name) {
    font-size: 12px;
  }
  .comments :global(.comment .comment-date) {
    opacity: 0.5;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .comments :global(.comment-text) {
    white-space: pre-line;
  }

  .comments :global(.comment + .comment) {
    margin-top: 40px;
  }
`

export const gridStyle = css`
  .grid :global(.griditem-info) {
    height: 32px;
    line-height: 33px;
    overflow: hidden;
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    box-sizing: border-box;
    z-index: 100;
    white-space: nowrap;
    color: white;
    font-size: 12px;
    text-align: center;
    cursor: default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  :global(.myalbum.slideview:not(.show)) {
    visibility: hidden !important;
  }

  .grid :global(.griditem-info):empty {
    display: none;
  }

  .grid :global(.griditem-comments) {
    display: inline-block;
    opacity: 1;
    height: inherit;
    line-height: inherit;
  }
  .grid :global(.griditem-comments[data-amount]:after) {
    content: attr(data-amount);
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    font-size: 14px;
    height: 32px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255,255,255, 0.5);
    border-radius: 3px;
    box-sizing: border-box;
  }
  .grid :global(.griditem-comments[data-amount="0"]) {
    display: none;
  }
`