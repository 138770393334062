import { PureComponent } from 'react';
import {attachmentCss} from "./style";

class Attachment extends PureComponent {

  render() {
    let style = {
      height: this.props.height,
      width: "100%",
      backgroundColor: this.props.backgroundColor,
      borderTopColor: this.props.arrowColor,
      borderBottomColor: this.props.arrowColor,
      zIndex: this.props.index,

      ...this.props.style,
    };

    return (
      <div
        style={style}
        className={"page-attachment arrow-"+this.props.arrow+' '+this.props.className}
      >
        {
          this.props.children
        }

        <style jsx>{attachmentCss}</style>
      </div>
    )
  }
}

Attachment.defaultProps = {
  arrowColor: "white",
  backgroundColor: "transparent",
  arrow: "bottom",
  style: {},
  className: "",
}

export default Attachment;