import { useContext } from 'react';
import Head from 'next/head';
import AlbumContext from './context';
import { CustomGoogleAnalytics } from 'components/AlbumHead';
import { NextSeo } from 'next-seo';

export default function AlbumHead() {
  /** @type {import("./store").default} */
  const store = useContext(AlbumContext);
  const link = `${process.env.SITE_URL}/album/${store.key}/`;
  const cover = `${link}coverphoto.jpg`;

  const noIndexNoFollow = !store?.seo?.albumIndexingEnabled || false;

  return (
    <>
      <Head>
        {
          store?.seo?.ua && <CustomGoogleAnalytics ua={store.seo.ua} />
        }
      </Head>
      <NextSeo
        title={store.title}
        description={store.subtitle || " "}
        noindex={noIndexNoFollow}
        nofollow={noIndexNoFollow}
        openGraph={{
          title: store.title,
          description: store.subtitle,
          url: link,
          images: [{
            url: cover,
            width: 1200,
            height: 675,
          }]
        }}
      />
    </>
  );
}