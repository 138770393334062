import { useMemo, useContext, useState, useCallback, useRef } from 'react';
import AlbumContext from './context'
import { runInAction } from 'mobx';
import { Observer } from 'mobx-react'
import translationStore from 'stores/translation-store'
import { useUser } from 'utils/user/lib';
import isDev from "utils/isDev";
import { IconButton, MenuBar } from 'components/UI'
import ShareModal from 'components/ShareModal/ShareModal';
import AlbumMoreModal from 'components/Album/MoreModal/LegacyAlbum';

import { faShareAlt } from "@fortawesome/pro-light-svg-icons/faShareAlt";
import { faBookmark } from "@fortawesome/pro-light-svg-icons/faBookmark";
import { faPencil } from "@fortawesome/pro-light-svg-icons/faPencil";
import { faEllipsisV as moreMenuIcon } from "@fortawesome/pro-light-svg-icons/faEllipsisV";
import { faBookmark as faBookmarkFilled } from "@fortawesome/pro-solid-svg-icons/faBookmark";


function AlbumMenuBar() {
  /** @type {import('./store').default} */
  const album = useContext(AlbumContext)
  const user = useUser();
  const [moreOpen, setMoreOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const editWindowRef = useRef();

  const toggleBookmark = useMemo(() => {
    return () => {
      const newState = !album.me.hasBookmarked;
      runInAction(() => {
        album.me.hasBookmarked = newState;
      });

      user.securePost("follow", `/legacyalbum/${album.key}/follow`, {
        'follow': newState ? 1 : 0,
      })
      .then(() =>
      {
      })
      .catch(()=>{});
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openAlbumEdit = useCallback(() => {
    const pos = album.getRelativeScrollPosition();
    const hash = pos?.item?.item?.key ? '#item-'+pos?.item?.item?.key+'-'+pos?.top : '';
    const timestamp = new Date().getTime();
    const url = `${process.env.SITE_URL}/legacyalbum/${album.key}/?p=${timestamp}${hash}`;
    if(editWindowRef.current && editWindowRef.current.opener)
    {
      // Deze truc moet omdat Chrome zich niet aan de standaarden houdt
      // chrome opent altijd een nieuwe window met window.open, ook al 
      // is de target hetzelfde gebleven
      editWindowRef.current.location.href=url;
      editWindowRef.current.focus();
      return;
    }

    editWindowRef.current = window.open(url, `album-${album.key}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showShareModal = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  const showMore = useCallback(() => {
    setMoreOpen(true);
  }, []);

  return (
    <Observer>
    {
      () => {
        const hasBookmarked = album.me.hasBookmarked;
        const showBookmarkOption = album.me.role!=='owner';
        const showEditOption = album.me.role==='owner';
        const showShareOptions = album.me.role==='owner' || album.settings.albumShareVisible;
        const hasOrderRights = album.me.role==='owner' || album.settings.albumShopVisible;
        const showBranding = album.settings.albumLogoVisible;
        const cartMenuItems = [];

        if(hasOrderRights) {
          const shopUrl = isDev ? `${process.env.SITE_URL}/order/photobook/${album.key}` : `/order/photobook/${album.key}/`;
          cartMenuItems.push({
            type: "link",
            className: "accent",
            title: translationStore.translate('legacy.order-photobook'),
            href: shopUrl,
          });
        }

        return (
          <>
            <MenuBar visible={ album.menuBarVisible }>
              <div key="left">
                <MenuBar.Back />
              </div>

              <div key="mobile">
                <MenuBar.Back key="shortback" />

                {
                  showShareOptions &&
                    <IconButton
                      icon={faShareAlt}
                      key="share-button"
                      className="menu-bar-button"
                      onClick={showShareModal}
                    />
                }

                {
                  showBookmarkOption &&
                  <IconButton
                    icon={hasBookmarked ? faBookmarkFilled : faBookmark}
                    onClick={toggleBookmark}
                    className="menu-bar-button"
                    style={{
                      color: hasBookmarked ? 'rgba(var(--accentColor), 1)' : 'rgba(var(--accentColorDark), 1)',
                    }}
                  />
                }

                {
                  <IconButton
                    icon={moreMenuIcon}
                    key="more-icon"
                    className="menu-bar-button"
                    onClick={showMore}
                  />
                }

                <MenuBar.Cart items={cartMenuItems} />
              </div>

              <div key="center">
                { showBranding && <MenuBar.Logo /> }
              </div>

              <div key="right">
                {
                  showEditOption &&
                  <IconButton
                    icon={faPencil}
                    key={"edit-button"}
                    className="menu-bar-button"
                    onClick={openAlbumEdit}
                  />
                }

                {
                  showShareOptions &&
                    <IconButton
                      icon={faShareAlt}
                      key="share-button"
                      className="menu-bar-button"
                      onClick={showShareModal}
                    />
                }

                {
                  showBookmarkOption &&
                  <IconButton
                    icon={hasBookmarked ? faBookmarkFilled : faBookmark}
                    onClick={toggleBookmark}
                    className="menu-bar-button"
                    style={{
                      color: hasBookmarked ? 'rgba(var(--accentColor), 1)' : 'rgba(var(--accentColorDark), 1)',
                    }}
                  />
                }

                <IconButton
                  icon={moreMenuIcon}
                  key="more-icon"
                  className="menu-bar-button"
                  onClick={showMore}
                />

                <MenuBar.Cart items={cartMenuItems} />
              </div>
            </MenuBar>

            {
              moreOpen && (
                <AlbumMoreModal
                  album={album.key}
                  legacyalbum={album}
                  onClose={() => {
                    setMoreOpen(false);
                  }}
                />
              )
            }

            {
              isShareModalOpen && <ShareModal
                onClose={() => setIsShareModalOpen(false)}
                subject={{
                  key: album.key,
                  type: 'album',
                }}
              />
            }
          </>
        );
      }
    }
    </Observer>
  );
}

export default AlbumMenuBar;