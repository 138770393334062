import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import { faEllipsisH } from "@fortawesome/pro-light-svg-icons/faEllipsisH";
import { faLink } from "@fortawesome/pro-light-svg-icons/faLink";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faCode } from "@fortawesome/pro-light-svg-icons/faCode";
import useScript from "hooks/useScript";
import style from "./style";
import translationStore from "stores/translation-store";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";

export default function SocialShare(props)
{
  const { size, embedCode } = props;
  const [copiedLink, setCopiedLink] = useState(false);
  const [copiedEmbed, setCopiedEmbed] = useState(false);
  const [facebookLoaded, setFacebookLoaded] = useState(false);

  useScript(useCallback(() => {
    window.FB.init({
      appId: process.env.SHARE_FACEBOOKAPPID,
      autoLogAppEvents: false,
      xfbml: false,
      version: "v9.0"
    });

    setFacebookLoaded(true);

    return () => {
      setFacebookLoaded(false);
    }
  }, []), {
    url: "https://connect.facebook.com/en_US/sdk.js",
    options: {referrerPolicy: "no-referrer"},
  });

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(props.url)
    .then(() => setCopiedLink(true))
    .catch(() => {});
  }, [props.url]);

  useEffect(() =>
  {
    if(copiedLink)
    {
      const timeout = setTimeout(() =>
      {
        setCopiedLink(false);
      }, 2000);

      return () =>
      {
        clearTimeout(timeout);
      }
    }
  }, [copiedLink]);

  useEffect(() =>
  {
    if(copiedEmbed)
    {
      const timeout = setTimeout(() =>
      {
        setCopiedEmbed(false);
      }, 2000);

      return () =>
      {
        clearTimeout(timeout);
      }
    }
  }, [copiedEmbed]);

  return (
    <>
      <div className={"social-share" + (props.darkMode ? " dark-mode" : "")}>
        <div className="row">
          {
            props.options.map(option => {
              switch(option) {
                case 'whatsapp':
                  return (
                    <button
                      title={"WhatsApp"}
                      key="whatsapp"
                      style={{width: size, height: size}}
                      className="button whatsapp"
                      onClick={() =>
                      {
                        const win = window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(props.url)}`);
                        win.focus();
                      }}
                    >
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </button>
                  );
                case 'copy':
                  return (
                    <button
                      title={translationStore.translate("base.link")}
                      key="copy"
                      style={{width: size, height: size}}
                      className="button link"
                      onClick={copyToClipboard}
                    >
                      <FontAwesomeIcon icon={copiedLink ? faCheck : faLink} />
                    </button>
                  )
                case 'facebook':
                  return (
                    <button
                      title="Facebook"
                      key="facebook"
                      style={{width: size, height: size}}
                      className={["button", "facebook", facebookLoaded ? "loaded" : undefined].filter(x=>x).join(" ")}
                      onClick={() =>
                      {
                        window.FB.ui({
                          method: "share",
                          href: props.url,
                          display: 'popup'
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </button>
                  );

                case 'twitter':
                  return (
                    <button
                      title="Twitter"
                      key="twitter"
                      style={{width: size, height: size}}
                      className="button twitter"
                      onClick={() =>
                      {
                        const winUrl = `http://twitter.com/intent/tweet?url=${encodeURIComponent(props.url)}&via=joinmyalbum`;
                        const win = window.open(winUrl, 'twitter', 'location=no,menubar=no,status=no,titlebar=no,toolbar=no,width=600,height=300');
                        win.focus();
                      }}
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </button>
                  )
                case 'mail':
                  return (
                    <a
                      title={translationStore.translate("base.mail")}
                      key="mail"
                      style={{width: size, height: size}}
                      href={`mailto:?body=${encodeURIComponent(props.url)}`}
                      className="button mail"
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                  )
                case 'embed':
                  if(embedCode) {
                    return (
                      <button
                        key="embed"
                        title="Embed"
                        style={{width: size, height: size}}
                        className="button embed"
                        onClick={() => {
                          navigator.clipboard.writeText(embedCode)
                          .then(() => setCopiedEmbed(true))
                          .catch(() => {});
                        }}
                      >
                        <FontAwesomeIcon icon={copiedEmbed ? faCheck : faCode} />
                      </button>
                    )
                  }
                break;
                case 'more':
                  if(window.navigator && window.navigator.share && window.navigator.canShare)
                  {
                    return (
                      <button
                        key="more"
                        style={{
                          width: `calc(${isNaN(size) ? size : `${size}px`} - 10px)`,
                          height: `calc(${isNaN(size) ? size : `${size}px`} - 10px)`
                        }}
                        className="button more"
                        onClick={() => window.navigator.share({url: props.url}).catch(() => {})}
                      >
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </button>
                    )
                  }
              }
            })
          }
        </div>
      </div>
      <style jsx>{style}</style>
    </>
  );
}

SocialShare.defaultProps = {
  options: ['copy', 'mail', 'whatsapp', 'facebook', 'twitter', 'embed', 'more'],
  size: "11vw",
  darkMode: false,
}