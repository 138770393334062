import PhotoShare from "components/PhotoShare/PhotoShare";
import SocialShare from "components/SocialShare/SocialShare";
import { Stack } from "components/UI/ModalV2/ModalV2";
import translationStore from "stores/translation-store";

function AssetShareModal(props) {
  const {asset} = props;
  if(!asset)
    return null;

  const urls = asset.sizes.map(size => size.url);
  const url = urls.slice(-1)[0];

  return (
    <Stack
      onClose={props.onClose}
      width={428}
    >
      <div className='content'>
        <h1>{translationStore.translate('default.share')}</h1>
        
        <SocialShare
          url={url}
          showLink={false}
          size={49}
        />

        {asset.type==='photo' && 
          <>
            <div style={{height: 50}} />

            <PhotoShare
              urls={urls.slice(-3).reverse()}
            />
          </>
        }
      </div>
    </Stack>
  )
}

export default AssetShareModal;