import { useContext, useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { reaction } from 'mobx';
import { Observer } from 'mobx-react'
import { currencyFormat } from 'utils/format';

import { whenAuthenticated } from 'components/Authentication';
import { ToggleButton, IconButton, Menu, Modal, MenuBar } from 'components/UI'
import AlbumReset from 'components/AlbumReset/AlbumReset';
import AlbumMoreModal from 'components/Album/MoreModal/AlbumMoreModal';

import AlbumContext from 'stores/album/context'
import translationStore from 'stores/translation-store'
import { useUser } from 'utils/user/lib';

import { faShareAlt } from "@fortawesome/pro-light-svg-icons/faShareAlt";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faBookmark } from "@fortawesome/pro-light-svg-icons/faBookmark";
import { faCog } from "@fortawesome/pro-light-svg-icons/faCog";
import { faEllipsisV as moreMenuIcon } from "@fortawesome/pro-light-svg-icons/faEllipsisV";
import { faBookmark as faBookmarkFilled } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import ShareModal from 'components/ShareModal/ShareModal';

const productPropsForPrice = {
  size: "L",
  booktype: "softcover"
};

function AlbumMenuBar() {
  /** @type {import('stores/album/root-store').default} */
  const store = useContext(AlbumContext)
  const user = useUser();

  const addMenuRef = useRef(null);
  const settingsMenuRef = useRef(null);
  const [addMenuOpen, setAddMenuOpen] = Menu.useOpener();
  const [moreOpen, setMoreOpen] = useState(false);
  const [showSorting, setShowSorting] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = Menu.useOpener();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  useEffect(() => {
    const userStateDispose = reaction(
      () => user.isLoggedIn,
      () => {
        store.album.updateMe();
        store.products.update();
      }
    );

    return () => {
      userStateDispose();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function toggleAddMenu() {
    setAddMenuOpen(!addMenuOpen);
  }

  function toggleSettingsMenu() {
    setSettingsMenuOpen(!settingsMenuOpen);
  }

  function setEditMode(state) {
    store.ui.setEditMode(state);

    if(!state)
      store.products.update();
  }

  function toggleBookmark()
  {
    const wasLoggedIn = user.isLoggedIn;

    whenAuthenticated(user, {
      message: translationStore.translate("album.bookmark.login"),
    }).then(() =>
    {
      if(!wasLoggedIn)
      {
        store.album.updateMe().then(() =>
        {
          store.album.setBookmark(true);
        });
      }
      else
        store.album.toggleBookmark();
    })
  }

  // Scrollt huidige geselecteerde pagina in beeld
  // Als selectie leeg is selecteren we de huidige pagina die in beeld staat
  function scrollEditPageInView() {
    if(store.content.selection.length===0)
    {
      if(store.content.items.length>store.content.indexInView)
        store.content.items[store.content.indexInView].isSelected = true;
    }

    store.content.trigger('scrollSelectionIntoView');
  }

  function pickIcon() {

    // We ondersteunen geen icons op de cover
    const isCover = (store.edit.activeSpread.viewMode==='book-cover');
    if(isCover)
      return;

    store.ui.editor.then(editor => {
      editor.icon.picker({
        spread: store.edit.activeSpread,
        onClose: (result) => {
          if(result)
          {
            const activePage = store.edit.activePage;
            editor.icon.addIconToPage(result.icon, result.tags, activePage);
          }
        }
      });
    });
  }

  function addText() {
    store.ui.editor.then(editor => {

      const designsPromise = new Promise(resolve => {
        const [promise] = store.edit.activeSpread.getSuggestions({mode: 'addtext'});
        promise.catch(() => {
          resolve([]);
        });
        promise.then(res => {
          resolve(res.suggestions);
        });
      });

      const pageNr = store.edit.activeSpread.contentItem.index * 2;
      editor.textDesignModal.show({
        designs: designsPromise,
        labels: [
          translationStore.translate("base.pagenumber", {page: pageNr-1}),
          translationStore.translate("base.pagenumber", {page: pageNr})
        ],
        onClose: (design) => {

          if(design)
          {
            const activeSpread = store.edit.activeSpread;
            activeSpread.setPages(design.data.pages);
            activeSpread.save();
          }

        }
      });
    });
  }


  const albumPrice = useMemo(() =>
  {
    const product = store.products.findPhotobook(productPropsForPrice);
    if(product)
    {
      if(product.albumPageCount <= 200)
        return currencyFormat(translationStore.getLanguage(), store.products.currency, (product.price / 100));
    }

    return null;
  }, [store.products.products, translationStore.language]); // eslint-disable-line react-hooks/exhaustive-deps


  const openShareModal = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);


  const showMore = useCallback(() => {
    setMoreOpen(true);
  }, []);


  return (
    <Observer>
    {
      () => {
        const hasOrderRights = ( store.album.settings.canOrder || store.album.me.role === "owner" );
        const hasPrice = (albumPrice !== null);
        const cartMenuItems = [];
        if(hasOrderRights) {
          cartMenuItems.push({
            type: "separator",
          });

          cartMenuItems.push({
            type: "textBlock",
            title: <div style={{
              fontSize: 21,
              whiteSpace: "nowrap",
              opacity: hasPrice ? 1 : 0.5
            }}>{translationStore.translate('minicart.order-album-title')}</div>,
            text: 
              hasPrice ? <div>
                {translationStore.translate('minicart.order-album-photos', {count: store.products.assetCount})} / {translationStore.translate('minicart.order-album-pages', {count: store.content.spreadCount * 2})}
              </div> 
              : <div style={{opacity: 0.5}}>
                {translationStore.translate('minicart.less-pages-to-order', {count: (store.content.spreadCount * 2) - 198})}
              </div>,
            readonly: true,
            multiline: true,
          });

          if(hasPrice) {
            cartMenuItems.push({
              type: "link",
              className: "accent",
              title: translationStore.translate('minicart.order-album', {price: albumPrice}),
              href: `/shop/photobook/[album]/`,
              as: `/shop/photobook/${store.album.key}/`,
            });
          }
        }


        return <>
          <MenuBar
            key="menubar"
            visible={ store.ui.menuBarVisible }
          >
            <div key="left">
              <MenuBar.Back />

              {!store.ui.isSmallScreen && store.album.me.role==='owner' && (
                <ToggleButton
                  label={translationStore.translate('menubar.editmode-label')}
                  onChange={setEditMode}
                  isProcessing={store.ui.isProcessingEditMode}
                  checked = {store.ui.editMode}
                  style={{marginLeft: 15, marginRight: 15}}

                  data-tip={store.ui.editMode ? translationStore.translate('tooltip.deactivate-editmode') : translationStore.translate('tooltip.activate-editmode')}
                  data-tip-align="bottom"
                  key="edit-toggle"
                />)
              }

              {store.ui.editMode && (
                <>
                  <MenuBar.Button
                    onClick={toggleAddMenu}
                    ref={addMenuRef}
                  >
                    <IconButton
                      label={translationStore.translate("menubar.add-label")}
                      icon={faPlus}
                      key="add-item"
                    />
                  </MenuBar.Button>

                  <Menu
                    key="edit-menu"
                    roundedBorder={[false, false, true, true]}
                    isOpen={addMenuOpen}
                    position={{ref: addMenuRef, hAlign: "left", vAlign: "bottom", offsetY: -1}}
                    onRequestClose={() => setAddMenuOpen(false)}
                  >
                    <Menu.Item
                      title={translationStore.translate("base.page")}
                      onClick={() => {
                        store.content.createBlankPage();
                      }}
                    />
                    <Menu.Separator />
                    <Menu.Item
                      title={translationStore.translate("base.photos-videos")}
                      onClick={() => {
                        store.ui.editor.then(editor => {
                          editor.addAssetModal.show({album: store.album, to: 'album'});
                        });
                      }}
                    />
                    <Menu.Item
                      title={translationStore.translate("base.text")}
                      disabled={!(store.edit.activeSpread && store.edit.activeSpread.viewMode!=="book-cover")}
                      onClick={() => {
                        scrollEditPageInView();
                        addText();
                      }}
                    />
                    <Menu.Item
                      title={translationStore.translate("base.icon")}
                      disabled={!(store.edit.activeSpread && store.edit.activeSpread.viewMode!=="book-cover")}
                      onClick={() => {
                        scrollEditPageInView();
                        pickIcon();
                      }}
                    />
                  </Menu>

                  <MenuBar.Button
                    onClick={toggleSettingsMenu}
                    ref={settingsMenuRef}
                  >
                    <IconButton
                      icon={faCog}
                      key="add-item"
                    />
                  </MenuBar.Button>

                  <Menu
                    key="settings-menu"
                    roundedBorder={[false, false, true, true]}
                    isOpen={settingsMenuOpen}
                    position={{ref: settingsMenuRef, hAlign: "left", vAlign: "bottom", offsetY: -1}}
                    onRequestClose={() => setSettingsMenuOpen(false)}
                  >
                    <Menu.Item
                      title={translationStore.translate('menubar.sorting-label')}
                      onClick={() => setShowSorting(true)}
                    />
                    <Menu.Item
                      title={translationStore.translate('album.reset.title')}
                      onClick={() => setShowSorting(true)}
                    />
                  </Menu>
                </>
              )}
            </div>

            <div key="mobile">
              <MenuBar.Back key="shortback" />
              
              {
                (store.album.settings.showShareOptions || store.album.me.role === "owner") &&
                <IconButton
                  icon={faShareAlt}
                  key="share-button"
                  className="menu-bar-button"
                  onClick={openShareModal}
                />
              }

              {
                store.album.me.loaded && store.album.me.role !== "owner" &&
                <IconButton
                  icon={store.album.me.hasBookmarked ? faBookmarkFilled : faBookmark}
                  key="bookmark-icon"
                  className="menu-bar-button"
                  onClick={() => toggleBookmark()}
                  style={{
                    color: store.album.me.hasBookmarked ? 'rgba(var(--accentColor), 1)' : 'rgba(var(--accentColorDark), 1)'
                  }}
                />
              }

              <MenuBar.Cart items={cartMenuItems} />

              <IconButton
                icon={moreMenuIcon}
                key="more-icon"
                className="menu-bar-button"
                onClick={showMore}
              />
            </div>

            <div key="center">
              { store.album.settings.showMyAlbumBranding && <MenuBar.Logo /> }
            </div>

            <div key="right">
              {
                (store.album.settings.showShareOptions || store.album.me.role === "owner") &&
                <>
                  <IconButton
                    icon={faShareAlt}
                    key="share-button"
                    className="menu-bar-button"
                    onClick={openShareModal}
                  />
                </>
              }

              {
                store.album.me.loaded && store.album.me.role !== "owner" &&
                <IconButton
                  icon={store.album.me.hasBookmarked ? faBookmarkFilled : faBookmark}
                  onClick={() => toggleBookmark()}
                  className="menu-bar-button"
                  style={{
                    color: store.album.me.hasBookmarked ? 'rgba(var(--accentColor), 1)' : 'rgba(var(--accentColorDark), 1)'
                  }}
                />
              }

              <IconButton
                icon={moreMenuIcon}
                key="more-icon"
                className="menu-bar-button"
                onClick={showMore}
              />

              <MenuBar.Cart items={cartMenuItems} />
            </div>
          </MenuBar>

          {
            moreOpen && (
              <AlbumMoreModal
                album={store.album.key}
                onClose={() => {
                  store.album.updateSettings();
                  setMoreOpen(false);
                }}
              />
            )
          }

          {
            showSorting &&
            <Modal.Inline
              onClose={() => setShowSorting(false)}
              modalStyle={{maxWidth: 500}}
              allowClose={false}
            >
              <div key="header">
                <div className="title">{translationStore.translate("album.reset.title")}</div>
                <div className="description">{translationStore.translate("album.reset.description")}</div>
              </div>
              <div key="content">
                <AlbumReset onClose={() => setShowSorting(false)} user={user} album={store.album} />
              </div>
            </Modal.Inline>
          }

          {
            isShareModalOpen && <ShareModal
              onClose={() => setIsShareModalOpen(false)}
              subject={{
                key: store.album.key,
                type: 'album',
              }}
            />
          }
        </>
      }
    }
    </Observer>
  );
}

export default AlbumMenuBar;