import { Component } from 'react';
import { observer } from 'mobx-react';
import translationStore from '../stores/translation-store';

class ExifGroup extends Component
{
  render()
  {
    let fields = [];
    for(let key in this.props.fields)
    {
      let config = this.props.fields[key];
      let value = this.props.data[key];
      if(value!==undefined)
      {
        if(config.values!==undefined && config.values[value])
          value = translationStore.translate(config.values[value]);

        if(Array.isArray(value))
          value = value.join(", ");

        let label = null;
        if(config.fullwidth===undefined)
          label = <div className="sidebar-row-label">{translationStore.translate(config.label)}</div>;

        if(config.suffix!==undefined && !(typeof config.suffix === 'string' || config.suffix instanceof String))
        {
          // Object in de suffix
          // Vertaal naar een string
          // Wordt bv gebruikt voor dimensions width and height
          let suffix = '';
          if(config.suffix.prefix!==undefined)
            suffix += config.suffix.prefix;

          suffix += this.props.data[config.suffix.field];
          config.suffix = suffix;
        }

        if(config.prefix && translationStore.translationExists(config.prefix))
          config.prefix = translationStore.translate(config.prefix);
        if(config.suffix && translationStore.translationExists(config.suffix))
          config.suffix = translationStore.translate(config.suffix);

        fields.push(
          <div className="sidebar-row" key={"row-"+key}>
            {label}
            <div className="sidebar-row-value">{config.prefix}{value}{config.suffix}</div>
          </div>
        );
      }
    }

    if(fields.length===0)
      return null;

    return (
      <div className="sidebar-group">
        <div className="sidebar-group-title">{translationStore.translate(this.props.title)}</div>
        {fields}

        <style jsx>{`
          .sidebar-group {
            margin-top: 30px;
          }

          .sidebar-group .sidebar-group-title {
            margin: 0px;
            margin-bottom: 10px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: normal;
            font-size: 14px;
          }

          :global(.sidebar-row) {
            font-family: 'Roboto Slab', serif;
            font-size: 11px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            line-height: 135%;
          }

          :global(.sidebar-row + .sidebar-row) {
            margin-top: 5px;
          }

          :global(.sidebar-row-label) {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 15px;
            opacity: 0.6;
          }

          :global(.sidebar-row-value) {
            flex: 1;
            word-break: break-word;
          }
        `}</style>
      </div>
    )
  }
}

export default observer(ExifGroup);