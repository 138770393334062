import { faSortAlt } from "@fortawesome/pro-duotone-svg-icons/faSortAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Loader, Modal, Select, CheckBox, ProgressBar } from "components/UI";
import { useState } from "react";
import translationStore from "stores/translation-store";

export default function AlbumReset(props)
{
  const { user, album } = props;
  const [sortMethod, setSortMethod] = useState(album.settings?.sorting?.method || 'exifTime');
  const [sortOrder, setSortOrder] = useState(album.settings?.sorting?.order || 'asc');
  const [enrichEnabled, setEnrichEnabled] = useState(album.settings.autoEnrich);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);

  function toggleSortOrder()
  {
    if(sortOrder === "asc")
      setSortOrder('desc');
    else if (sortOrder === "desc")
      setSortOrder('asc');
  }

  function onSort()
  {
    if(isProcessing)
      return;

      setIsProcessing(true);
    const generateConfig = {
      sorting: {
        method: sortMethod,
        order: sortOrder,
      },
      autoEnrich: enrichEnabled,
    }

    const [promise] = user.FetchJSON(`${process.env.API_URL}/album/${album.key}/generate`, {
      method: "POST",
      data: generateConfig,
    })
    promise.then(({data}) => {
      setProgress(data.job.progress);
      trackProgress(data.job.id);
    })
    .catch((e) => {
      setIsProcessing(false);
      Modal.Alert({
        title: `Error: ${e.statusCode || 0}`,
        description: JSON.stringify(e)
      });
    });
  }

  function trackProgress(jobId, errors=0) {
    new Promise(resolve => {
      const [promise] = user.FetchJSON(`${process.env.API_URL}/album/${album.key}/status/${jobId}`);
      promise.then(({data}) => {
        resolve({result: data, errors});
      });
      promise.catch((e) => {
        resolve({result: {e}, errors: errors+1});
      })
    })
    .then(({result, errors}) => {
      if(result?.progress) {
        setProgress(result.progress);
      }

      if(result?.finished) {
        album.reloadAndEnableEditMode();
      }

      if(errors===3) {
        // 3 errors gehad inmiddels, toon de error dan maar en geeft het op
        Modal.Alert({
          title: `Error: ${result.e.statusCode || 0}`,
          description: JSON.stringify(result.e)
        });
      }

      if(errors<3 && result?.finished===undefined) {
        // Nog niet gefinished en minder dan 3 errors, retry
        setTimeout(() => {
          trackProgress(jobId, result.errors);
        }, 500);
      }
    })
  }

  if(isProcessing) {
    return (
      <div style={{marginTop: 30, flex: 1}}>
        <ProgressBar total={100} percentage={progress} />
      </div>
    )
  }else
  {
    return (
      <div className="container">
        <div className="contents">
          <Select
            value={sortMethod}
            onChange={e => setSortMethod(e.target.value)}
            style={{flex: 1}}
          >
            <option value="exifTime">{translationStore.translate("album.sort.order.chronologic")}</option>
            <option value="fileName">{translationStore.translate("album.sort.order.alphabetic")}</option>
            <option value="uploadOrder">{translationStore.translate("album.sort.order.upload")}</option>
          </Select>

          <div className="sortorder" onClick={toggleSortOrder}>
            <FontAwesomeIcon icon={faSortAlt} fixedWidth size={"lg"} rotation={sortOrder === "asc" ? 0 : 180} />
          </div>
        </div>

        <div className="enrich">
          <CheckBox
            selected={enrichEnabled}
            onChange={checked => {
              setEnrichEnabled(checked)
            }}
            style={{
              fontSize: 20,
              alignItems: "flex-start"
            }}
          >
            <div style={{marginTop: -3}}>{translationStore.translate("album.reset.enrich-title")}</div>
            <div
              style={{
                fontSize: '1.4rem',
                fontWeight: 'normal',
              }}
            >{translationStore.translate("album.reset.enrich-description")}</div>
          </CheckBox>
        </div>

        <div className="buttons">
          <Button
            className="large"
            disabled={isProcessing}
            onClick={props.onClose}
          >
            {translationStore.translate("base.cancel")}
          </Button>
          <Button
            disabled={isProcessing}
            className="filled default large"
            onClick={onSort}
          >
            {
              isProcessing ?
              <Loader foregroundColor={"rgba(var(--accentColorDarkContrastColor), 1)"} backgroundColor={"rgba(var(--accentColorDarkContrastColor), 0.6)"} /> :
              translationStore.translate("album.reset.title")
            }
          </Button>
        </div>
        <style jsx>
        {`
          .container {
            display: flex;
            flex: 1;
            flex-direction: column;
          }

          .container .contents {
            display: flex;
            margin-top: 40px;
          }

          .container .contents .sortorder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border: 1px solid rgba(var(--inputBorder), 1);
            border-radius: 5px;
            margin-top: 2px;
            margin-left: 5px;
            cursor: pointer;
          }

          .container .enrich {
            margin-top: 15px;
          }

          .container .buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            margin-top: 40px;
          }
        `}
        </style>
      </div>
    );
  }
}