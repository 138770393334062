import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { selectFiles } from "utils/upload";
import { Button } from "..";

export const uploadAcceptFileTypes = [
  "image/bmp",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "video/*",
];

const FileSelectButton = forwardRef(({children, className, enabled, style, ...props}, forwardedRef) =>
{
  const inputRef = useRef(null);
  const selectDispose = useRef(null);

  useImperativeHandle(forwardedRef, () => ({
    click: () => {
      inputRef.current.click();
    }
  }));

  useEffect(() => {
    return () => {
      if(selectDispose.current) {
        selectDispose.current();
      }
    }
  })

  if(props.accept.toLowerCase().includes('image/*'))
  {
    // image/* is niet toegestaan, geef een whitelist mee die de server accepteert
    // hierdoor zit gebruiker niet onnodig op upload te wachten die toch geweigerd gaat worden
    // met image/* krijg je b.v. ook problemen met iCloud photos (HEIC)
    // gebruik bijvoorbeeld image/jpeg en HEIC wordt automatisch op de Mac naar JPEG geconverteerd 
    console.error("image/* is not allowed, use a whitelist");
    return <div style={{backgroundColor: 'red', color: 'white', fontWeight: "bold", padding: 20, paddingTop: 10, paddingBottom: 10, marginTop: 10}}>image/* is not allowed, use a whitelist!</div>
  }

  return (
    <>
      <Button
        style={style}
        className={className + (enabled===false ? " disabled" : "")}
        enabled={enabled}
        onClick={() =>
        {
          selectDispose.current = selectFiles(
            (files) => props.onSelect(files), props);
        }}
      >
        {children}
      </Button>
    </>
  );
});

FileSelectButton.displayName = "FileSelectButton";
FileSelectButton.defaultProps = {
  enabled: true,
  multiple: false,
  accept: uploadAcceptFileTypes.join(","),
}

export default FileSelectButton;