import React from 'react';
import Blueprint from 'components/Blueprint/Blueprint';
import { toJS, transaction } from 'mobx';
import { observer } from 'mobx-react';
import extend from 'utils/extend';
import dnd from 'utils/DnD';
import AlbumContext from 'stores/album/context'
import { v4 as uuidv4 } from 'uuid';
import { calculateRotatedSize } from 'utils/math';

// Accepteert layer dit content type? Domme check eerst, later tekstvakken controleren
function layerAcceptsContentType(layer) {
  if(layer.data.meta.type!=='content' || layer.data.meta.floating || !layer.data.meta.editable)
    return false;

  return true;
}

const textDropAreaSize = 80;

const PageBlueprint = observer(class PageBlueprint extends React.PureComponent {
  static contextType = AlbumContext
  ref = React.createRef();

  componentDidMount() {
    this.props.page.getData();
  }

  componentDidUpdate() {
    this.props.page.getData();
  }

  get blueprint() {
    return this.ref.current ? this.ref.current.blueprint : null;
  }

  get getLayerByOffset() {
    if(this.ref.current)
      return this.ref.current.getLayerByOffset;
    return null;
  }

  get getLayerByMetaName() {
    if(this.ref.current)
      return this.ref.current.getLayerByMetaName;
    return null;
  }

  get highlightLayer() {
    if(this.ref.current)
      return this.ref.current.highlightLayer;
    return null;
  }

  // Geeft offset positie binnen deze page terug
  getOffsetPosition(blueprint, offset) {
    const svg = blueprint.artboard.el;
    let point = svg.createSVGPoint();
    point.x = offset.x;
    point.y = offset.y;
    const svgPoint = (point.matrixTransform(svg.getScreenCTM().inverse()));
    const positionWidth = this.pixelsToSvg(textDropAreaSize);

    // Text
    const crop = this.props.page.crop;
    let position = undefined;
    if(svgPoint.x<positionWidth)
      position = 'left';
    else if(svgPoint.x>(crop.width - positionWidth))
      position = 'right';
    else if(svgPoint.y<(positionWidth+crop.y))
      position = 'top';
    else if(svgPoint.y>(crop.height - positionWidth))
      position = 'bottom';

    return position;
  }

  pixelsToSvg(svgPoints) {
    const svg = this.blueprint.artboard.el;
    let point1 = svg.createSVGPoint();
    let matrix = svg.getScreenCTM();
    point1.x = 0;
    let position1 = point1.matrixTransform(matrix);
    point1.x = textDropAreaSize;
    let position2 = point1.matrixTransform(matrix);
    const ratio = (textDropAreaSize / (position2.x - position1.x));

    return svgPoints * ratio;
  }

  showDropLayer(position) {
    let color = false;
    let frame = undefined;
    if(position)
    {
      const size = this.pixelsToSvg(textDropAreaSize);
      color = 'rgba(0,0,0, 0.5)';
      frame = {
        x: this.props.page.crop.x,
        y: this.props.page.crop.y,
        width: 0,
        height: 0,
      }

      switch(position) {
        case 'left':
          frame.width = size;
          frame.height = this.props.page.crop.height;
        break;
        case 'top':
          frame.width = this.props.page.crop.width;
          frame.height = size;
        break;
        case 'right':
          frame.x = this.props.page.crop.width-size+this.props.page.crop.x;
          frame.width = size;
          frame.height = this.props.page.crop.height;
        break;
        case 'bottom':
          frame.y = this.props.page.crop.height-size+this.props.page.crop.y;
          frame.width = this.props.page.crop.width;
          frame.height = size;
        break;
      }
    }

    this.ref.current.highlightArea(color, frame);
  }

  addDnD(ref) {
    const dragTarget = dnd.addTarget('asset', ref, {
      data: {
        page: this.props.page,
        spread: this.props.spread,
      },
      isOver: (state, data) => {
        const result = {};
        const blueprint = this.blueprint;
        const sourcePage = data!==undefined && data.sourceData.page ? data.sourceData.page : undefined;
        const sourceSpread = data!==undefined && data.sourceData.spread ? data.sourceData.spread : undefined;
        const targetPage = this.props.page;
        const targetSpread = this.props.spread;
        const isDraggable = data!==undefined && (data.sourceData.layer && data.sourceData.layer.meta.draggable);
        const isResizable = data!==undefined && (data.sourceData.resize && data.sourceData.layer.meta.resizable);
        const isDifferentPage = (sourcePage!==targetPage);
        const canDrop = state && data.canDrop();
        const asset = data!==undefined && data.sourceData.asset!==undefined ? data.sourceData.asset : undefined;
        const isDifferentSpread = (sourcePage===undefined || sourceSpread!==targetSpread);
        const isFromLibrary = data && data.sourceData && data.sourceData.source && data.sourceData.source==='asset';

        if(data!==undefined)
        {
          result.action = data.action;
        }

        if(data?.sourceData?.ignoreHover)
        {
          return result;
        }

        if(canDrop)
        {
          if(sourcePage!==undefined)
          {
            // Asset vanuit andere spread
            result.action = isDifferentSpread ? 'copy': undefined;
          }

          if(isDifferentSpread)
          {
            result.action = 'copy';
          }

          if(isFromLibrary)
          {
            // Asset vanuit bibliotheek
            result.action = 'move';
          }

          if(asset) {
            if(asset.type==='text')
            {
              if(isDifferentPage || isDifferentSpread)
                result.action = 'copy';
            }
          }

          if(isResizable)
          {
            result.action = 'resize';
          }else if(isDraggable)
          {
            result.action = 'move';
            if(isDifferentPage)
              this.highlightLayer(blueprint.artboard);
          }else
          {
            switch(result.action)
            {
              case 'copy':
                // Bij een copy altijd het artboard highlighten
                this.highlightLayer(blueprint.artboard);
              break;
              case 'move':
              case undefined:
                let targetLayer = this.getLayerByOffset(data.offset);
                if(targetLayer)
                {
                  // We hebben een targetLayer, maar accepteert deze de inhoud wel? Anders doen we gewoon een copy
                  if(asset && !layerAcceptsContentType(targetLayer, asset.type))
                    targetLayer = undefined;
                }

                if(targetLayer===undefined)
                {
                  this.highlightLayer(blueprint.artboard);
                  result.action = 'copy';
                }else
                {
                  const isDifferentLayer = data.sourceData.layer!==targetLayer;
                  if((isDifferentLayer || isFromLibrary) && targetLayer!==undefined)
                    this.highlightLayer(targetLayer);
                  else
                    this.highlightLayer(false);
                }
              break;
            }
          }
        }else
        {
          this.highlightLayer(false);
          this.showDropLayer(false);
        }

        return result;
      },
      canDrop: (data) => {
        // Draggable layers worden op de huidige pagina alleen verplaatst
        const isDraggable = (data.sourceData.layer && data.sourceData.layer.meta.draggable);
        if(isDraggable)
        {
          if(this.props.spread.viewMode==='book-cover')
            return false;
          else
            return true;
        }

        const targetLayer = this.getLayerByOffset(data.offset);
        const targetSpread = this.props.spread;
        const targetPage = this.props.page;
        const sourceLayer = data.sourceData.layer;
        const sourcePage = data.sourceData.page;
        const isSpreadDrag = data.sourceData.isSpreadDrag;
        const asset = data.sourceData.asset!==undefined ? data.sourceData.asset : undefined;
        const isDifferentPage = (sourcePage!==targetPage);

        if(isSpreadDrag) {
          return false;
        }

        // Droppen op zichzelf kan niet natuurlijk
        if(targetLayer!==undefined && sourceLayer===targetLayer)
        {
          if(!asset || asset.type!=='text')
          {
            return false;
          }
        }

        if(targetSpread.viewMode==='book-cover') {
          // Teksten kan je niet op de cover gooien
          if(asset.type==='text')
            return false;
        }

        // Copy actie mag altijd op een blueprint
        if(data.action==='copy')
        {
          return true;
        }

        if(asset)
        {
          // Verslepen naar andere pagina mag
          if(isDifferentPage)
            return true;

          if(asset.type==='text' && !isDifferentPage)
          {
            // Teksten kan je niet verslepen op de eigen pagina
            return false;
          }
        }

        // Geen toevoeg actie, dus swap vanuit blueprint of assets library
        if(targetLayer && asset)
        {
          const sourceData = data.sourceData;
          if(sourceData.layer)
          {
            // Swappen, check of sourceLayer targetLayer asset wel wil accepteren
            const sourceLayer = sourceData.layer;
            const targetAsset = targetLayer.data.style && targetLayer.data.style.content && targetLayer.data.style.content.asset!==undefined ? targetLayer.data.style.content.asset : null;
            if(targetAsset && !layerAcceptsContentType(sourceLayer, targetAsset.type))
            {
              return false;
            }
          }

          // Check of target asset wil accepteren
          let dropType = sourceData.asset.type;
          if(layerAcceptsContentType(targetLayer, dropType))
          {
            return true;
          }
        }

        if(!targetLayer)
          return true;

        return false;
      },
      drop: (data) => {
        const isDraggable = (data.sourceData.layer && data.sourceData.layer.meta.draggable && !data.sourceData.resize);
        const isSamePage = (data.sourceData.page===this.props.page);

        let returnValue = {
          dropped: false
        };

        let dropType = 'layer';
        if(data.action==='copy')
          dropType = 'addToPage';
        else if(data.action==='resize')
          dropType = 'resize';

        if(isDraggable)
        {
          if(isSamePage)
            dropType = 'move';
          else
            dropType = 'addToPage';
        }

        switch(dropType)
        {
          case 'addToPage':
          case 'resize':
          case 'move':
            returnValue = {
              dropped: true,
              type: dropType,
              page: this.props.page,
              spread: this.props.spread,
              blueprint: this.blueprint,
            };
          break;
          case 'layer':
            const layer = this.getLayerByOffset(data.offset);
            if(layer)
            {
              returnValue = {
                dropped: true,
                type: dropType,
                layer: layer,
                page: this.props.page,
                spread: this.props.spread,
              };
            }
          break;
        }

        this.setState({isDragging: false});
        if(returnValue!==undefined)
          return returnValue;
      }
    });

    const dragSource = dnd.addSource('asset', ref, {
      canDrag: (data) => {
        if(!this.context.ui.editMode)
          return false;

        const layer = data.keyDown===18 ? undefined : this.getLayerByOffset(data.offset, this.blueprint);
        
        // We gaan uitzoeken of we een layer proberen te draggen die een asset bevat
        if(layer && layer.meta.editable)
        {
          return true;
        }

        // Geen layer, dus we hebben de spread zelf te pakken
        // spreads zijn draggable als het maar niet de cover is
        if(!layer && this.props.page.design.type!=='cover') {
          return true;
        }

        return false;
      },

      beginDrag: (data) => {
        let asset = null;
        const isMoveHint = this.context.edit.isContentMove;

        // bij een isMoveHint altijd de activeLayer gebruiken,
        // anders kan het zijn dat je per ongeluk bv een logo oppakt
        // terwijl je eigenlijk de foto eronder had moeten hebben
        const layer = data.keyDown===18 ? undefined : isMoveHint ? this.getLayerByMetaName(this.context.edit.activeLayer)?.[0] : this.getLayerByOffset(data.offset);
        const isSpreadDrag = data.keyDown===18 || !layer;
        const el = document.elementFromPoint(data.offset.x, data.offset.y);
        if(layer && layer.meta.editable)
        {
          asset = layer.data.style && layer.data.style.content && layer.data.style.content.asset!==undefined ? layer.data.style.content.asset : null;
        }

        const showDragLayer = (layer && layer.meta!==undefined && !layer.meta.draggable) || isSpreadDrag;
        const returnValue = {
          asset: isSpreadDrag ? undefined : asset,
          source: "blueprint",
          page: this.props.page,
          spread: this.props.spread,
          layer: isSpreadDrag ? undefined : layer,
          isSpreadDrag: isSpreadDrag,
          showDragLayer: showDragLayer,
          autoScroll: {
            top: 65,
            bottom: 195,
          },
        }

        if(layer && layer.meta.draggable) {
          const svg = layer.artboard.el;
          let point = svg.createSVGPoint();
          point.x = data.offset.x;
          point.y = data.offset.y;

          const svgPoint = (point.matrixTransform(svg.getScreenCTM().inverse()));
          const dragCorrection = {
            x: svgPoint.x - layer.x,
            y: svgPoint.y - layer.y,
          }

          returnValue.dragCorrection = dragCorrection;
        }

        const isResizeHint = el.hasAttribute('resize-hint');
        if(isResizeHint && !isSpreadDrag) {
          returnValue.resize = el.getAttribute('resize-hint');
          returnValue.frame = layer.data.frame;
        }

        if(isMoveHint) {
          const svg = layer.artboard.el;
          const point = svg.createSVGPoint();
          point.x = 0;
          point.y = 0;
          const pixelPoint = (point.matrixTransform(svg.getScreenCTM()));
          point.x = layer.data.frame.width;
          point.y = 0;
          const pixelPoint2 = (point.matrixTransform(svg.getScreenCTM()));
          const pixelFrame = {width: pixelPoint2.x - pixelPoint.x};
          pixelFrame.height = layer.data.frame.height / (layer.data.frame.width/pixelFrame.width);

          returnValue.move = {
            hint: 'content',
            contentPosition: layer.data.style?.content?.position || [50, 50],
            contentScale: layer.data.style?.content?.scale || 1,
            contentRotation: layer.data.style?.content?.rotation || 0,
            pixelFrame: pixelFrame,
          }

          returnValue.showDragLayer = false;
          returnValue.ignoreHover = true;
          returnValue.autoScroll = undefined;
        }

        return returnValue;
      },

      dragMove: (data) => {
        const returnValue = {};
        const layer = data.sourceData.layer;
        const page = data.sourceData.page;
        const isSpreadDrag = data.sourceData.isSpreadDrag;

        if(!layer || !page || isSpreadDrag)
          return;

        if(data.sourceData.resize)
        {
          // Resize layer
          const editHintsSize = 10;
          const frame = {...data.sourceData.frame};
          const maxX = frame.x + frame.width;
          const maxY = frame.y + frame.height;

          // Pixels naar svg points omzetten
          const svg = layer.artboard.el;
          let point = svg.createSVGPoint();

          // X waarden
          point.x = data.beginOffset.x;
          let beginX = (point.matrixTransform(svg.getScreenCTM().inverse())).x;
          point.x = data.offset.x;
          let currentX = (point.matrixTransform(svg.getScreenCTM().inverse())).x;

          // Y waarden
          point.y = data.beginOffset.y;
          let beginY = (point.matrixTransform(svg.getScreenCTM().inverse())).y;
          point.y = data.offset.y;
          let currentY = (point.matrixTransform(svg.getScreenCTM().inverse())).y;

          // Min frame garanderen
          // 3* resizeHint size
          point.x = data.beginOffset.x + (editHintsSize * 3);
          let minX = (point.matrixTransform(svg.getScreenCTM().inverse())).x;
          const minSize = minX - beginX;

          const pos = {
            x: currentX - beginX,
            y: currentY - beginY
          }
          const rotation = layer?.style?.rotation || 0;

          switch(data.sourceData.resize) {
            case 'centerright':
              switch(rotation) {
                case 0:
                default:
                  frame.width += pos.x;

                  if(frame.width<minSize)
                    frame.width = minSize;
                break;
                case 90:
                  frame.x += (pos.x / 2);
                  frame.y -= pos.x/2;
                  frame.height += pos.x;

                  if(frame.height<minSize)
                  {
                    const diff = minSize - frame.height;
                    frame.y -= diff/2;
                    frame.x += diff/2;

                    frame.height = minSize;
                  }
                break;
              }
            break;
            case 'centerleft':
              switch(rotation) {
                case 0:
                default:
                  frame.x += pos.x;
                  frame.width -= pos.x;

                  if(frame.width<minSize)
                  {
                    frame.x = maxX - minSize;
                    frame.width = minSize;
                  }
                break;
                case 90:
                  frame.y += pos.x/2;
                  frame.x += pos.x/2;
                  frame.height -= pos.x;

                  if(frame.height<minSize)
                  {
                    const diff = minSize - frame.height;
                    frame.y -= diff/2;
                    frame.x -= diff/2;
                    frame.height = minSize;
                  }
                break;
              }
            break;
            case 'topcenter':
              switch(rotation) {
                case 0:
                default:
                  frame.y += pos.y;
                  frame.height -= pos.y;

                  if(frame.height<minSize)
                  {
                    frame.y = maxY - minSize;
                    frame.height = minSize;
                  }
                break;
                case 90:
                  frame.y += pos.y/2;
                  frame.x += pos.y/2;
                  frame.width -= pos.y;

                  if(frame.width<minSize)
                  {
                    const diff = minSize - frame.width;
                    frame.y -= diff/2;
                    frame.x -= diff/2;
                    frame.width = minSize;
                  }
                break;
              }
            break;
            case 'bottomcenter':
              switch(rotation) {
                case 0:
                default:
                  frame.height += pos.y;

                  if(frame.height<minSize)
                    frame.height = minSize;
                break;
                case 90:
                  frame.y += pos.y/2;
                  frame.x -= pos.y/2;
                  frame.width += pos.y;

                  if(frame.width<minSize)
                  {
                    const diff = minSize - frame.width
                    frame.width = minSize;

                    frame.y += diff/2;
                    frame.x -= diff/2;
                  }
                break;
              }
            break;

            case 'topleft':
              switch(rotation) {
                case 0:
                default:
                  frame.x += pos.x;
                  frame.width -= pos.x;
                  frame.y += pos.y;
                  frame.height -= pos.y;

                  if(frame.height<minSize)
                  {
                    frame.y = maxY - minSize;
                    frame.height = minSize;
                  }

                  if(frame.width<minSize)
                  {
                    frame.x = maxX - minSize;
                    frame.width = minSize;
                  }
                break;
                case 90:
                  frame.x += pos.x/2 + pos.y/2;
                  frame.height -= pos.x;
                  frame.y += pos.y/2 + pos.x/2;
                  frame.width -= pos.y;

                  if(frame.width<minSize)
                  {
                    const diff = minSize - frame.width
                    frame.width = minSize;
                    frame.y -= diff/2;
                    frame.x -= diff/2;
                  }

                  if(frame.height<minSize)
                  {
                    const diff = minSize - frame.height
                    frame.height = minSize;
                    frame.y -= diff/2;
                    frame.x -= diff/2;
                  }
                break;
              }
            break;
            case 'bottomleft':
              switch(rotation) {
                case 0:
                default:
                  frame.x += pos.x;
                  frame.width -= pos.x;
                  frame.height += pos.y;

                  if(frame.height<minSize)
                  {
                    frame.height = minSize;
                  }

                  if(frame.width<minSize)
                  {
                    frame.x = maxX - minSize;
                    frame.width = minSize;
                  }
                break;
                case 90:
                  frame.y += pos.y/2 + pos.x/2;
                  frame.x -= pos.y/2 - pos.x/2;
                  frame.width += pos.y;
                  frame.height -= pos.x;

                  if(frame.width<minSize)
                  {
                    const diff = minSize - frame.width
                    frame.width = minSize;
                    frame.y += diff/2;
                    frame.x -= diff/2;
                  }

                  if(frame.height<minSize)
                  {
                    const diff = minSize - frame.height
                    frame.height = minSize;
                    frame.y -= diff/2;
                    frame.x -= diff/2;
                  }
                break;
              }
            break;

            case 'topright':
              switch(rotation) {
                case 0:
                default:
                  frame.width += pos.x;
                  if(frame.width<minSize)
                    frame.width = minSize;

                  frame.y += pos.y;
                  frame.height -= pos.y;
                  if(frame.height<minSize)
                  {
                    frame.y = maxY - minSize;
                    frame.height = minSize;
                  }
                break;
                case 90:
                  frame.y += pos.y/2 - pos.x/2;
                  frame.x += pos.y/2 + pos.x/2;
                  frame.width -= pos.y;
                  frame.height += pos.x;

                  if(frame.width<minSize)
                  {
                    const diff = minSize - frame.width
                    frame.width = minSize;
                    frame.y -= diff/2;
                    frame.x -= diff/2;
                  }

                  if(frame.height<minSize)
                  {
                    const diff = minSize - frame.height
                    frame.height = minSize;
                    frame.y -= diff/2;
                    frame.x += diff/2;
                  }
                break;
              }
            break;
            case 'bottomright':
              switch(rotation) {
                case 0:
                default:
                  frame.width += pos.x;
                  if(frame.width<minSize)
                    frame.width = minSize;

                  frame.height += pos.y;
                  if(frame.height<minSize)
                  {
                    frame.height = minSize;
                  }
                break;
                case 90:
                  frame.y += pos.y/2 - pos.x/2;
                  frame.x -= pos.y/2 - pos.x/2;

                  frame.width += pos.y;
                  frame.height += pos.x;

                  if(frame.width<minSize)
                  {
                    const diff = minSize - frame.width
                    frame.width = minSize;
                    frame.y += diff/2;
                    frame.x -= diff/2;
                  }

                  if(frame.height<minSize)
                  {
                    const diff = minSize - frame.height
                    frame.height = minSize;
                    frame.y -= diff/2;
                    frame.x += diff/2;
                  }
                break;
              }
            break;
          }

          // We hebben het gewenste frame, nu eventueel aanpassen aan gewenste ratio
          const newFrame = {...frame};
          if(layer.meta.resizable==='ratio')
          {
            const ratio = layer.data.frame.height/layer.data.frame.width;
            newFrame.height = frame.width * ratio;
            if(data.sourceData.resize.includes('left'))
            {
              newFrame.x -= newFrame.width - frame.width;
            }else if(data.sourceData.resize.includes('right'))
            {
              newFrame.x += newFrame.width - frame.width;
            }

            if(data.sourceData.resize.includes('top'))
            {
              newFrame.y -= newFrame.height - frame.height;
            }
          }

          transaction(() => {
            page.setLayerProperty({
              layer: layer.meta.name,
              path: ['frame'],
              value: newFrame,
              temp: true,
            });

            if(layer.data.path!==undefined)
            {
              page.setLayerProperty({
                layer: layer.meta.name,
                path: ['path'],
                value: "M0,0h"+newFrame.width+"v"+newFrame.height+"h-"+newFrame.width+"z",
                temp: true,
              });
            }
          });

          return;
        }

        // content fill binnen vak verplaatsen
        if(data.sourceData?.move?.hint==='content') {
          const movement = {
            x: data.beginOffset.x - data.offset.x,
            y:  data.beginOffset.y - data.offset.y,
          };

          // Layer frame in pixels
          const pixelFrame = data.sourceData.move.pixelFrame;
          const contentScale = data.sourceData.move.contentScale;
          const contentRotation = data.sourceData.move.contentRotation;

          // Afmetingen huidige image
          const oriAssetSize = data.sourceData.asset.sizes[data.sourceData.asset.sizes.length-1];
          const assetSize = calculateRotatedSize(oriAssetSize.width, oriAssetSize.height, contentRotation);

          // scale asset to fill pixelFrame
          const scale = Math.min(assetSize.width/pixelFrame.width, assetSize.height/pixelFrame.height) / contentScale;          
          const scaledAssetSize = {
            width: assetSize.width / scale,
            height: assetSize.height / scale
          }          

          // Bereken overgebleven ruimte
          const imageDiff = {
            width: Math.round(scaledAssetSize.width - pixelFrame.width),
            height: Math.round(scaledAssetSize.height - pixelFrame.height)
          }

          // Huidige en nieuwe pixelpositie berekenen
          // Daarna kan je percentage opnieuw berekenen
          const curPixelPosition = {
            x: (imageDiff.width/100) * data.sourceData.move.contentPosition[0],
            y: (imageDiff.height/100) * data.sourceData.move.contentPosition[1],
          }
          const newPixelPosition = {
            x: Math.max(0, Math.min(imageDiff.width, curPixelPosition.x + movement.x)),
            y: Math.max(0, Math.min(imageDiff.height, curPixelPosition.y + movement.y)),
          }

          // width en height moeten wel groter zijn dan 0 natuurlijk
          // afronden op 2 decimalen
          const position = [
            imageDiff.width>0 ? Math.round(((newPixelPosition.x / imageDiff.width) * 100) * 1e2)/1e2 : 50,
            imageDiff.height>0 ? Math.round(((newPixelPosition.y / imageDiff.height) * 100) * 1e2)/1e2 : 50,
          ];

          page.setLayerProperty({
            layer: layer.meta.name,
            path: ['style', 'content', 'position'],
            value: position,
            temp: true,
          });

          return;
        }

        if(layer.meta!==undefined && layer.meta.draggable)
        {
          // Layer is draggable
          const svg = layer.artboard.el;
          let point = svg.createSVGPoint();
          point.x = data.offset.x;
          point.y = data.offset.y;

          const svgPoint = (point.matrixTransform(svg.getScreenCTM().inverse()));
          const x = svgPoint.x - data.sourceData.dragCorrection.x;
          const y = svgPoint.y - data.sourceData.dragCorrection.y;

          const maxY = page.crop.height - layer.height + (layer.height - 50);
          const maxX = page.crop.width - layer.width  + (layer.width - 50);
          const minX = 0 - (layer.width-50);
          const minY = 0 - (layer.height-50);
          const frame = {
            x: Math.min(Math.max(minX, x), maxX),
            y: Math.min(Math.max(minY, y), maxY),
            width: layer.width,
            height: layer.height,
          };

          const showDragLayer = data.overData && data.overData.page!==undefined && data.overData.page!==page;
          returnValue.showDragLayer = showDragLayer;

          transaction(() => {
            page.setLayerProperty({
              layer: layer.meta.name,
              path: ['frame'],
              value: frame,
              temp: true,
            });
            page.setLayerProperty({
              layer: layer.meta.name,
              path: ['style', 'display'],
              value: showDragLayer!==false ? 'none' : null,
              temp: true,
            });
          });

          return returnValue;
        }
      },

      endDrag: (result) => {
        const sourceLayer = result.sourceData.layer;
        const sourcePage = result.sourceData.page;
        const sourceSpread = result.sourceData.spread;
        const isSpreadDrag = result.sourceData.isSpreadDrag;

        if(isSpreadDrag) {
          if(result.targetData?.afterSpread)
            sourceSpread.moveTo({after: result.targetData.afterSpread});
          else
            return;
        }
        
        if(!sourceSpread || !sourcePage || !sourceLayer)
          return;

        const sourceAsset = result.sourceData.asset ? result.sourceData.asset : null;
        const targetPage = result.targetData ? result.targetData.page : undefined;
        const targetSpread = result.targetData ? result.targetData.spread : undefined;
        const targetLayer = result.targetData ? result.targetData.layer : undefined;
        const isDraggable = (sourceLayer.meta!==undefined && sourceLayer.meta.draggable);
        const dropped = result.targetData && result.targetData.dropped;
        const isResizeAction = result.sourceData.resize!==undefined;
        const isContentPositionAction = result.sourceData?.move?.hint==='content';
        let dropType = result.targetData ? result.targetData.type : undefined;

        transaction(() => {
          if((result.didDrop && dropped) || isResizeAction || isContentPositionAction) {
            if(isResizeAction)
              dropType = 'resize';

            if(isContentPositionAction)
              dropType = 'contentPosition';

            switch(dropType) {
              case "resize":
                // resize layer
                // Frame definitief maken
                sourcePage.setLayerProperty({
                  layer: sourceLayer.meta.name,
                  path: ['frame'],
                  value: {...sourceLayer.data.frame}
                });

                sourcePage.setLayerProperty({
                  layer: sourceLayer.meta.name,
                  path: ['path'],
                  value: "M0,0h"+sourceLayer.data.frame.width+"v"+sourceLayer.data.frame.height+"h-"+sourceLayer.data.frame.width+"z"
                });
              break;
              case "contentPosition":
                // contentPosition change, maak definitief
                sourcePage.setLayerProperty({
                  layer: sourceLayer.meta.name,
                  path: ['style', 'content', 'position'],
                  value: [...sourceLayer.data.style.content.position]
                });
              break;
              case "move":
                // Move layer
                // Frame definitief maken
                sourcePage.setLayerProperty({
                  layer: sourceLayer.meta.name,
                  path: ['frame'],
                  value: {...sourceLayer.data.frame},
                });
              break;
              case "layer":
                // Wissel layer fills om
                // swap
                const targetContent = JSON.parse(JSON.stringify(targetLayer.data.style.content));
                const sourceContent = JSON.parse(JSON.stringify(sourceLayer.data.style.content));
                targetPage.setLayerProperty({
                  layer: targetLayer.meta.name,
                  path: ['style', 'content'],
                  value: sourceContent
                });

                sourcePage.setLayerProperty({
                  layer: sourceLayer.meta.name,
                  path: ['style', 'content'],
                  value: targetContent
                });

                this.context.edit.setActive({layer: undefined});
              break;
              case "spread-spacer":
                this.context.content.addSpread({
                  insertAfter: result.targetData.afterSpread,
                  add: [{
                    asset: toJS(sourceAsset),
                    layer: sourceLayer.data,
                    targetPage: 0,
                  }],
                });

                // Oude layer weggooien, maar dit nog niet naar server sturen, dit doen we later wel met een re-generate
                sourcePage.autoPushToServer = false;

                // Layer content leegmaken server fixt dit bij regenerate wel weer
                sourcePage.setLayerProperty({
                  layer: sourceLayer.meta.name,
                  path: ['style', 'content'],
                  value: null
                });

                sourcePage.autoPushToServer = true;

                // Regenerate spread waar deze layer ooit op stond
                sourceSpread.regenerate({
                  mode: 'rel',
                  changes: {
                    pages: [sourcePage.index]
                  }
                });

                this.context.edit.setActive({layer: undefined});
              break;
              case "addToPage":
                if(isDraggable) {
                  // Layer kopieren maar wel bepaalde data vervangen
                  const metaName = uuidv4();
                  const layer = JSON.parse(JSON.stringify(sourceLayer.data));
                  delete layer.style.display;
                  delete layer.style.editHints;
                  delete layer.style.resizeHints;
                  delete layer.style.highlight;
                  layer.id = uuidv4();
                  layer.meta.name = metaName;


                  // Frame bepalen
                  let svg = result.targetData.blueprint.artboard.el;
                  let point = svg.createSVGPoint();
                  point.x = result.offset.x;
                  point.y = result.offset.y;
                  const svgPoint = (point.matrixTransform(svg.getScreenCTM().inverse()));
                  const dragCorrection = {
                    x: sourceLayer.width/2,
                    y: sourceLayer.height/2,
                  }

                  const x = svgPoint.x - dragCorrection.x;
                  const y = svgPoint.y - dragCorrection.y;
                  const maxY = targetPage.crop.height - sourceLayer.height + (sourceLayer.height/2);
                  const maxX = targetPage.crop.width - sourceLayer.width  + (sourceLayer.width/2);
                  const minX = 0 - sourceLayer.width/2;
                  const minY = 0 - sourceLayer.height/2;
                  const frame = {
                    x: Math.min(Math.max(minX, x), maxX),
                    y: Math.min(Math.max(minY, y), maxY),
                    width: sourceLayer.width,
                    height: sourceLayer.height,
                  };
                  layer.frame = frame;


                  // Op vorige page layer verwijderen
                  sourcePage.removeLayer(sourceLayer.meta.name);

                  // Op target nieuwe layer aanmaken
                  targetPage.addExtensions('Artboard', [
                    layer
                  ]);

                  // ActivePage en activeLayer instellen
                  this.context.content.selection = [targetSpread.contentItem];
                  this.context.edit.setActive({
                    page: targetPage,
                    layer: metaName,
                  });
                }else
                {
                  // Op vorige page layer verwijderen
                  sourcePage.removeLayer(sourceLayer.meta.name, false);

                  // Als target en source op zelfde spread zitten dan 1 spread wijzigen en beide pagina's als gewijzigd markeren
                  let targetAndSourceInSameSpread = targetSpread === sourceSpread;
                  let changedPages = targetAndSourceInSameSpread ? [sourcePage.index, targetPage.index] : [targetPage.index];
                  targetSpread.regenerate({
                    mode: 'rel',
                    add: [{
                      asset: toJS(sourceAsset),
                      layer: sourceLayer.data,
                      targetPage: targetPage.index,
                    }],
                    changes: {
                      pages: changedPages
                    }
                  });

                  if(!targetAndSourceInSameSpread)
                  {
                    // Source zit op andere spread, dus die ook regeneraten
                    sourceSpread.regenerate({
                      mode: 'rel',
                      changes: {
                        pages: [sourcePage.index]
                      }
                    })

                    this.context.content.selection = [targetSpread.contentItem];
                  }

                  this.context.edit.setActive({
                    page: targetPage,
                    layer: undefined,
                  });
                }
              break;
              default:
              break;
            }
          }

          if(sourceLayer && isDraggable)
          {
            sourcePage.setLayerProperty({
              layer: sourceLayer.meta.name,
              path: ['style', 'display'],
              value: null,
              temp: true
            });

            sourcePage.setLayerProperty({
              layer: sourceLayer.meta.name,
              path: ['frame'],
              value: null,
              temp: true,
            });
          }
        });
      }
    });

    return function() {
      dragSource.destroy();
      dragTarget.destroy();
    }
  }

  render() {
    const page = this.props.page;
    const data = this.props.data;
    const editHints = this.props.editHints;

    let x = data.x;
    const blueprintStyle = {
      width: page.width,
      height: page.height,
      left: x + (page.marginLeft ? page.marginLeft : 0),
      position: 'absolute',
    }

    if(page.padding!==undefined)
    {
      for(let key in page.padding)
      {
        let styleKey = key.charAt(0).toUpperCase() + key.slice(1);
        blueprintStyle['padding'+styleKey] = page.padding[key];
      }
    }

    if(page.background!==undefined)
      blueprintStyle.backgroundColor = 'rgba('+page.background.join(',')+')';


    const isLoaded = (page.blueprint.data!==undefined && page.extensions!==undefined && page.layerData!==undefined && page.tempLayerData!==undefined);
    if(!isLoaded)
      return null;

    // EditHints handmatig aan layerData toevoegen
    const editHintsLayerData = {};
    const editableLayers = page.getLayersByMetaKey('editable', true).get();
    for(let layer of editableLayers) {
      editHintsLayerData[layer.meta.name] = {style: {
        editHints: editHints,
        resizeHints: false
      }};

      if(editHints && this.context.edit)
      {
        // We hebben een editor
        if(page===this.context.edit.activePage && layer.meta.name==this.context.edit.activeLayer)
        {
          const asset = (layer.style?.content?.asset);
          const contentMoveHints= !this.context.edit.isContentMove && (asset && asset.type==='photo') ? 'rgba(var(--accentColor), 1)' : undefined;

          // Huidige page & layer geselecteerd
          editHintsLayerData[layer.meta.name] = {style: {
            editHints: 'rgba(var(--accentColor), 1)',
            contentMoveHints: contentMoveHints,
            resizeHints: layer.meta.resizable ? {
              type: layer.meta.resizable,
              color: 'rgba(var(--accentColor), 1)'
            } : false,
          }};
        }
      }
    }

    const textViewLayerData = {};
    for(let layerName of page.zoomTextLayers) {
      textViewLayerData[layerName] = {
        style: {
          textZoom: 2
        }
      };
    }

    // Voeg viewMode toe aan data zodat bij wijzigen viewMode de blueprint opnieuw rendert
    // hiermee voorkomen we dat de 100% blueprint dezelfde photo formaten laadt als bv de 50%
    const blueprintData = toJS({
      ...page.blueprint.data,
      viewMode: this.props.spread.viewMode
    });
    const layerData = extend(true, toJS(page.layerData), toJS(page.tempLayerData), editHintsLayerData, textViewLayerData);
    const extensions = toJS(page.extensions);

    // Rechterpagina eventueel verplaatsen
    const right = page.getLayerByName('right').get();
    const rightFrame = right && page.moveRight ? {
      x: right.frame.x + page.moveRight.x,
      y: right.frame.y + page.moveRight.y
    } : undefined;

    return <Blueprint
      ref={this.ref}
      eventListener={this.props.eventListener}
      config={{
        fitPhotosToViewport: (this.props.spread.viewMode==='cover'),
        useFontFallback: true,
      }}

      style={blueprintStyle}
      data={blueprintData}
      extensions={extensions}
      layerData={layerData}
      viewBox={page.crop}
      rightFrame={rightFrame}

      onMount={(ref) => {
        const disposers = this.addDnD(ref);
        return disposers;
      }}
    />;
  }
});

export default PageBlueprint;