import { useEffect, useRef, useState } from "react";
import {commentsStyle} from './embedStyle';
import { useUser } from 'utils/user/lib';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle as deleteIcon } from "@fortawesome/pro-light-svg-icons/faMinusCircle";
import translationStore from "stores/translation-store";
import { Modal } from "components/UI";

function CommentsBar(props) {
  const [commentValue, setCommentValue] = useState("");
  const [comments, setComments] = useState([]);
  const user = useUser();

  useEffect(() => {
    const [promise] = user.FetchJSON("/legacyalbum/" + props.itemKey + "/itemdetails")
    promise.then(({data}) => {
      setComments(data.comments);
    })
    .catch(e => {
      console.error(e);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // itemType in legacy album opzoeken
    // item daarvan update zodat de comment counter geupdate kan worden na een props.onUpdate
    const itemType = props.album.items.getInstance(props.itemKey);
    itemType.item.Update({
      social: [0, comments.length],
    },
    {
      is_replacement: true
    });

    props.onUpdate();
  }, [comments.length]); // eslint-disable-line react-hooks/exhaustive-deps

  

  function onChange(e) {
    setCommentValue(e.target.value);
  }

  function onKeyDown(e) {
    e.stopPropagation();

    if(e.keyCode == 13) // ENTER key
    {
      if(!e.altKey && !e.shiftKey)
      {
        e.preventDefault();
        submitComment();
      }
    }
  }

  function deleteComment(commentKey) {
    Modal.Confirm({
      title: translationStore.translate("legacy.delete-comment-title"),
      okButtonText: translationStore.translate("base.delete"),
    })
    .then(() => {

      user.securePost("removecomment", `/comment/remove`, {
        'key': commentKey,
      })
      .then((data) =>
      {
        if(data.status)
          setComments(data.comments);      
      })
      .catch(()=>{});

    })
    .catch(() => {
    });    
  }
  
  function submitComment() {
    const value = commentValue;
    setCommentValue('');

    user.securePost("addcomment", `/comment/add`, {
      'itemkey': props.itemKey,
      'comment': value
    })
    .then((data) =>
    {
      setComments(data.comments);
    })
    .catch(()=>{});
  }

  function onKeyUp(e) {
    e.stopPropagation();

    if(e.keyCode==27)
    {
      setTimeout(function()
      {
        // close window workaround
        e.target.blur();
      }, 20);
    }
  }

  return (
    <div className="comments">
      { user.isLoggedIn && (
        <div className="new-comment">
          <textarea value={commentValue} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onChange={onChange} placeholder={translationStore.translate('legacy.write-comment-placeholder')} />
        </div>
      )}

      {
        comments.map(comment => <Comment key={comment.key} data={comment} onDelete={deleteComment} />)
      }

      <style jsx>{commentsStyle}</style>
    </div>
  );
}

function Comment(props) {
  const {data} = props;
  const avatar = useRef();

  // Linebreak verwijderen, css fixt dat newlines wel goed worden weergegeven
  const text = data.comment.replace(/<br\s?\/?>/g, "");

  useEffect(() => {
    window.myalbum.require("Avatars", function(Avatars)
    {
      Avatars.generate(avatar.current);
    });
  }, []);

  function onDelete() {
    props.onDelete(data.key);
  }

  const cssClasses = ["comment"];
  if(data.fromUser)
    cssClasses.push("deletable");

  return (
    <div className={cssClasses.join(" ")}>
      <div
        ref={avatar}
        className="avatar"
        data-avatar="true"
        data-url={data.avatar_url}
        data-color={data.avatar_color}
        data-text={data.avatar_initials}
      ></div>
      <div className="comment-content">
        <div className="comment-name">{data.name}</div>
        <div className="comment-date">{data.date}</div>
        <div className="comment-text">{text}</div>
      </div>
      <div className="comment-delete" onClick={onDelete}>
        <FontAwesomeIcon icon={deleteIcon} size="1x" color={"white"} />
      </div>
    </div>
  )
}

export default CommentsBar;