import {Button} from 'components/UI';
import { useEffect } from 'react';
import translationStore from "stores/translation-store";
import { Stack } from "../ModalV2";

type Props = {
  onCancel?: () => void,
  onConfirm?: () => void,

  confirmLabel?: string,
  cancelLabel?: string,

  title?: string,
  text?: string,

  destructive?: boolean,
}

function Confirm(props:Props) {

  const confirmLabel = props.confirmLabel ? props.confirmLabel : translationStore.translate("base.ok");
  const cancelLabel = props.cancelLabel ? props.cancelLabel : translationStore.translate("base.cancel");

  useEffect(() => {
    function keyDown(e) {
      if(e.keyCode===13)
      {
        props.onConfirm();
      }
    }

    window.addEventListener('keydown', keyDown);
    return () => {
      window.removeEventListener('keydown', keyDown);
    }
  }, [props.onConfirm]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack
      onClose={props.onCancel}
      className="small"
      style={{
        maxWidth: 550
      }}
    >
      <div className="content">
        <h1>{props.title}</h1>
        <h3>{props.text}</h3>

        <div
          style={{display: 'flex', flexDirection: 'row'}}
        >
          <Button
            className={"accent large" + (props.destructive ? " red" : "")}
            onClick={props.onConfirm}
          >
            {confirmLabel}
          </Button>

          <Button
            className="large"
            onClick={props.onCancel}
          >
            {cancelLabel}
          </Button>
        </div>

      </div>
    </Stack>
  )
}

Confirm.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},

  confirmLabel: undefined,
  cancelLabel: undefined,

  title: "Title",
  text: "Text",
}

export default Confirm;