import React from 'react';
import PageBlueprint from './PageBlueprint';
import Panorama from 'components/Panorama/Panorama';
import { observer } from 'mobx-react';
import {pagerowCss} from "./style";

const PageRow = observer(class PageRow extends React.PureComponent {

  static defaultProps = {
    onContextMenu: () => {},
    onMouseUp: () => {},
    onMouseDown: () => {},
    onClick: () => {},
    onDblClick: () => {},
  }

  constructor(props) {
    super(props);
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  renderPano(page, index) {
    const content = <Panorama
      key={"pano-"+index}
      imageSizes={page.pano.asset.sizes}
      width={page.width}
      height={page.height}
      onContextMenu={data => {
        data.page = page;
        data.layer = page.pano.layerName;
        this.props.onContextMenu(data);
      }}
      onMouseUp={(data) => {
        data.eventType = 'click';
        data.page = page;
        data.layer = page.pano.layerName;
        this.props.eventListener(data)
      }}
    />;

    return {content};
  }

  renderBlueprint(page, index, data) {
    const content = (
      <PageBlueprint
        key={"blueprint-"+index}
        data={data}
        page={page}
        spread={this.props.spread}

        styleSheet={this.props.styleSheet}
        editHints={this.props.editHints}
        textView={this.props.textView}

        eventListener={(data) => {
          data.page = page;
          this.props.eventListener(data);
        }}
      />
    );

    return {content, width: page.width};
  }

  render() {
    const style = {...this.props.style};
    let x = 0;

    return (
      <div
        style={style}
        className="page-row"
      >
        {
          this.props.pages.map((page, index) => {

            let result = undefined;
            switch(page.type) {
              case 'pano':
                result = this.renderPano(page, index, {
                  x: x
                });
              break;
              default:
                result = this.renderBlueprint(page, index, {
                  x: x
                });
              break;
            }

            if(result.width!==undefined)
              x += result.width;

            return result.content;
          })
        }

        <style jsx>{pagerowCss}</style>
      </div>
    )
  }
});

export default PageRow;