const defaultOptions = {
  method: "GET"
}

class AbortError extends Error { name = "AbortError" }

class XHRResponse
{
  constructor(request)
  {
    this.request = request;
  }

  get ok()
  {
    return this.request.status >= 200 && this.request.status < 300;
  }

  get status()
  {
    return this.request.status;
  }

  text()
  {
    return new Promise(resolve =>
    {
      resolve(this.request.response);
    })
  }

  json()
  {
    return new Promise((resolve, reject) =>
    {
      try
      {
        resolve(JSON.parse(this.request.response));
      }
      catch(e)
      {
        reject(e);
      }
    });
  }
}

/**
 * 
 * @param {String} url 
 * @param {Object} options 
 * @param {String} options.body
 * @param {Object} options.headers
 * @param {String} options.method
 * @param {String} options.mode
 * @param {AbortSignal} options.signal
 */
function XHRFetch(url, options)
{
  const _options = Object.assign({}, defaultOptions, options)

  return new Promise((resolve, reject) =>
  {
    const request = new XMLHttpRequest();
    request.open(_options.method, url);
    request.addEventListener("error", (e) =>
    {
      reject(Error("Network error", e));
    });

    request.addEventListener("load", () =>
    {
      resolve(new XHRResponse(request));
    });

    if(_options.mode)
    {
      if(_options.mode === "cors")
        request.withCredentials = true;
    }

    if(_options.headers)
    {
      Object.keys(_options.headers).forEach(key =>
      {
        request.setRequestHeader(key, _options.headers[key]);
      })
    }

    if(_options.signal)
    {
      _options.signal.addEventListener("abort", () =>
      {
        request.abort();
        reject(new AbortError("Aborted"));
      })
    }

    if(_options.body)
      request.send(_options.body);
    else
      request.send();
  })
}

let MyFetch = fetch;
if(typeof window !== 'undefined')
  MyFetch = window.fetch ? fetch : XHRFetch;
export default MyFetch;