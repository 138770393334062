const textSuggestions = [];
const styleSheets = {};

function getStyleSheet(name) {
  return new Promise(resolve => {
    if(name===undefined)
      name = 'default';

    if(styleSheets[name]===undefined)
    {
      console.warn("Unknown styleSheet '"+name+"'");
      name = 'default';
    }

    resolve(JSON.parse(JSON.stringify(styleSheets[name])));
  });
}

function createStyleSheet(config) {
  return {
    lineHeight: config.lineHeight,
    vAlign: 'bottom',
    textAlign: 'left',
    font: config.font,
    fontSize: config.fontSize,

    p: {
      marginTop: 20
    },
    ol: {
      marginTop: 20
    },
    ul: {
      marginTop: 20
    },
    li: {
      marginLeft: 30,
      marginTop: 0
    },
    h1: {
      font: config.title.font,
      fontSize: config.title.fontSize * 1,
      lineHeight: config.title.fontSize * 1 * 1.25,
    },
    h2: {
      font: config.title.font,
      fontSize: config.title.fontSize * 0.75,
      lineHeight: config.title.fontSize * 0.75 * 1.25,
    },
    h3: {
      font: config.title.font,
      fontSize: config.title.fontSize * 0.625,
      lineHeight: config.title.fontSize * 0.625 * 1.25,
    },
    h4: {
      font: config.title.font,
      fontSize: config.title.fontSize * 0.5,
      lineHeight: config.title.fontSize * 0.5 * 1.25
    },
    h5: {
      font: config.title.font,
      fontSize: config.title.fontSize * 0.437,
      lineHeight: config.title.fontSize * 0.437 * 1.25
    },
    h6: {
      font: config.title.font,
      fontSize: config.title.fontSize * 0.425,
      lineHeight: config.title.fontSize * 0.425 * 1.25,
      opacity: 0.5,
    },
    a: {
      textDecoration: "underline",
    },
    em: {
      font: config.em.font,
    }
  }
}

styleSheets['default'] = createStyleSheet({
  title: {
    font: {
      family: "Source Sans Pro",
      style: "normal",
      weight: 200
    },
    fontSize: 32
  },
  font: {
    family: "Roboto Slab",
    style: "normal",
    weight: 200
  },
  em: {
    font: {
      family: "Roboto",
      style: "italic",
      weight: 100
    }
  },
  fontSize: 18,
  lineHeight: 25,
});

styleSheets['SacramentoPiazzolla'] = createStyleSheet({
  title: {
    font: {
      family: "Sacramento",
      style: "normal",
      weight: 400
    },
    fontSize: 42,
  },
  font: {
    family: "Piazzolla",
    style: "normal",
    weight: 400
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Piazzolla",
      style: "italic",
      weight: 300
    }
  },
});
styleSheets['MontserratPiazzolla'] = createStyleSheet({
  title: {
    font: {
      family: "Montserrat",
      style: "normal",
      weight: 900
    },
    fontSize: 24,
  },
  font: {
    family: "Piazzolla",
    style: "normal",
    weight: 400
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Piazzolla",
      style: "italic",
      weight: 300
    }
  },
});
styleSheets['PiazzollaRoboto'] = createStyleSheet({
  title: {
    font: {
      family: "Piazzolla",
      style: "normal",
      weight: 600
    },
    fontSize: 30,
  },
  font: {
    family: "Roboto",
    style: "normal",
    weight: 100
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Roboto",
      style: "italic",
      weight: 100
    }
  }
});
styleSheets['SatisfyPiazzolla'] = createStyleSheet({
  title: {
    font: {
      family: "Satisfy",
      style: "normal",
      weight: 400
    },
    fontSize: 36,
  },
  font: {
    family: "Piazzolla",
    style: "normal",
    weight: 400
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Piazzolla",
      style: "italic",
      weight: 400
    }
  }
});
styleSheets['OpenSansCondensedPiazzolla'] = createStyleSheet({
  title: {
    font: {
      family: "Open Sans Condensed",
      style: "normal",
      weight: 700
    },
    fontSize: 36,
  },
  font: {
    family: "Piazzolla",
    style: "normal",
    weight: 400
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Piazzolla",
      style: "italic",
      weight: 400
    }
  }
});
styleSheets['GelasioRoboto'] = createStyleSheet({
  title: {
    font: {
      family: "Gelasio",
      style: "normal",
      weight: 400
    },
    fontSize: 30,
  },
  font: {
    family: "Roboto",
    style: "normal",
    weight: 100
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Roboto",
      style: "italic",
      weight: 100
    }
  }
});
styleSheets['ElsieRoboto'] = createStyleSheet({
  title: {
    font: {
      family: "Elsie",
      style: "normal",
      weight: 400
    },
    fontSize: 30,
  },
  font: {
    family: "Roboto",
    style: "normal",
    weight: 100
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Roboto",
      style: "italic",
      weight: 100
    }
  }
});
styleSheets['RobotoCondensedCabin'] = createStyleSheet({
  title: {
    font: {
      family: "Roboto Condensed",
      style: "normal",
      weight: 700
    },
    fontSize: 30,
  },
  font: {
    family: "Cabin",
    style: "normal",
    weight: 400
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Cabin",
      style: "italic",
      weight: 400
    }
  }
});
styleSheets['OpenSansCondensedCantarell'] = createStyleSheet({
  title: {
    font: {
      family: "Open Sans Condensed",
      style: "normal",
      weight: 700
    },
    fontSize: 30,
  },
  font: {
    family: "Cantarell",
    style: "normal",
    weight: 400
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Cantarell",
      style: "italic",
      weight: 400
    }
  }
});
styleSheets['PermanentMarkerOverpass'] = createStyleSheet({
  title: {
    font: {
      family: "Permanent Marker",
      style: "normal",
      weight: 400
    },
    fontSize: 27,
  },
  font: {
    family: "Overpass",
    style: "normal",
    weight: 400
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Overpass",
      style: "italic",
      weight: 400
    }
  }
});
styleSheets['OpenSansCondensedJosefinSans'] = createStyleSheet({
  title: {
    font: {
      family: "Open Sans Condensed",
      style: "normal",
      weight: 700
    },
    fontSize: 36,
  },
  font: {
    family: "Josefin Sans",
    style: "normal",
    weight: 300
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Josefin Sans",
      style: "italic",
      weight: 300
    }
  }
});
styleSheets['MontserratJosefinSans'] = createStyleSheet({
  title: {
    font: {
      family: "Montserrat",
      style: "normal",
      weight: 900
    },
    fontSize: 24,
  },
  font: {
    family: "Josefin Sans",
    style: "normal",
    weight: 300
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Josefin Sans",
      style: "italic",
      weight: 300
    }
  }
});
styleSheets['SatisfyGelasio'] = createStyleSheet({
  title: {
    font: {
      family: "Satisfy",
      style: "normal",
      weight: 400
    },
    fontSize: 36,
  },
  font: {
    family: "Gelasio",
    style: "normal",
    weight: 400
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Gelasio",
      style: "italic",
      weight: 400
    }
  }
});
styleSheets['LibreBaskervilleMontserrat'] = createStyleSheet({
  title: {
    font: {
      family: "Libre Baskerville",
      style: "normal",
      weight: 400
    },
    fontSize: 24,
  },
  font: {
    family: "Montserrat",
    style: "normal",
    weight: 300
  },
  fontSize: 18,
  lineHeight: 25,
  em: {
    font: {
      family: "Montserrat",
      style: "italic",
      weight: 300
    }
  }
});


textSuggestions.push({
  name: 'base.default',
  styleSheet: 'default'
});
textSuggestions.push({
  name: 'Sacramento Piazzolla',
  styleSheet: 'SacramentoPiazzolla'
});
textSuggestions.push({
  name: 'Montserrat Piazzolla',
  styleSheet: 'MontserratPiazzolla'
});
textSuggestions.push({
  name: 'Piazzolla Roboto',
  styleSheet: 'PiazzollaRoboto'
});
textSuggestions.push({
  name: 'Satisfy Piazzolla',
  styleSheet: 'SatisfyPiazzolla'
});
textSuggestions.push({
  name: 'OpenSansCondensed Piazzolla',
  styleSheet: 'OpenSansCondensedPiazzolla'
});
textSuggestions.push({
  name: 'Gelasio Roboto',
  styleSheet: 'GelasioRoboto'
});
textSuggestions.push({
  name: 'Elsie Roboto',
  styleSheet: 'ElsieRoboto'
});
textSuggestions.push({
  name: 'RobotoCondensed Cabin',
  styleSheet: 'RobotoCondensedCabin'
});
textSuggestions.push({
  name: 'OpenSansCondensed Cantarell',
  styleSheet: 'OpenSansCondensedCantarell'
});
textSuggestions.push({
  name: 'PermanentMarker Overpass',
  styleSheet: 'PermanentMarkerOverpass'
});
textSuggestions.push({
  name: 'OpenSansCondensed JosefinSans',
  styleSheet: 'OpenSansCondensedJosefinSans'
});
textSuggestions.push({
  name: 'Montserrat JosefinSans',
  styleSheet: 'MontserratJosefinSans'
});
textSuggestions.push({
  name: 'Satisfy Gelasio',
  styleSheet: 'SatisfyGelasio'
});
textSuggestions.push({
  name: 'LibreBaskerville Montserrat',
  styleSheet: 'LibreBaskervilleMontserrat'
});

export {styleSheets, textSuggestions, getStyleSheet};