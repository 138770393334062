import { useRef, useEffect, useState } from 'react';
import translationStore from 'stores/translation-store';
import dnd from 'utils/DnD';

function SpreadSpacer(props) {
  const ref = useRef();
  const target = useRef();
  const [showContent, setShowContent] = useState(false);

  function addTarget(ref) {
    return dnd.addTarget('asset', ref.current, {
      isOver: (state, data) => {
        const canDrop = state && data && Object.keys(data.sourceData).length && data.canDrop();
        const isSpreadDrag = data?.sourceData?.isSpreadDrag;
        const returnValue = {};
        if(state && canDrop)
          returnValue.action = 'copy';

        if(isSpreadDrag)
          returnValue.action = 'move';

        setShowContent(state && canDrop && data.sourceData.resize===undefined);
        return returnValue;
      },
      canDrop: (data) => {
        const isSpreadDrag = data?.sourceData?.isSpreadDrag;
        if(isSpreadDrag)
          return true;

        const draggable = data.sourceData.sourceLayer && data.sourceData.sourceLayer.meta.draggable;
        if(draggable)
          return false;

        const isContentMoveAction = data.sourceData?.move?.hint;
        if(isContentMoveAction)
          return false;

        return true;
      },
      drop: () => {
        return {
          dropped: true,
          type: 'spread-spacer',
          afterSpread: props.spread,
        };
      }
    });
  }

  useEffect(() => {
    if(ref.current)
      target.current = addTarget(ref);
    else if(target.current)
      target.current.destroy();
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  if(props.height===0)
    return null;

  return(
    <div
      ref={ref}
      className={("spread-spacer" + (showContent ? " isOver" : ""))}
      style={{height: props.height, width: '100%'}}
    >

      {showContent && <div className="label">{translationStore.translate("context.edit.add-page")}</div>}

      <style jsx>
      {`
        .spread-spacer {
          overflow: hidden;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          user-select: none;
        }

        .spread-spacer.isOver:after {
          content: '';
          height: 2px;
          width: inherit;
          max-width: 50%;
          display: block;
          background-color: rgba(65, 65, 65, 1);
          margin-right: 60px;
        }

        .spread-spacer .label {
          position: relative;
          border: 1px solid rgba(65, 65, 65, 1);
          background-color: rgba(65, 65, 65, 1);
          height: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          white-space: nowrap;
          box-sizing: border-box;
          border-right: 0px;
          margin-left: 60px;
          padding-left: 10px;
          padding-right: 5px;
          color: white;
          font-size: 10px;
          text-transform: uppercase;
        }

        .spread-spacer .label:before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 12px solid rgba(65, 65, 65, 1);
          position: absolute;
          right: -12px;
          top: -1px;
        }
      `}
      </style>
    </div>
  )
}

export default SpreadSpacer;