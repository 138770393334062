import { Component } from 'react';
import { observer } from 'mobx-react';
import { colorArrayToString, getDataProps } from '../utils';
import ThemeStore from '../store';
import style from './style';

class ToggleButton extends Component
{
  static defaultProps = {
    enabled: true,
    checked: false,
    lightColor: null,
    mediumColor: null,
    darkColor: null,
    backgroundColor: null,
  }

  get lightColor()
  {
    return this.props.lightColor || ThemeStore.theme.toggleBg;
  }

  get mediumColor()
  {
    return this.props.mediumColor || ThemeStore.theme.toggleLine;
  }

  get darkColor()
  {
    return this.props.darkColor || ThemeStore.theme.toggleActive;
  }

  get backgroundColor()
  {
    return this.props.backgroundColor || ThemeStore.theme.toggleKnob;
  }

  get toggleStyle()
  {
    if(this.props.checked)
    {
      return {
        backgroundColor: colorArrayToString(this.darkColor),
        borderColor: colorArrayToString(this.darkColor)
      };
    }
    else
    {
      return {
        backgroundColor: colorArrayToString(this.lightColor),
        borderColor: colorArrayToString(this.mediumColor)
      };
    }
  }

  get circleStyle()
  {
    if(this.props.checked)
    {
      return {
        backgroundColor: colorArrayToString(this.backgroundColor),
        borderColor: colorArrayToString(this.darkColor)
      };
    }
    else
    {
      return {
        backgroundColor: colorArrayToString(this.backgroundColor),
        borderColor: 'rgba(196, 174, 214, 1)'
      };
    }
  }

  toggle = () => {
    const value = !this.props.checked;
    if(this.props.onChange)
      this.props.onChange(value);
  }

  componentDidUpdate(prevProps)
  {
    if(prevProps.checked!==this.props.checked)
      this.setState({checked: this.props.checked});
  }

  render() {
    const dataProps = getDataProps(this.props);
    const classList = [this.props.className];
    classList.push("toggle-button");
    if(this.props.checked)
      classList.push("checked");
    if(!this.props.enabled)
      classList.push("disabled");

    return (
      <div style={{...this.colors, ...this.props.style}} {...dataProps} className={classList.join(" ")} onClick={() => {this.toggle();}}>
        <div className="toggle" style={this.toggleStyle} data-tip-anchor>
          <div className="circle" style={this.circleStyle} />

          {this.props.isProcessing && (<div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>)}
        </div>

        {this.props.label && <div className="label">{this.props.label}</div>}

        <style jsx>{style}</style>
      </div>
    );
  }
}

export default observer(ToggleButton);