import { useEffect, useRef } from "react"
import isDev from "utils/isDev";

type Params = {
  url: string,
  options?: {
    async?: boolean,
    defer?: boolean,
    crossOrigin?: string,
    referrerPolicy?: string
  }
}

export default function useScript(callback: () => void, {url, options={async: true, defer: true, crossOrigin: null, referrerPolicy: null}}: Params) {
  const destroyFunction = useRef(null);
  const instance = useRef({
    current: 0,
    lastRender: Date.now()
  });

  useEffect(() => {
    let isMounted = true;
    const script = document.createElement("script");
    script.async = options.async;
    script.defer = options.defer;

    if(options.crossOrigin)
      script.crossOrigin = options.crossOrigin;
    if(options.referrerPolicy)
      script.referrerPolicy = options.referrerPolicy;
    
    script.setAttribute("charset", "utf-8")
    script.type = "text/javascript";

    script.onload = () => {
      if(isMounted) {
        destroyFunction.current = callback();
      }
    };

    script.src = url;
    document.body.appendChild(script);

    return () => {
      isMounted = false;
      document.body.removeChild(script);

      if(destroyFunction.current)
        destroyFunction.current();
    }
  }, [callback, options.async, options.crossOrigin, options.defer, options.referrerPolicy, url]);

  useEffect(() => {
    if(isDev) {
      if(instance.current.current > 1) {
        const now = Date.now();
        const diff = now - instance.current.lastRender;
        if(diff < 100) {
          console.warn(`Re-rendering useScript hook too much, did you forgot to memoize the callback?`);
        }
      }
      
      instance.current.current++;
      instance.current.lastRender = Date.now();
    }
  }, [callback]);
}