import { whenAuthenticated } from 'components/Authentication';
import { Modal } from 'components/UI';
import { uploadAcceptFileTypes } from 'components/UI/FileSelectButton/FileSelectButton';
import translationStore from 'stores/translation-store';
import XHRFetch from 'utils/XHRFetch';
import { selectFiles } from './upload';

export function fetchAlbumWithUser(user, album)
{
  return new Promise((resolve, reject) =>
  {
    const fetchAlbum = XHRFetch(`${process.env.LEGACYAPI_URL}/album/${album}?noitems=1`, {
      headers: {
        MAAUTHORIZATION: user && user?.token?.token ? `Bearer ${user.token.token}` : `ClientToken V5BkhO7GlUH#CjgXcxC74frqlK_vy8cp`,
      },
    });

    fetchAlbum.then(result => {
      result.text()
      .then(text => {
        const json = JSON.parse(text);
        const data = {
          data: json.album,
          settings: json.settings,
        }

        if(user)
          data.user = json.user;

        resolve(data);
      })
    })
    .catch(e => {      
      if(e.statusCode===404)
        return reject(e);
      
      resolve({
        data: {
          key: album
        },
        statusCode: e.statusCode,
      });
    });
  })
}

export function onContributeLegacy(upload, user, album) {  
  return new Promise((uploadResolve) => {
    const disposeFileSelect = selectFiles((files) => {
      upload.setUrl(`${process.env.SITE_URL}/upload`);
      upload.setUser(user);
      upload.clear();
      upload.addFiles(files);
  
      new Promise((resolve, reject) => {
        if(!user.isLoggedIn) {
          whenAuthenticated(user, {
            message: translationStore.translate("album.contribute.login"),
          }).then(resolve).catch(e =>
          {
            if(e.error === "popup_closed_by_user")
              reject();
          });
        } else {
          resolve();
        }
      })
      .then(() => {
        const progressBar = Modal.Progress({
          modalStyle: {
            width: "100vw",
            maxWidth: 500,
          },
          onCancel: () => new Promise(resolve => {
            upload.pause();
            Modal.Confirm({
              title: translationStore.translate("myalbums.add.cancel.title"),
              description: translationStore.translate("myalbums.add.cancel.description"),
              okButtonText: translationStore.translate("myalbums.add.cancel.button-ok"),
              cancelButtonText: translationStore.translate("myalbums.add.cancel.button-cancel"),
            })
            .then(() => {
              upload.clear();
              resolve();
            })
            .catch(() => {
              upload.resume();
            })
          })
        });
  
        upload.onInitUpload(() => {
          return new Promise((resolve, reject) => {
            const initUrl = new URL(`${process.env.SITE_URL}/upload/init`);
            initUrl.searchParams.append("albumkey", album.key);
            initUrl.searchParams.append("isaddrequest", 1);
    
            const [promise] = user.FetchJSON(initUrl.toString());
            promise.then(response =>
            {
              upload.addParam("uploadkey", response.data.uploadKey);
              resolve();
            })
            .catch(reject);
          });
        });
  
        upload.onProgress(() => {
          progressBar.setPercentage(upload.progress.percentage);
          progressBar.setTitle(translationStore.translate("myalbums.add.progress.in-progress", {count: upload.files.size}));
        });
    
        upload.onStatus(() => {
          function checkStatus(uploadKey, resolve, reject) {
            if(!upload.isActive) {
              return;
            }

            const [promise] = user.FetchJSON(`/api/v1/member/upload/${uploadKey}/status/`, {legacy: true});
            promise.then((res) => {
              const result = res.data;
              if(result.status) {
                switch(result.status) {
                  case 'processing':
                    checkStatusDelayed(uploadKey, resolve, reject);
                  break;
                  case 'complete':
                    const [completePromise] = user.FetchJSON(result.action, {
                      legacy: true,
                      method: result.actionMethod === "GET" ? "GET" : "POST",
                    });
                    completePromise.then(response =>
                    {
                      resolve(response.data);
                    })
                    .catch(reject);
                  break;
                }
              } else {
                checkStatusDelayed(uploadKey, resolve, reject);
              }
            })
            .catch(reject);
          }

          function checkStatusDelayed(uploadKey, resolve, reject) {
            setTimeout(() => {
              checkStatus(uploadKey, resolve, reject);
            }, 1000);
          }

          function awaitUploadStatus(uploadKey)
          {
            return new Promise((resolve, reject) => {
              checkStatus(uploadKey, resolve, reject);
            });
          }

          return new Promise((resolve, reject) => {
            const uploadkey = upload.getParam("uploadkey");
            awaitUploadStatus(uploadkey)
            .then((result) => {
              upload.setData("album", result.albumKey);
              upload.setData("errors", {
                corrupt: result.corrupt,
                durationTooLong: result.tooLong,
                resolutionTooLarge: result.resolutionExceeded,
                filesizeTooLarge: result.tooLarge,
                resolutionTooSmall: result.tooSmall,
              });
              upload.setData("filesCount", {
                successCount: parseInt(result.nrAccepted, 10) - parseInt(result.nrFailed, 10),
                acceptedCount: parseInt(result.nrAccepted, 10),
                failedCount: parseInt(result.nrFailed, 10),
              });
              resolve();
            }).catch(reject);
          });
        });

        upload.onComplete(() => {
          return new Promise(resolve => {
            progressBar.setIndeterminate(true);
            progressBar.setTitle(translationStore.translate("myalbums.add.progress.processing", {count: upload.files.size}));

            resolve();
          });
        });
  
        upload.start()
        .then(() => {
          disposeFileSelect();
          uploadResolve();
          progressBar.close();
        })
        .catch(e => {
          upload.cancel();
          upload.clear();

          progressBar.close();
          disposeFileSelect();

          if(e) {
            const errorProps = new Map();
            if (e.statusCode) { 
              errorProps.set("status", e.statusCode);
            }

            if(e.msg && e.msg.length) {
              errorProps.set("message", e.msg);
            }

            if(e.response) {
              Object.entries(e.response).forEach(([key, value]) => {
                errorProps.set(key, value);
              });
            }

            console.log("DEBUG INFO");
            console.log(upload.debug);
            console.log("DEBUG INFO");

            Modal.Alert({
              title: translationStore.translate("base.error"),
              description: `Upload error: ${Array.from(errorProps).map(([key, value]) => `${[key, value].filter(x=>x !== undefined).join(": ")}`).join(", ")}`
            })
          }
        });
      })
      .catch(() => {
        upload.clear();
        disposeFileSelect();
      })
    }, {
      multiple: true,
      accept: uploadAcceptFileTypes.join(", ")
    });
  });
}