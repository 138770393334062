import PhotoShare from "components/PhotoShare/PhotoShare";
import React from "react";
import SocialShare from "components/SocialShare/SocialShare";

export default function PhotoShareBar({item, domains}) {
  const { sizes } = item;
  const [large, medium, small] = sizes.sort((a, b) => {
    const [, widthA, heightA] = a;
    const [, widthB, heightB] = b;
    
    return (widthB * heightB) - (widthA * heightA);
  });

  const urls = [large, medium, small].filter(x => {
    if(Array.isArray(x)) {
      return x[0] !== "error"
    }

    return x;
  }).map(size => `${domains[size[4]]}${size[3]}`);

  if (urls.length === 0) {
    return null;
  }

  const isPhoto = item.type===1;

  return (
    <div
      style={{
        padding: 30,
      }}
    >
      <SocialShare
        url={urls[0]}
        showLink={false}
        size={49}
        darkMode={true}
      />
      <div style={{height: 40}} />
      {isPhoto && <PhotoShare urls={urls}/> }
    </div>
  );
}