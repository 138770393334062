import { useContext, memo, useRef, useEffect, useState, useCallback } from 'react';
import { autorun, reaction, runInAction } from 'mobx';
import { observer, Observer } from 'mobx-react'
import LazyList from '@myalbum/lazylist';
import { ToggleButtonDark, Menu, Floater } from 'components/UI';
import AlbumContext from '../../stores/album/context'
import albumStyle, {lazyListStyling} from './style';
import translationStore from '../../stores/translation-store';
import isDev from "utils/isDev";
import { throttle, debounce } from 'throttle-debounce';
import SlideView from 'components/SlideView';
import AssetShareModal from './AssetShareModal';
import DownloadUpsellModal from 'components/DownloadUpsellModal';
import removeMarkdown from "remove-markdown";
import { Confirm } from 'components/UI/ModalV2/ModalV2';
import AppInstallBar from 'components/AppInstallBar/AppInstallBar';
const isServerSide = typeof window === 'undefined';

function Album(props) {
  const store = useContext(AlbumContext);
  const [showZoomButton, setShowZoomButton] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    isOpen: false,
    layer: undefined,
    page: undefined,
    event: undefined,
    contentItem: undefined,
    spread: undefined
  });

  const lazylist = useRef(null);
  const disposers = useRef([]);
  const menuBarLockTimer = useRef(null);
  const autorunReaction = useRef(null);
  const scrollToIndex = useRef(null);
  
  // Zodra er een render heeft plaatsgevonden in de layzlist wordt dit getriggerd
  // Door de debounce kunnen we wachten totdat echt alle render acties klaar zijn
  const afterRenderCallback = debounce(24, () =>
  {
    if(lazylist.current && scrollToIndex.current!==null)
    {
      // dit hoeft maar 1x uitgevoerd te worden
      // als dit weer uitgevoerd moet worden moet scrollToIndex.current niet meer null zijn
      lazylist.current.autoScrollToIndex(Math.max(0, scrollToIndex.current));
      scrollToIndex.current = null;

      runInAction(() => {
        // Deze value zorgt er b.v. voor dat de editbar in beeld is
        store.content.selectionIsInView = true;
      });
    }
  }, {atBegin: true});

  useEffect(() => {
    store.content.indexInView = 0;
    store.content.on("scrollSelectionIntoView", scrollSelectionIntoView);
    store.content.on("scrollIndexIntoView", scrollIndexIntoView);
    store.content.on("scrollToTop", scrollToTop);
    store.content.on("contextmenu", showContextMenu);
    store.content.on("longtouch", longtouch);
    store.content.on("createBlankPage", createBlankPage);

    window.addEventListener('keydown', keyDown, {passive: true});
    window.addEventListener('mousedown', mouseDown, {passive: true});

    if(isDev && !isServerSide)
      window.lazylist = lazylist;

    disposers.current.push(
      reaction(
        () => store.ui.editMode,
        (editMode) => {
          // EditMode wijzigt, graag naar oude positie scrollen
          if(lazylist.current)
          {
            const index = lazylist.current.index;

            // zodra renderen klaar is wordt deze value uitgelezen
            // er wordt automatisch heen gescrollt
            scrollToIndex.current = index;

            if(editMode) {
              const item = store.content.items[index];
              if(item)
                store.content.selection = [item];
            }else
              store.content.deSelectAll();

            // scrollen nu ook vast naar de (mogelijke) juiste positie
            lazylist.current.autoScrollToIndex(Math.max(0, index));
          }
        }
      )
    );

    setShowZoomButton(true);

    const currentDisposers = disposers.current;
    return function() {
      for(let disposer of currentDisposers)
        disposer();

      if(autorunReaction.current)
        autorunReaction.current.dispose();

      store.content.off("scrollSelectionIntoView", scrollSelectionIntoView);
      store.content.off("scrollIndexIntoView", scrollIndexIntoView);
      store.content.off("scrollToTop", scrollToTop);
      store.content.off("contextmenu", showContextMenu);
      store.content.off("longtouch", longtouch);
      store.content.off("createBlankPage", createBlankPage);

      window.removeEventListener('keydown', keyDown, {passive: true});
      window.removeEventListener('mousedown', mouseDown, {passive: true});

      if(isDev)
        delete window.lazylist;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const longtouch = useCallback((e) => {
    if(store.ui.editMode)
      return;

    if(store.ui.menuBarEnabled)
    {
      setContextMenu({
        isOpen: true,
        event: e.event,
        layer: e.layer,
        page: e.page,
        contentItem: e.contentItem,
        spread: e.spread,
      })
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onContextMenuClose = useCallback(() => {
    setContextMenu({...contextMenu, isOpen: false});
  }, [contextMenu]);


  const mouseDown = useCallback((e) => {
    if(!store.ui.editMode)
      return;

    if(e.target && e.target.classList.contains('spread-spacer'))
      store.content.deSelectAll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const keyDown = useCallback((e) => {
    let item, index;
    let currentIndex = lazylist.current.index;

    switch(e.keyCode)
    {
      case 27:
        if(e.target===document.body)
        {
          store.content.deSelectAll();
        }
      break;
      case 33:
        // page up
        e.preventDefault();
        index = currentIndex-1;
        item = index>-1 ? store.content.items[index] : null;
        if(item)
        {
          if(store.ui.editMode)
            store.content.selection = [item];

          lazylist.current.scrollToIndex(index);
        }
      break;
      case 34:
        // page down
        e.preventDefault();
        index = currentIndex+1;
        item = store.content.items.length-1>index ? store.content.items[index] : null;
        if(item)
        {
          if(store.ui.editMode)
            store.content.selection = [item];

          lazylist.current.scrollToIndex(index);
        }
      break;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderItem = useCallback((item) => {
    return item.content;
  }, []);

  const renderFooter = useCallback(() => {
    let bottomSpacing = 0;
    if(store.ui.editMode)
      bottomSpacing += 230;

    return (<div style={{height: bottomSpacing}} />);
  }, [store.ui.editMode]);

  const getItemLayout = useCallback((item) => {
    if(!lazylist.current)
      return;

    item.touchLayout();
    return item.size;
  }, []);

  const onResize = useCallback((dimensions) => {
    store.ui.setDimensions(dimensions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onScrollThrottle = useRef(throttle(100, () => {onScroll()})).current;
  const onScroll = useCallback(() => {
    if(scrollToIndex.current!==null)
    {
      // We zijn bezig met een autoscroll omdat we de editmode net aan hebben getoggled
      return;
    }

    if(lazylist.current)
    {
      store.content.indexInView = lazylist.current.index;

      // In editmode bijhouden of huidige selectie nog inView is
      if(store.ui.editMode && store.content.selection.length>0)
      {
        runInAction(() => {
          const indexesInViewport = lazylist.current.indexesInViewport;
          const isInView = indexesInViewport.includes(store.content.selection[0].index);
          store.content.selectionIsInView = isInView;
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getViewModes() {
    let viewModes = ["book", "fill"];
    if(store.ui.isSmallScreen)
      return ["book", "smallscreen"];

    return viewModes;
  }

  const getViewMode = useCallback((zoomIn) => {
    let viewMode = store.ui.viewMode;
    let viewModes = getViewModes();

    let index = viewModes.indexOf(viewMode);
    if(zoomIn)
    {
      if(index<viewModes.length-1)
        index++;
    }else
    {
      if(index>0)
        index--;
    }

    return viewModes[index];
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function createBlankPage() {
    const index = lazylist.current.index > 0 ? lazylist.current.index - 1 : 0;
    const key = store.content.items[index].key;

    store.content.addSpread({
      insertAfter: key
    })
    .then(spread => {
      store.content.selection = [spread];
    })
    .catch(() => {})
  }

  const zoom = useCallback((zoomIn) => {
    store.ui.lockMenuBar = true;

    // Eerst volgende update scrollen naar deze index (hierdoor blijft huidige spread in beeld)
    lazylist.current.autoScrollToIndex(Math.max(0, lazylist.current.index));
    runInAction(() => {
      store.ui.viewMode = getViewMode(zoomIn);
    });

    if(menuBarLockTimer.current)
      clearTimeout(menuBarLockTimer.current);

    menuBarLockTimer.current = setTimeout(() => {
      // Hebben menubar tijdelijk gelockt, anders schuift die steeds tijdens zoomen
      store.ui.lockMenuBar = false;
    }, 300);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const zoomOut = useCallback(() => {
    zoom(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const zoomIn = useCallback(() => {
    zoom(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollSelectionIntoView = useCallback(() => {
    if(lazylist.current)
      lazylist.current.scrollIntoView(store.content.selection[0].index, {
        before: 65 + 200, // blijf zover uit de top vandaan, menubar en extra space
        after: store.ui.editBarHeight + 200, // blijf zover van de onderkant vandaan, toolbar en extra space
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollIndexIntoView = useCallback((e) => {
    if(lazylist.current)
    {
      lazylist.current.scrollIntoView(e.index, {
        before: 65 + 200, // blijf zover uit de top vandaan, menubar en extra space
        after: store.ui.editBarHeight + 200, // blijf zover van de onderkant vandaan, toolbar en extra space
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollToTop = useCallback(() => {
    if(lazylist.current)
      lazylist.current.scrollToIndex(1, true);
  }, []);

  const showContextMenu = useCallback((e) => {
    e.event.preventDefault();
    setContextMenu({
      isOpen: true,
      event: e.event,
      page: e.page,
      layer: e.layer,
      contentItem: e.contentItem,
      spread: e.spread,
    });
  }, []);

  return (
    <Observer>
    {
      () => {
        const items = store.content.items.slice();

        // Zodra een item van layout size verandert wordt de lazylist hiervan op de hoogte gesteld
        // Oude reaction opruimen en nieuwe aanmaken
        if(autorunReaction.current)
          autorunReaction.current.dispose();

        autorun(reaction => {
          autorunReaction.current = reaction;

          // eslint-disable-next-line
          let touchLayout = 0;
          for(let item of items)
          {
            touchLayout += item.layout.width + item.layout.height;
          }

          // Ook luisteren naar editMode
          // eslint-disable-next-line
          touchLayout += store.ui.editMode;

          if(lazylist.current)
          {
            lazylist.current.updateVisibleItems();
            afterRenderCallback();
          }
        }, {
          delay: 20
        });

        const albumBackgroundColor = '25,26,27';
        const albumForegroundColor = '255,255,255';
        const albumSelection = [193, 147, 254];

        return (
          <>
            <div style={{
              position: 'relative',
              ...props.style,
            }}>
              <LazyList
                ref={lazylist}
                data={items}
                key={"spreads-"+store.storeInstanceId}

                viewportOffset={{before: 65, after: 230}}
                renderItem={renderItem}
                getItemLayout={getItemLayout}
                className={lazyListStyling.className + ' ' + (store.content.selection.length>0 ? 'selection' : '') }
                onResize={onResize}
                onScroll={onScrollThrottle}
                ListFooterComponent={renderFooter}
              />
            </div>

            <AppInstallBar />

            <ContextMenu
              {...contextMenu}
              onRequestClose={onContextMenuClose}
            />

            <SlideView />

            <div className="bottom">
              <div className="right-buttons">
                {showZoomButton && store.ui.showViewModePicker && <Floater>
                  <ToggleButtonDark
                    className={"small"}
                    label={translationStore.translate("album.view-as-photobook")}
                    checked={store.ui.viewMode==='book'}
                    darkColor={[66, 172, 89]}
                    onChange={() => {
                      if(store.ui.viewMode==='book')
                        zoomIn();
                      else
                        zoomOut();
                    }}
                  />
                </Floater>}
              </div>

              {
                Object.values(store.ui.components)
              }
            </div>

            
            <style global jsx>{`
              :root {
                --albumBackgroundColor: ${albumBackgroundColor};
                --albumForegroundColor: ${albumForegroundColor};
                --albumSelection: ${albumSelection};
              }
              body
              {
                background-color: rgba(var(--albumBackgroundColor), 1);
              }
            `}</style>
            <style jsx>{albumStyle}</style>
            <style global jsx>{`
              *:not([contenteditable]):not(input):not(textarea):not(.rte):not([contenteditable] *)
              {
                user-select: ${store.ui.editMode ? 'none' : 'invalid-value'};
              }

              @media (hover: none) {
                *:not([contenteditable]):not(input):not(textarea):not(.rte):not([contenteditable] *)
                {
                  user-select: none;
                }
              }
            `}</style>
            {lazyListStyling.styles}
          </>
        );
      }
    }
    </Observer>
  );
}

const ContextMenu = observer(function ContextMenu({layer, page, contentItem, spread, event, isOpen, onRequestClose}) {
  const store = useContext(AlbumContext);

  const contextMenuItems = [];
  const editMode = store.ui.editMode;
  const layerData = layer ? page.getLayerByMetaName(layer).get() : null;
  const asset = layerData?.style?.content!==undefined ? layerData.style.content.asset : undefined;
  const albumShareEnabled = (store.album.settings.showShareOptions || store.album.me.role === "owner");
  const layerName = asset ? asset.meta!==undefined ? asset.meta.editType!==undefined ? asset.meta.editType : asset.type : asset.type : 'layer';
  const [showAssetShare, setShowAssetShare] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  // Toon upsell als je owner bent maar nog geen abo met download rechten hebt
  const enableDownloadUpsell = store.album.me.role==='owner' && !store.user.subscription?.features?.download;
  const [showDownloadUpsell, setShowDownloadUpsell] = useState(false);
  
  const downloadAsset = useCallback((assetKey) => {
    if(enableDownloadUpsell) {
      setShowDownloadUpsell(true);
    } else {
      store.album.downloadAsset(assetKey);
    }
  }, [enableDownloadUpsell]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideDownloadUpsell = useCallback(() => {
    setShowDownloadUpsell(false);
  }, []);

  const onDeleteConfirmed = useCallback(() => {
    contentItem.delete();
    setShowDeleteConfirm(false);
  }, [contentItem]);

  if(asset)
  {
    // Tekst
    if(asset?.type === "text") {
      contextMenuItems.push({
        type: "item",
        title: translationStore.translate('context.copy-text'),
        onClick: () => {
          navigator.clipboard.writeText(removeMarkdown(asset.text))
          .then(() => console.log("Copied to clipboard"))
          .catch(e => console.error(e));
        }
      });
    }

    // Deel optie foto/video
    if(albumShareEnabled && ["photo", "video"].includes(asset?.type))
    {
      contextMenuItems.push({
        type: "item",
        title: translationStore.translate('default.share')+'...',
        onClick: () => {
          setShowAssetShare(asset);
        }
      });
    }

    // Toon download optie als setting aan staat of als je owner bent
    const showDownloadOption = store.album.settings.canDownload || store.album.me.role === 'owner';
    if(store.album.canDownloadAsset(asset) && showDownloadOption)
    {
      contextMenuItems.push({
        type: "item",
        title: translationStore.translate('context.download-original'),
        onClick: () => {
          downloadAsset(asset.key);
        }
      });
    }
  }

  if(editMode)
  {
    // Separator
    if(contextMenuItems.length>0)
    {
      contextMenuItems.push({type: "separator"});
    }

    // Layer removes
    if(layerData?.meta?.editable)
    {
      if(asset && page.design.type==='page')
      {
        switch(asset.type) {
          case "text":
            const freeMoveState = (layerData.meta.draggable && layerData.meta.resizable);
            contextMenuItems.push({
              type: "checkmark",
              checked: freeMoveState,
              title: translationStore.translate('context.free-move'),
              closeOnClick: true,
              onClick: () => {

                const meta = {...layerData.meta};
                meta.resizable = !freeMoveState;
                meta.draggable = !freeMoveState;

                page.setLayerProperty({
                  layer: layer,
                  path: ['meta'],
                  value: meta,
                });
              }
            });
          break;
        }
      }
    }

    // layer is editable en dus ook deletable, tenzij deletable echt op false staat
    const layerIsDeletable = layerData?.meta?.editable && layerData?.meta?.deletable!==false || layerData?.meta?.deletable===true;
    if(layerIsDeletable)
    {
      contextMenuItems.push({
        type: "item",
        title: translationStore.translate('context.delete-'+layerName),
        onClick: () => {
          if(!store.ui.editMode)
            return;

          // selectie goedzetten
          store.content.selection = [contentItem];

          runInAction(() => {
            store.edit.activeTab = 'page';
          });

          store.ui.editor.then(editor => {
            editor.removeLayer(layer, page, spread);
          });
        }
      });
    }

    if(contentItem!==undefined && contentItem.isDeletable)
    {
      contextMenuItems.push({
        type: "item",
        title: translationStore.translate("context.delete-page", {page: contentItem.label ? ' ' + contentItem.label : ''}),
        onClick: () => {
          setShowDeleteConfirm(true)
        },
      });
    }
  }

  if(store.user.isAdmin)
  {
    if(contextMenuItems.length>0 && contextMenuItems.slice(-1)[0].type!=="separator")
      contextMenuItems.push({type: "separator"});

    contextMenuItems.push({
      type: "item",
      title: "Show print version",
      onClick: () => {
        window.open('/album/' + store.album.key + '/print/?spread='+spread.key);
      }
    });
  }

  return (
    <>
      <Menu
        isOpen={isOpen}
        position={{event}}
        onRequestClose={onRequestClose}
      >
        {contextMenuItems.length>0 && Menu.ArrayItems(contextMenuItems)}
      </Menu>

      <AssetShareModal asset={showAssetShare} onClose={() => setShowAssetShare(false)} />

      {
        showDownloadUpsell && 
        <DownloadUpsellModal onClose={hideDownloadUpsell} />
      }

      {
        showDeleteConfirm && 
        <Confirm
          title={translationStore.translate("context.delete-page", {page: contentItem.label ? ' ' + contentItem.label : ''})}
          text={translationStore.translate("context.delete-page-confirmation-msg", {page: contentItem.label ? ' ' + contentItem.label : ''})}

          confirmLabel={translationStore.translate("base.delete")}

          onConfirm={onDeleteConfirmed}
          onCancel={() => {
            setShowDeleteConfirm(false)
          }}
        />
      }
    </>
  )
});

export default memo(Album);