import css from 'styled-jsx/css'

export const lazyListStyling = css.resolve`
  .lazylist {
    touch-action: none pan-up pan-down;
  }
`

export default css`
  .bottom {
    position: fixed;
    flex: 1;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    pointer-events: none;
    user-select: none;
  }

  .bottom :global(div) {
    pointer-events: auto;
  }

  .bottom .left-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: flex-start;
    padding-bottom: 15px;
    padding-bottom: max(15px, env(safe-area-inset-bottom));
    padding-right: 15px;
  }

  .bottom .left-buttons .test {
    padding: 5px 10px;
    background-color: white;
    border-radius: 5px;
  }

  .bottom .right-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: flex-end;
    padding-bottom: 15px;
    padding-bottom: max(15px, env(safe-area-inset-bottom));
    padding-right: 15px;
  }

  .bottom .right-buttons :global(>* + *) {
    margin-left: 15px;
  }

  :global(.main-content) {
    touch-action: none pan-up pan-down;
  }
`