import ReactDOM from 'react-dom';
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AlbumContext from '../context'
import translationStore from "stores/translation-store";
import InfoBar from './InfoBar';
import CommentsBar from './CommentsBar';
import {gridStyle} from './embedStyle';
import PhotoShareBar from './PhotoShareBar';
import ThemeStore from 'components/UI/store';
import { useUser } from 'utils/user/lib';
import useScript from 'hooks/useScript';
import { reaction, toJS } from 'mobx';

function Embed() {
  /** @type {import('../store').default} */
  const store = useContext(AlbumContext)
  const ref = useRef();
  const [infoBar, renderInfoBar] = useState(false);
  const [commentsBar, renderCommentsBar] = useState(false);
  const [photoShareBar, renderPhotoShareBar] = useState(false);
  const albumLikesCommentsEnabled = store.settings.albumLikesCommentsEnabled;
  const albumShareEnabled = store.settings.albumShareVisible;
  const album = useRef(null);
  const user = useUser();
  const scriptUrl = `${process.env.SITE_URL}/res/package/js/api-album.js?hl=${translationStore.language}`;

  useEffect(() => {
    const disposer = reaction(
      () => user.token,
      () => {
        if(album.current)
          album.current.setAuthorization(user.token?.token);
      }
    )
    
    return () => {
      disposer();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useScript(useCallback(() => {
    const holder = document.createElement('div');
    holder.id = 'holder';
    ref.current.innerHTML = '';
    ref.current.appendChild(holder);

    const init = (token) => {
      // eslint-disable-next-line
      album.current = new myalbum.album.Create("holder", {
        showHeader: true,
        autoplayVideo: true,
        onRequestAuthorization: () => {
          if(user.isLoggedIn)
          {
            return new Promise(resolve => {
              user.refreshToken().then(() => {
                resolve(user.token.token);
              })
            });
          }
        }
      });
      album.current.states.Change('windowLoad', true);
      album.current.grid.on('renderItem', onRenderItem);
      if(token) {
        album.current.setAuthorization(token);
      }

      album.current.SlideView.on('zoomIn', function() {
        store.menuBarLock = true;
        store.menuBarVisible = false;
        ThemeStore.setTheme("dark");
      });

      album.current.SlideView.on('zoomOut', function() {
        store.menuBarLock = false;
        ThemeStore.setTheme("light");
      });

      album.current.on('getSlideViewIcons', function(item, icons) {
        const itemShareEnabled = [1, 10].includes(item.type);

        if(albumLikesCommentsEnabled)
        {
          icons.push(
          {
            id: 'comments',
            onClick: function()
            {
              album.current.SlideView.sidebar.OpenAndRender('social');
            }.bind(this)
          });
        }

        if(albumShareEnabled && itemShareEnabled) {
          icons.push({
            id: "share",
            onClick: function()
            {
              album.current.SlideView.sidebar.OpenAndRender('share');
            }.bind(this)
          });
        }
        
        icons.push(
        {
          id: 'info',
          onClick: function()
          {
            album.current.SlideView.sidebar.OpenAndRender('info');
          }.bind(this)
        });
      });

      album.current.on('getSlideViewTabs', function(item, tabs)
      {
        const itemShareEnabled = [1, 10].includes(item.type);

        if(albumLikesCommentsEnabled)
        {
          tabs.push(
          {
            id: 'social',
            Render: function(callback)
            {
              var el = document.createElement('div');
              callback(el);

              // We update de state zodat React weet dat er een portal gemaakt moet worden voor de comments
              renderCommentsBar({
                el: el,
                itemKey: item.key,
              });

            }.bind(this)
          });
        }

        if(albumShareEnabled && itemShareEnabled) {
          tabs.push({
            id: "share",
            Render: function (callback) {
              var el = document.createElement('div');
              callback(el);

              // We update de state zodat React weet dat er een portal gemaakt moet worden voor de comments
              renderPhotoShareBar({
                el: el,
                item: item,
              });
            }.bind(this)
          });
        }

        tabs.push(
        {
          id: 'info',
          Render: function(callback)
          {
            var el = document.createElement('div');
            callback(el);

            album.current.getItemDetails(item.key, function(key) {
              
              // We update de state zodat React weet dat er een portal gemaakt moet worden voor de infobar
              renderInfoBar({
                el: el,
                itemKey: key,
                itemType: item.type,
                details: album.current.itemdata[key].details,
                SlideView: album.current.SlideView,
              });
            });
          }.bind(this)
        });
      });

      album.current.SlideView.on('slideChange', function()
      {
        UpdateTopIcons();
      }.bind(this));


      store.getRelativeScrollPosition = () => {
        return album.current.grid.getRelativeScrollPosition();
      }

      album.current._setAlbumData({
        key: store.key,
        title: store.title,
        subTitle: store.subTitle,
        header: toJS(store.header),
      });
      album.current.load(store.key);
    }

    if(user.isLoggedIn)
    {
      if(!user.token?.token)
      {
        user.refreshToken()
        .then((token) => {
          init(token.token);
        });
      }else
      {
        init(user.token.token);
      }
    }else
    {
      init();
    }

    return () => {
      if(album.current)
        album.current.destroy();
    }
  }, [albumLikesCommentsEnabled, albumShareEnabled, store, user]), {url: scriptUrl});

  function UpdateTopIcons() {
    if(!album.current.SlideView.currentItem())
      return;
    
    const item = album.current.SlideView.currentItem().item;
    const commentIcon = album.current.SlideView.icons.Get('comments');
    if(commentIcon)
      commentIcon.setAttribute('data-amount', item.social[1]);

    const gridCommentCount = document.getElementById('griditem-comments-'+item.key);
    if(gridCommentCount)
      gridCommentCount.setAttribute('data-amount', item.social[1]);
  }

  function onRenderItem(item, itemdata, div) {    
    var infodiv = document.createElement("div");
    infodiv.className = 'griditem-info';
    var comments = document.createElement('div');
    comments.className = 'griditem-comments';
    comments.id = 'griditem-comments-'+item.key;
    infodiv.appendChild(comments);
    div.appendChild(infodiv);
    
    item.commentsEl = comments;
    if(itemdata.social && itemdata.social.length==2)
    {
      comments.setAttribute('data-amount', itemdata.social[1]);
    }

    comments.addEventListener('click', function() {
      album.current.SlideView.sidebar.autoOpen('social');
    });
  }

  return (
    <div key="embed">
      <div className="grid" ref={ref}></div>
      <style jsx>{gridStyle}</style>

      {infoBar && <InfoBarPortal target={infoBar.el} details={infoBar.details} itemType={infoBar.itemType} itemKey={infoBar.itemKey} SlideView={infoBar.SlideView} />}
      {photoShareBar && <PhotoSharePortal target={photoShareBar.el} item={photoShareBar.item} domains={album.current.domains} />}
      {commentsBar && <CommentsBarPortal target={commentsBar.el} itemKey={commentsBar.itemKey} album={album.current} onUpdate={UpdateTopIcons} />}
    </div>
  );
}

export default Embed;


function InfoBarPortal(props) {
  return (
    ReactDOM.createPortal(<InfoBar details={props.details} itemType={props.itemType} SlideView={props.SlideView} />, props.target)
  );
}

function PhotoSharePortal({target, item, domains}) {
  return (
    ReactDOM.createPortal(<PhotoShareBar item={item} domains={domains} />, target)
  );
}

function CommentsBarPortal(props) {
  return (
    ReactDOM.createPortal(<CommentsBar itemKey={props.itemKey} album={props.album} onUpdate={props.onUpdate} />, props.target)
  );
}