import { enableStaticRendering } from 'mobx-react'
import { observable, action, makeObservable, computed } from "mobx";
const isSSR = (typeof window === "undefined")

// Nodig ivm serverside rendering, die gaat anders lekken omdat er geen unmounts e.d. zijn
enableStaticRendering(isSSR);

class Store {
  key = '';
  title = '';
  subTitle = '';
  header = {};
  lastUpdate = new Date(0);
  seo = {};
  scrollTop = 0;
  window = {
    innerWidth: 1600,
    innerHeight: 1200,
    clientWidth: 1600,
    scrollTop: 0,
  }
  mouse = {
    clientY: 0,
    clientX: 0,
  }
  _menuBarVisible = true;
  menuBarLock = false;
  access = {
    level: "hidden" // FIXME: Get this data from the server
  };
  settings = {};
  me = {
    hasBookmarked: false,
    role: 'member',
  };
  _stats = {

  }

  constructor(data) {
    makeObservable(this, {
      key: observable,
      title: observable,
      subTitle: observable,
      header: observable,
      lastUpdate: observable,
      seo: observable,
      window: observable,
      mouse: observable,
      _menuBarVisible: observable,
      settings: observable,
      me: observable,
      _stats: observable,

      hydrate: action,
      setScrollPosition: action,
      setMousePosition: action,
      setWindowSize: action,
      setSettings: action,
      menuBarVisible: computed,
      stats: computed,

      downloadsEnabled: computed,
      canContribute: computed,
    });

    this.hydrate(data);
  }

  get menuBarVisible() {
    return this._menuBarVisible;
  }

  set menuBarVisible(value) {
    this._menuBarVisible = value;
  }

  get stats() {
    return this._stats;
  }

  set stats(value) {
    this._stats = value;
  }

  hydrate(data) {
    if (!data) return;

    this.key = data.key;
    this.title = data.title;
    this.subTitle = data.subTitle;    
    this.header = data.header;
    this.lastUpdate = new Date(data.lastUpdate * 1000);
    this.settings = data.settings;
    this.me = data.me;
  }

  setWindowSize(size) {
    this.window = size;
  }

  setScrollPosition(scrollTop) {
    scrollTop = scrollTop > 0 ? scrollTop : 0;

    const direction = scrollTop===this.scrollTop ? this.scrollDirection : (scrollTop > this.scrollTop ? 'down' : 'up');
    this.scrollDirection = direction;
    this.scrollTop = scrollTop;
    const mouseAtTop = this.mouse.clientY<=60;
    const menuBarVisible = direction==='down' && !mouseAtTop ? ((scrollTop + 65) < this.window.innerHeight) : true;

    if(!this.menuBarLock)
      this.menuBarVisible = menuBarVisible;
    
    this.window.scrollTop = scrollTop;
  }
  
  setSettings(_settings) {
    // Probleem is dat de API de settings steeds weer anders noemt, soms Enabled, soms Visible aan het einde
    // converteer "verkeerde" "Enabled" naar "Visible"
    let settings = JSON.parse(JSON.stringify(_settings));
    for(let key in settings) {
      if(key.substring(key.length-7)==='Enabled')
      {
        settings[key.substring(0, key.length-7)+'Visible'] = settings[key];
        delete settings[key];
      }
    }

    Object.assign(this.settings, {...settings});
  }

  setMousePosition(position) {
    Object.assign(this.mouse, {...position});

    if(position.clientY<=60 && !this.menuBarLock)
      this.menuBarVisible = true;
  }

  get downloadsEnabled() {
    return this.settings.albumDownloadsEnabled;
  }

  get canContribute() {
    return this.settings.albumContributionsEnabled && this.me.role!=='owner';
  }
}

export default Store;