import { toJS, action, reaction, observable, computed, runInAction, transaction, makeObservable } from 'mobx';
import PageModel from '../page-model';
import SpreadComponent from 'components/Spread/Spread';
import {getProp, getBoundingBoxFromFrame} from 'utils/Utils';
import {FetchJSON} from 'utils/Fetch';
import { debounce } from 'throttle-debounce';
import ContentType from './content-type';
import { v4 as uuidv4 } from 'uuid';
import MarkdownIt from "markdown-it";

class SpreadModel extends ContentType {
  _suggestions = [];
  _suggestionsCache = {};
  pages = [];
  pageRows = [];
  prepends = [];
  appends = [];
  _autoPushToServer = 1;
  _setPageRowsId = 0;

  constructor(contentItem)
  {
    super(contentItem);

    makeObservable(this, {
      _suggestions: observable,
      pages: observable,
      pageRows: observable,
      prepends: observable,
      appends: observable,

      setPages: action,
      resetPageSuggestions: action,
      fetchSuggestions: action,
      fill: action,
      pano: action,

      suggestions: computed({keepAlive: true}),
      pageData: computed({keepAlive: true}),
    });

    this.store = this.contentItem.store.store;
    this.data = this.contentItem.data;
    this.blueprints = [];
    this.pageSpacing = {
      default: 1,
      vertical: 0,
      horizontal: 0
    };
    this.preferBlueprint = 'default';
    this.width = 0;
    this.height = 0;
    this.save = debounce(20, this.save.bind(this));
    this.setPages(toJS(this.data.pages));

    this.on('mousedown', (e) => {
      this.store.content.trigger('mousedown', e);
    });
    this.on('mouseup', (e) => {
      this.store.content.trigger('mouseup', e);
    });
    this.on('click', (e) => {
      this.store.content.trigger('click', e);
    });
    this.on('dblclick', (e) => {
      this.store.content.trigger('dblclick', e);
    });
    this.on('arrow', (e) => {
      this.store.content.trigger('scrollToTop', e);
    });
    this.on('contextmenu', (e) => {
      this.store.content.trigger('contextmenu', e);
    });
    this.on('longtouch', (e) => {
      this.store.content.trigger('longtouch', e);
    });

    // Controleert of page een push naar de server wil versturen
    this.disposers.push(
      reaction(
        () => this.pages.map(page => page.wantsServerPush),
        (values) => {
          if(!this.store.ui.editMode)
            return;

          // Check of een van de pagina's wel een serverpush wil
          let pageWantsServerPush = false;
          for(let value of values)
          {
            if(value>0)
            {
              pageWantsServerPush = true;
              break;
            }
          }

          if(!pageWantsServerPush)
            return;

          if(this.autoPushToServer)
          {
            this.save();
            this.resetPageSuggestions();
            this.resetCacheSuggestions();
          }
        }
      )
    );

    this.disposers.push(() => {
      for(const p of this.pages)
        p.dispose();
    })
  }

  get autoPushToServer() {
    return this._autoPushToServer>0;
  }

  set autoPushToServer(state) {
    if(state)
      this._autoPushToServer++;
    else
      this._autoPushToServer--;

    if(this._autoPushToServer>1)
      this._autoPushToServer = 1;
  }

  // Alleen targetPage wijzigen
  setPage(pageData, targetPage=0) {

    transaction(() => {
      runInAction(() => {
        this.autoPushToServer = false;

        // Als niet direct data meegekregen hebben dan een promise instellen
        const data = pageData.data ? pageData.data : () => {
          return new Promise(resolve => {
            this.fetch()
              .then(data => {
                const myData = data.pages[targetPage].data;
                resolve(myData);
              })
              .catch(() => {
                // Helaas, geen data beschikbaar voor deze pagina
              });
          });
        }
        const page = new PageModel({
          index: targetPage,
          design: pageData.design,
          data: data,
        });

        const _pages = [];
        for(let i=0; i<this.pages.length; i++) {
          const p = this.pages[i];

          // targetPage vervangen, verder gewoon de oude pages terugzetten
          if(i===targetPage)
            _pages.push(page);
          else
            _pages.push(p);
        }

        // Oude pages disposen!
        for(const p of this.pages)
          p.dispose();

        this.pages.replace(_pages);
        this._fetchPromise = undefined;
        this._getPageRowsCacheKey = null;
        this.contentItem.calculateLayout(true);
        this.autoPushToServer = true;

        if(this.store.edit)
          this.store.edit.spreadPagesChanged(this);
      });
    });
  }

  // Alle pages wijzigen
  setPages(pages) {
    transaction(() => {
      this.autoPushToServer = false;
      const _pages = [];
      pages.forEach((pageData, pageIndex) => {
        // Als niet direct data meegekregen hebben dan een promise instellen
        const data = pageData.data ? pageData.data : () => {
          return new Promise(resolve => {
            this.fetch()
              .then(data => {
                const myData = data.pages[pageIndex].data;
                resolve(myData);
              })
              .catch(() => {
                // Helaas, geen data beschikbaar voor deze pagina
              });
          });
        };

        const page = new PageModel({
          index: pageIndex,
          design: pageData.design,
          data: data,
        });

        _pages.push(page);
      });

      // Oude pages disposen!
      for(const p of this.pages)
        p.dispose();

      this.pages.replace(_pages);
      this._fetchPromise = undefined;
      this._getPageRowsCacheKey = null;
      this.contentItem.calculateLayout(true);
      this.autoPushToServer = true;

      if(this.store.edit)
        this.store.edit.spreadPagesChanged(this);
    });
  }

  fetch() {
    if(!this._fetchPromise)
    {
      this._fetchPromise = new Promise((resolve, reject) => {
        const [promise] = FetchJSON(this.data.url)
        promise
          .then(res => {
            const json = res.data;
            let spreadUpToDate = true;
            if(json.pages.length!==this.pages.length)
            {
              spreadUpToDate = false;
            }else
            {
              for(let a=0; a<json.pages.length; a++)
              {
                const newPage = json.pages[a];
                const oldPage = this.pages[a];
                if(oldPage.design.key!==newPage.design.key)
                {
                  spreadUpToDate = false;
                  break;
                }
              }
            }

            if(spreadUpToDate)
              resolve(json);
            else
            {
              // Spread is invalid geworden, graag opnieuw pages instellen
              this.setPages(json.pages);
              reject();
            }
          })
          .catch(e => {
            // Spread kon niet opgehaald worden, verwijder spread maar uit de lokale lijst
            reject(e);
            this.contentItem.delete();
          })
      });
    }

    return this._fetchPromise;
  }

  get key() {
    return this.contentItem.key;
  }

  get pageData() {
    const pages = [];
    for(const page of this.pages)
    {
      // Verzamel de benodigde data van deze page
      const data = toJS(page.data);
      pages.push({
        data: {
          blueprintExtensions: data.blueprintExtensions,
          layerData: data.layerData,
        },
        design: {key: page.design.key}
      });
    }

    return pages;
  }

  save() {
    return new Promise(resolve => {
      if(this.saveCancel)
        this.saveCancel();

      const albumKey = (this.store.album.key);
      const pages = this.pageData;
      const data = {
        pages: pages,
      }

      const [promise, cancel] = this.store.user.FetchJSON(process.env.API_URL + '/album/' + albumKey + '/spreads/' + this.key, {
        method: 'PUT',
        data
      });
      this.saveCancel = cancel;

      promise.then(() => {
        resolve();
      });
      promise.catch(e => {
        console.error("spread save failed", e);
      });
    });
  }

  // Returns promise met daarin de size {width, height}
  getSize(config) {
    const viewModeSuggestion = config.viewMode;
    const viewPort = config.viewPort;
    const seperatorWidth = config.seperatorWidth;
    const seperatorHeight = config.seperatorHeight;
    if(viewPort.width<=0 || viewPort.height<=0)
    {
      // Geen nut om te gaan berekenen, we zijn gewoon niet zichtbaar
      this.width = 0;
      this.height = 0;

      return Promise.resolve({
        width: this.width,
        height: this.height,
      })
    }

    const viewMode = this.getViewMode(viewModeSuggestion);
    return new Promise(resolve => {
      this.getCachedPageRows(viewMode, viewPort, seperatorWidth, seperatorHeight)
        .then(() => {
          return this.fixPanos(viewMode);
        })
        .then(() => {
          resolve({
            width: this.width,
            height: this.height
          });
        })
        .catch(e => {
          this.message = {
            type: 'error',
            msg: e,
          }
          resolve({
            width: this.width,
            height: 100
          })
        })
    });
  }

  getViewMode(viewMode) {
    // Smallscreen gebruiken we niet in de editMode
    // teksten zitten bv niet in de blueprints waardoor allerlei edit dependencies kaputt gaan
    if(this.store.ui.editMode && viewMode==='smallscreen')
      viewMode = 'book';

    return viewMode;
  }

  fixPanos(viewMode) {
    return new Promise(resolve => {
      if(this.pages.length===1 && !this.store.ui.editMode && viewMode!=='book')
      {
        this.pages[0].getData().then(pageData => {
          let assets = [];
          let panoLayerName = undefined;
          for(let layerName in pageData.layerData)
          {
            const layerData = pageData.layerData[layerName];
            const assetKey = getProp(layerData, ['style', 'content', 'asset']);
            assets.push(assetKey);
            panoLayerName = layerName;

            // Bij meer dan 1 gewoon gelijk kappen
            if(assets.length>1)
            {
              break;
            }
          }

          if(assets.length===1)
          {
            // Assets van deze spread zijn nu beschikbaar
            const asset = assets[0];
            if(asset && Array.isArray(asset.sizes)) {
              const size = asset.sizes.slice(-1)[0];
              const ratio = size.width/size.height;
              if(ratio >= 2.98)
              {
                resolve({
                  asset: asset,
                  layerName: panoLayerName
                });
              }else
              {
                return resolve(false);
              }
            }else
            {
              return resolve(false);
            }
          }else
          {
            resolve(false);
          }
        });
      }else
      {
        resolve(false);
      }
    })
    .then(pano => {
      this.pages[0].pano = pano;

      if(pano)
        this.pano(this.viewPort.width, this.viewPort.height, pano);
    })
  }

  // Bepaalt per page welke blueprints gebruikt moeten worden
  getCachedPageRows(viewMode, viewPort, seperatorWidth, seperatorHeight) {
    const cacheKey = [
      JSON.stringify(viewPort),
      viewMode,
      this.store.ui.editMode ? 'editmode-on' : 'editmode-off',
      seperatorWidth,
      seperatorHeight
    ].join('-');

    if(this._getPageRowsCacheKey !== cacheKey)
    {
      // Cache vernieuwen
      this._getPageRowsCacheKey = cacheKey;
      this.getPageRowsPromise = this.setPageRows(viewMode, viewPort, seperatorWidth, seperatorHeight);
    }

    return this.getPageRowsPromise;
  }

  // Berekent welke blueprints er gebruikt moeten worden en verdeelt deze over pagerows
  // {width, height} worden ook gelijk berekend
  setPageRows(viewMode, viewPort, seperatorWidth, seperatorHeight) {
    this.viewMode = viewMode;
    this.viewPort = viewPort;
    this.seperatorWidth = seperatorWidth;
    this.seperatorHeight = seperatorHeight;
    this._setPageRowsId++;

    return new Promise((resolve, reject) => {
      runInAction(() => {
        this.pageRows = [];
        this.prepends = [];
        this.appends = [];
        this.preferBlueprint = 'default';
      });

      try{
        switch(viewMode)
        {
          case "cover":
            this.pageSpacing.vertical = this.pageSpacing.default;
            this.pageSpacing.horizontal = 0;
            this.cover(viewPort.width, viewPort.height, viewPort.inset);

            resolve();
          break;
          case "book-cover":
          case "book-backside":
            const variant = viewMode==='book-backside' ? 'backside' : 'cover';
            this.pageSpacing.vertical = this.pageSpacing.default;
            this.pageSpacing.horizontal = 0;

            this.bookCover(viewPort.width, viewPort.height, variant);
            resolve();
          break;
          default:
          case "fit":
            this.pageSpacing.vertical = 0;
            this.pageSpacing.horizontal = 0;
            this.fit(viewPort.width, viewPort.height);

            if(this.store.ui.editMode)
            {
              resolve();
            }else
            {
              this.cutout(this.width).then(() => {
                resolve();
              })
            }
          break;
          case "fill":
            this.pageSpacing.vertical = 0;
            this.pageSpacing.horizontal = 0;

            // Als we in editmode zitten, dan moeten we alle pages renderen, anders alleen de niet-lege pagina's
            const pagesToRender = new Promise(resolve => {
              if(this.store.ui.editMode)
                resolve(this.pages);
              else
              {
                this.getNonEmptyPages(this.pages).then((pages) => {
                  resolve(pages);
                });
              }
            });
            
            pagesToRender.then((pages) => {              
              this.fill(pages, viewPort.width);

              if(this.store.ui.editMode)
              {
                resolve();
              }else
              {
                this.cutout(this.width)
                .then(() => {
                  resolve();
                })
              }
            });
          break;
          case "book":
            this.pageSpacing.vertical = 0;
            this.pageSpacing.horizontal = this.pageSpacing.default;

            this.book(viewPort.width, viewPort.height);
            resolve();
          break;
          case "smallscreen":
            this.pageSpacing.vertical = 0;
            this.pageSpacing.horizontal = 0;

            this.fill(this.pages, viewPort.width);
            this.cutout(viewPort.width)
            .then(() => {
              resolve();
            })
          break;
        }
      }catch(e) {
        reject(e);
      }
    });
  }

  // Beste blueprint die deze viewport kan vullen
  getBestBlueprintForViewport(blueprints, viewport) {
    let wantedRatio = viewport.height/viewport.width;
    let currentBestBlueprint = undefined;

    for(let a=0; a<blueprints.length; a++)
    {
      let blueprint = blueprints[a];
      let blueprintRatio = blueprint.artBox.height/(blueprint.artBox.width/2);
      let currentBestBlueprintRatio = currentBestBlueprint ? currentBestBlueprint.artBox.height/(currentBestBlueprint.artBox.width/2) : undefined;

      let blueprintDiff = Math.abs(wantedRatio - blueprintRatio);
      let currentBestBlueprintDiff = currentBestBlueprint ? Math.abs(wantedRatio - currentBestBlueprintRatio) : undefined;
      if(currentBestBlueprintRatio===undefined || blueprintDiff < currentBestBlueprintDiff)
      {
        currentBestBlueprint = blueprint;
      }
    }

    return currentBestBlueprint;
  }

  getBestBlueprints(pages, width, height) {
    let bestBlueprints = [];
    for(const page of pages)
    {
      const blueprints = page.design.blueprints;
      if(page.design.type!==undefined && page.design.type==='cover')
      {
        let blueprint = this.getBestBlueprintForViewport(blueprints, {width, height});
        bestBlueprints.push(blueprint);
        continue;
      }

      if(this.preferBlueprint)
      {
        let preferredBlueprints = blueprints.filter(blueprint => {
          return (blueprint.key===this.preferBlueprint)
        });

        if(preferredBlueprints.length>0)
        {
          bestBlueprints.push(preferredBlueprints[0]);
          continue;
        }
      }

      // Geen smallscreen of geen smallscreen blueprint gevonden
      for(const blueprint of blueprints)
      {
        if(blueprint.key!=='smallscreen')
        {
          bestBlueprints.push(blueprint);
          continue;
        }
      }
    }

    return bestBlueprints;
  }

  bookCover(width, height, variant) {
    const blueprint = this.pages[0].design.blueprints.find(blueprint => blueprint.key==='landscape-2');
    const frame = {
      width: blueprint.artBox.width/2,
      height: blueprint.artBox.height,
      x: variant==='cover' ? blueprint.artBox.x + blueprint.artBox.width/2 : blueprint.artBox.x,
      y: blueprint.artBox.y,
    };

    const rowWidth = blueprint.artBox.width;
    const rowHeight = blueprint.artBox.height;
    const ratio = rowWidth / rowHeight;
    const scale = Math.max(rowWidth / width, rowHeight / height);

    // rowWidth als we niet alleen de cover zouden tonen, anders tonen we de cover te groot
    let scaledRowWidth = Math.min(width, Math.round((rowWidth/scale)/2) * 2);
    let scaledRowHeight = Math.round(scaledRowWidth / ratio);
    // rowWidth met alleen de cover in beeld
    scaledRowWidth = Math.min(width, Math.round((frame.width/scale)/2) * 2);

    this.width = scaledRowWidth;
    this.height = scaledRowHeight;

    let newPages = [];
    let index = 0;
    for(let page of this.pages)
    {
      page.setBlueprint(blueprint);
      page.setConfig({
        crop: frame,
        width: this.width,
        height: this.height,
        marginLeft: index > 0 ? this.pageSpacing.horizontal : 0,
        type: 'blueprint'
      });

      newPages.push(page);
      index++;
    }

    this.pageRows.push({
      pages: newPages,
      width: this.width,
      height: this.height,
      marginTop: 0
    });
  }

  // Fill width met 1 page
  cover(width, height, inset) {
    const bestBlueprints = this.getBestBlueprints(this.pages, width, height);

    function getViewFrame(wantedWidth, wantedHeight, blueprint)
    {
      var frame = {};
      var maxDesignWidth = blueprint.width / 2;
      var maxDesignHeight = blueprint.height;

      var minDesignWidth = blueprint.artBox.width / 2;
      var minDesignHeight = blueprint.artBox.height;
      var ratio = wantedHeight/wantedWidth;

      var ratios = [minDesignHeight/maxDesignWidth, minDesignHeight/minDesignWidth, maxDesignHeight/maxDesignWidth, maxDesignHeight/minDesignWidth];

      ratio = Math.min(ratio, Math.max.apply(null, ratios));

      var base = minDesignHeight;
      var width = base / ratio;
      var height = base;

      if(width < minDesignWidth)
      {
        width = minDesignWidth;
        height = width * ratio;
      }

      if(width>maxDesignWidth)
      {
        // te breed, maar dat is niet heel erg toch?
        width = maxDesignWidth;
        if(width>blueprint.width)
        {
          // Breder dan artboard, dat kan eigenlijk niet
          width = blueprint.width;
        }
      }

      frame.width = Math.round(width);
      frame.height = Math.round(height);
      frame.x = Math.round(blueprint.width / 2);
      frame.y = Math.round((blueprint.height - height)/2);

      return frame;
    }

    let blueprint = toJS(bestBlueprints[0]);
    let frame = getViewFrame(width, height, blueprint);
    let scaleX = width / frame.width;
    let coverWidth = width;
    let coverHeight = Math.round(frame.height * scaleX) + inset.top;

    let safeArea =  {width: blueprint.artBox.width/2, height: blueprint.artBox.height};
    let moveRight = {
      x: ((frame.width - safeArea.width) / 2),
      y: 0
    }

    runInAction(() => {
      this.width = 0;
      this.height = 0;
      this.pageRows = [];
    });

    transaction(() => {
      let index = 0;
      for(let page of this.pages)
      {
        let blueprint = bestBlueprints[index];
        page.setBlueprint(toJS(blueprint));
        page.setConfig({
          crop: frame,
          moveRight: moveRight,
          width: coverWidth,
          height: coverHeight,
          type: 'blueprint'
        });

        let pageRow = {
          pages: [page],
          width: coverWidth,
          height: coverHeight,
          paddingTop: inset.top
        };

        pageRow.width = coverWidth;
        pageRow.height = coverHeight;

        runInAction(() => {
          this.pageRows.push(pageRow);
          this.width = pageRow.width;
          this.height += pageRow.height;
        });
        index++;
      }
    });

  }

  pano(width, height, panoData) {
    let panoWidth = width;

    const pixelRatio = Math.max(1, window.devicePixelRatio / 1.5);
    const imageSize = panoData.asset.sizes.slice(-1)[0];
    const imageRatio = imageSize.width / imageSize.height;
    const maxPanoHeight = Math.min(imageSize.height / pixelRatio, height);

    // minScale zodat er nog steeds een pan kan
    const minPanoHeight = (width + 200) / imageRatio;
    const minScale = 1 / (maxPanoHeight / minPanoHeight);

    let panoHeight = maxPanoHeight;
    switch(this.store.ui.viewMode) {
      case "book":
        panoHeight = maxPanoHeight * minScale;
      break;
      case "fit":
        panoHeight = maxPanoHeight * (minScale + ((1 - minScale)/2));
      break;
      default:
        panoHeight = maxPanoHeight;
      break;
    }

    panoHeight = Math.max(minPanoHeight, panoHeight);

    const page = this.pages[0];
    page.setConfig({
      width: panoWidth,
      height: panoHeight,
      type: 'pano'
    });

    let pageRow = {
      pages: [page],
      width: panoWidth,
      height: panoHeight
    };

    this.pageRows = [pageRow];
    this.width = panoWidth;
    this.height = panoHeight;
  }

  // Fill width met 1 page
  fill(pages, width) {
    const bestBlueprints = this.getBestBlueprints(pages);

    this.width = 0;
    this.height = 0;
    this.pageRows = [];
    let index = 0;
    for(let page of pages)
    {
      let blueprint = bestBlueprints[index];
      let w = blueprint.artBox.width;
      let h = blueprint.artBox.height;
      let scale = w / width;
      let scaledHeight = Math.round(h / scale);
      let scaledWidth = width;
      let marginTop = index ? this.pageSpacing.vertical : 0;

      page.setBlueprint(toJS(blueprint));
      page.setConfig({
        crop: blueprint.artBox,
        width: scaledWidth,
        height: scaledHeight,
        type: 'blueprint'
      });

      let pageRow = {
        pages: [page],
        width: scaledWidth,
        height: scaledHeight,
        marginTop: marginTop
      };

      pageRow.width= scaledWidth;
      pageRow.height = scaledHeight;

      this.pageRows.push(pageRow);
      this.width = pageRow.width;
      this.height += pageRow.height;
      this.height += marginTop;
      index++;
    }
  }

  // Fit 1 page in viewport
  fit(width, height) {
    const bestBlueprints = this.getBestBlueprints(this.pages);

    this.width = 0;
    this.height = 0;
    this.pageRows = [];
    let index = 0;
    for(let page of this.pages)
    {
      let blueprint = bestBlueprints[index];

      let w = blueprint.artBox.width;
      let h = blueprint.artBox.height;
      const ratio = h/w;
      let scale = Math.max(w / width, h / height);
      let scaledHeight = Math.round(h / scale);
      let scaledWidth = Math.floor(scaledHeight / ratio);
      let marginTop = index ? this.pageSpacing.vertical : 0;

      page.setBlueprint(toJS(blueprint));
      page.setConfig({
        crop: blueprint.artBox,
        width: scaledWidth,
        height: scaledHeight,
        type: 'blueprint'
      });

      this.pageRows.push({
        pages: [page],
        width: scaledWidth,
        height: scaledHeight,
        marginTop: marginTop
      });

      this.width = scaledWidth;
      this.height += scaledHeight;
      this.height += marginTop;

      index++;
    }
  }

  // Fit 1 page in viewport
  book(width, height) {
    const bestBlueprints = this.getBestBlueprints(this.pages);
    const pageSpacing = ((this.pages.length-1) * this.pageSpacing.horizontal);
    const rowWidth = (bestBlueprints[0].artBox.width * this.pages.length);
    const rowHeight = bestBlueprints[0].artBox.height;
    const ratio = rowWidth / rowHeight;
    width -= pageSpacing;

    const scale = Math.max(rowWidth / width, rowHeight / height);
    let scaledRowWidth = Math.min(width, Math.round((rowWidth/scale)/2) * 2);
    let scaledRowHeight = Math.round(scaledRowWidth / ratio);

    this.width = scaledRowWidth + pageSpacing;
    this.height = scaledRowHeight;

    let newPages = [];
    let index = 0;
    for(let page of this.pages)
    {
      let blueprint = toJS(bestBlueprints[index]);
      page.setBlueprint(blueprint);
      page.setConfig({
        crop: blueprint.artBox,
        width: Math.round(scaledRowWidth/this.pages.length),
        height: this.height,
        marginLeft: index > 0 ? this.pageSpacing.horizontal : 0,
        type: 'blueprint'
      });

      newPages.push(page);
      index++;
    }

    this.pageRows.push({
      pages: newPages,
      width: this.width,
      height: this.height,
      marginTop: 0
    });
  }

  /**
   * Returns non empty pages
   * @param {PageModel[]} _pages 
   * @returns Promise<PageModel[]>
   */
  getNonEmptyPages(_pages) {    
    return new Promise((resolve) => {
      const pages = [];
      for(const page of _pages)
      {
        pages.push(page.getData());
      }

      Promise.all(pages)
      .then(() => {  
        const notEmptyPages = [];      
        for(const page of _pages) {
          const isEmpty = Object.keys(page.layerData).length === 0;
          if(!isEmpty) {
            notEmptyPages.push(page);
          }
        }

        if(notEmptyPages.length === 0)
        {
          // Hele spread leeg, dan tonen we toch maar een lege pagina
          notEmptyPages.push(_pages[0]);
        }

        resolve(notEmptyPages);
      });
    });
  }

  // Knip blueprint uit zodat alleen de assets nog mooi in beeld staan
  // smallscreen, maar ook op bigscreens levert dit veel nettere views op
  cutout(width) {
    const defaultPadding = this.seperatorHeight/2;

    return new Promise(resolve => {
      // Alle rows en pages klaarmaken zodat we later ook 
      // naar vorige en volgende page kunnen kijken
      let rows = [];
      for(let pageRow of this.pageRows)
      {
        rows.push(new Promise(resolveRow => {
          let pages = [];
          for(let page of pageRow.pages)
          {
            pages.push(page.ready());
          }

          Promise.all(pages).then(() => {
            resolveRow(pageRow);
          });
        }));
      }

      Promise.all(rows).then(rows => {

        for(let rowIndex=0; rowIndex<rows.length; rowIndex++)
        {
          const row = rows[rowIndex];
          const pages = row.pages;
          const prevRow = rowIndex>0 ? rows[rowIndex-1] : undefined;
          const nextRow = rowIndex<rows.length-1 ? rows[rowIndex+1] : undefined;
          let rowWidth = 0;
          let rowHeight = 0;

          for(let pageIndex=0; pageIndex<pages.length; pageIndex++)
          {
            const page = row.pages[pageIndex];
            const prevPage = pageIndex > 0 ? row.pages[pageIndex-1] : (prevRow ? prevRow.pages[prevRow.pages.length-1] : undefined);
            const nextPage = pageIndex<row.pages.length-1 ? row.pages[pageIndex+1] : nextRow ? nextRow.pages[0] : undefined;

            // cutout van page ophalen
            // cutout knipt page zodat alle benodigde assets precies nog in beeld staan
            const assetsToCutout = this.viewMode==='smallscreen' ? ['photo', 'video', 'text'] : ['photo', 'video', 'text'];
            const cutout = page.cutout(assetsToCutout);
            if(cutout) {
              const hasPadding = cutout.x>0;
              let padding = {
                top: hasPadding ? defaultPadding * 1.5: 0,
                left: hasPadding ? defaultPadding: 0,
                bottom: hasPadding ? defaultPadding * 1.5: 0,
                right: hasPadding ? defaultPadding: 0,
              };

              const background = page.artboardFill;
              if(prevPage)
              {
                const prevBackground = prevPage.artboardFill;
                if(prevBackground.join(',')===background.join(','))
                  padding.top = 0;
              }


              if(nextPage)
              {
                const nextBackground = nextPage.artboardFill;
                if(nextBackground.join(',')===background.join(','))
                  padding.bottom = defaultPadding*0.5;
              }

              // Nieuw frame bepaald, knip blueprint maar lekker uit
              let pixelWidth = width - (padding.left + padding.right);
              let pixelHeight = Math.round(cutout.height/cutout.width * pixelWidth);

              page.setConfig({
                crop: cutout,
                width: pixelWidth,
                height: pixelHeight,
                padding: padding,
                background: background,
              });

              rowWidth += pixelWidth + (padding.left+padding.right);
              rowHeight += pixelHeight  + (padding.top+padding.bottom);
            }else
            {
              // Geen crop, bijvoorbeeld een pagina zonder assets
              let pixelWidth = width;
              let pixelHeight = Math.round(page.crop.height/page.crop.width * pixelWidth);

              page.setConfig({
                crop: page.crop,
                width: pixelWidth,
                height: pixelHeight,
              });

              rowWidth += pixelWidth;
              rowHeight += pixelHeight;
            }
          }

          runInAction(() => {
            if(rowWidth>0)
            {
              row.width = rowWidth;
              row.height = rowHeight;
            }else
            {
              row.height = 0;
            }
          });
        }

        let maxWidth = 0;
        let height = 0;
        for(let row of rows)
        {
          if(row.width>maxWidth)
            maxWidth = row.width;

          height += row.height;
        }

        this.width = maxWidth;
        this.height = height;

        resolve();
      });
    });
  }

  // Zorg dat tekst beter leesbaar is op b.v. smallscreens
  improveTextReadability(state) {
    if(!state) {
      // Niks fixen
      this._improveTextReadabilityWidth = 0;

      return new Promise(resolve => {
        for(let index=0; index<this.pageRows.length; index++)
        {
          const pageRow = this.pageRows[index];
          for(let page of pageRow.pages)
          {
            page.setZoomTextLayers([]);
          }
        }

        resolve();
      });
    }

    if(!this._improveTextReadabilityPromise || this._improveTextReadabilityWidth!==this.width) {
      this._improveTextReadabilityWidth = this.width;

      this._improveTextReadabilityPromise = new Promise(resolve => {
        const texts = [];
        const processPages = new Promise(processResolve => {
          const pagePromises = [];
          for(let index=0; index<this.pageRows.length; index++)
          {
            const pageRow = this.pageRows[index];
            for(let page of pageRow.pages)
            {
              const pagePromise = new Promise(pageResolve => {
                page.ready().then(() => {

                  // Array gaat layer meta names bevatten die bij het renderen van de spread een textZoom moeten krijgen
                  const zoomTextLayerCandidates = [];
                  const crop = page.crop;

                  page.walkLayers(layer => {
                    const asset = getProp(layer, ['style', 'content', 'asset']);
                    const rotation = getProp(layer, ['style', 'rotation']);
                    const fontSize = getProp(layer, ['styleSheet', 'fontSize']);
                    const titleFontSize = getProp(layer, ['styleSheet', 'h1', 'fontSize']);

                    // Geen tekst asset? Dan skippen
                    if(!asset || asset.type!=='text' || fontSize>=25)
                      return;

                    // Krijgen de boundingbox terug en houden rekening met rotation
                    let box = getBoundingBoxFromFrame(layer.frame, rotation);
                    const right = box.x2;
                    const left = box.x1;
                    const top = box.y1;
                    const bottom = box.y2;

                    // Bevindt de layer zich compleet binnen de crop?
                    const gracePixels = 1; // extra marge ivm afrondingen
                    const layerIsInsideCrop = (
                      (left >= crop.x-gracePixels && (left <= (crop.x+crop.width+gracePixels)))
                      &&
                      (right >= crop.x-gracePixels && (right <= (crop.x+crop.width+gracePixels)))
                      &&
                      (top >= crop.y-gracePixels && (top <= (crop.y+crop.height+gracePixels)))
                      &&
                      (bottom >= crop.y-gracePixels && (bottom <= (crop.y+crop.height+gracePixels)))
                    );


                    // Betreft dit een title vak? Dan moet fontSize van body en van Title hetzelfde zijn
                    const isTitleField = fontSize===titleFontSize;

                    // Als het geen titleField is, maar het bevat alleen maar een title...
                    let isTitleOnly = false;
                    if(asset && asset.type==='text' && !isTitleField)
                    {
                      const markdownit = MarkdownIt();
                      const tokens = markdownit.parse(asset.text);
                      const hasP = tokens.some(token => {
                        return (token.type==="paragraph_open")
                      });
                      const hasH1 = tokens.some(token => {
                        return (token.type==="heading_open")
                      });

                      if(!hasP && hasH1)
                      {
                        isTitleOnly = true;
                      }
                    }

                    const isTitle = (isTitleOnly || isTitleField) && (titleFontSize>=25);

                    // Op smallscreen alle tekst hiden, op grotere schermen alleen alles (gedeeltelijk) buiten de crop
                    const extractThisAsset = !isTitle && (this.viewMode==='smallscreen' ? true : !layerIsInsideCrop);
                    if(asset && asset.type==='text' && extractThisAsset)
                    {
                      // Enable textZoom
                      zoomTextLayerCandidates.push(layer.meta.name);
                    }
                  });

                  page.setZoomTextLayers(zoomTextLayerCandidates);
                  pageResolve();
                });
              });

              pagePromises.push(pagePromise);
            }
          }

          Promise.all(pagePromises).then(() => {
            processResolve();
          })
        });

        processPages.then(() => {
          Promise.all(texts).then(values => {
            const result = {
              prepends: [],
              appends: [],
              height: 0,
            }
            for(let value of values)
            {
              if(value.type==='prepend')
                result.prepends.push(value);
              else
                result.appends.push(value);

              result.height += value.height;
            }

            resolve(result);
          });
        });
      });
    }

    return this._improveTextReadabilityPromise;
  }

  eventListener = (data) => {
    data.contentItem = this.contentItem;
    data.spread = this;
    this.trigger(data.eventType, data);
  }

  addAssetsToStore = () => {
    return Promise.resolve();
  }

  regenerate(myconfig={}) {
    return new Promise(resolve => {
      const config = Object.assign({mode: 'rel'}, myconfig);
      if(this.regenerateCancel)
        this.regenerateCancel();

      const [promise, cancel] = this.getSuggestions(config);
      this.regenerateCancel = cancel;
      promise.then((result) => {
        if(result.suggestions.length>0)
        {
          this.setPages(toJS(result.suggestions[0].data.pages));
          this.save();

          this.resetPageSuggestions();
          this.resetCacheSuggestions();

          resolve(this);
        }
      });
    });
  }

  /**
   * Reset suggesties, forceert een fetch van de server
   */
  resetCacheSuggestions() {
    this._suggestionsCache = {};
  }

  resetPageSuggestions() {
    this._suggestions = [];
  }

  /**
   * 
   * @param {config} config {mode, cache, ...} 
   * 
   * retourneert de suggesties voor deze spread in deze mode
   */
  getSuggestions(myconfig={}) {
    const spreadType = this.pages[0].design.type==="cover" ? "cover" : "spread";
    const config = Object.assign({
      type: spreadType,
      mode: 'rel',

      current: {
        key: this.key,
        pages: this.pageData,
      },
    }, myconfig);

    const cacheKey = JSON.stringify(myconfig);
    if(myconfig.cache && this._suggestionsCache[cacheKey]!==undefined) {
      return [
        this._suggestionsCache[cacheKey][0],
        () => {} // We vervangen de cancel door een lege functie, data is al gecached, dus cancel heeft geen nut
      ];
    }

    const [promise, cancel] = this.store.user.FetchJSON(process.env.API_URL + '/album/' + this.store.album.key + '/spreads/suggestions', {
      method: 'POST',
      data: config,
    });

    this._suggestionsCache[cacheKey] = [new Promise(resolve => {
      promise.then(({data}) => {
        data.suggestions.map(suggestion => {
          suggestion.key = uuidv4();
          return suggestion;
        })

        resolve(data);
      })
      .catch(() => {});
    }), cancel];

    return this._suggestionsCache[cacheKey];
  }

  /**
   * Haalt suggesties op van de server die in de editoren als tips getoond worden
   */
  fetchSuggestions() {
    this._suggestions.clear();

    if(this.cancelSuggestions)
      this.cancelSuggestions();

    const [promise, cancel] = this.getSuggestions({mode: 'alt'});
    this.cancelSuggestions = cancel;

    promise.then(data => {
      runInAction(() => {
        this._suggestions.replace(data.suggestions.map(suggestion => {
          return suggestion;
        }));
      });
    })
    promise.catch(e => {
      console.error("Couldn't fetch suggestions", e);
    });
  }

  moveTo(position) {
    const afterSpread = this.store.content.items.find(item => item.key===position.after);
    this.contentItem.moveTo(afterSpread.index + 1);

    const [promise] = this.store.user.FetchJSON(process.env.API_URL + '/album/' + this.store.album.key + '/spreads/move', {
      method: 'POST',
      data: {
        subject: this.key,
        after: position.after,
      },
    });
    promise.catch(e => {
      console.error("Couldn't save spread move", e);
    });
  }

  /**
   * Retourneert computed gecachte versie van suggesties
   */
  get suggestions() {
    if(this._suggestions.length===0)
      requestAnimationFrame(this.fetchSuggestions.bind(this));

    return this._suggestions;
  }

  render(key, style)
  {
    return (
      <SpreadComponent
        key={"spread-"+key}
        id={key}
        style={style}
        contentItem={this.contentItem}

        spread={this}
        prepends={this.prepends}
        appends={this.appends}
        pageRows={this.pageRows}
        message={this.message}
        eventListener={this.eventListener}
        viewMode={this.viewMode}
      />
    );
  }
}

export default SpreadModel;