import { enableStaticRendering } from 'mobx-react'
import { observable, action, runInAction, reaction, makeObservable, computed } from "mobx";
import Router from 'next/router';
import { downloadFile } from 'utils/Utils';
const isSSR = (typeof window === "undefined")

// Nodig ivm serverside rendering, die gaat anders lekken omdat er geen unmounts e.d. zijn
enableStaticRendering(isSSR);

class AlbumStore {
  key = '';
  title = '';
  lastActivity = new Date(0);
  me = {
    loaded: false,
    role: 'guest',
    hasBookmarked: false,
  }
  access = {
    level: "hidden"
  }
  seo = {
  }
  thumbs = {};
  settings = {
    showContributors: false,
    showBookmarkers: false,
    showAnimations: false,
    showShareOptions: false,
    showMyAlbumBranding: false,
    searchIndexEnabled: false,
    canOrder: false,
    canComment: false,
    canLike: false,
    canDownload: false,
    canContribute: false
  }

  /**
   * @param {import('./root-store').default} store
   */
  constructor(store, data) {
    makeObservable(this, {
      key: observable,
      title: observable,
      me: observable.deep,
      settings: observable,

      hydrate: action,
      setBookmark: action,
      canDownload: computed,
      canContribute: computed,
    });

    this.store = store;
    this.hydrate(data);
    this.updateMe();
  }

  hydrate(data) {
    if (!data) return;

    this.key = data.key;
    this.title = data.title;
    this.lastActivity = new Date(data.lastActivity);
    this.access = data.access;
    this.thumbs = data.thumbs;
    this.seo = data.seo ? data.seo : {};

    if(data.settings)
      this.settings = Object.assign({}, this.settings, data.settings);
  }

  // Haalt rol van huidige gebruiker binnen dit album op
  updateMe() {
    if(typeof window === 'undefined')
      return;

    const [mePromise] = this.store.user.FetchJSON(process.env.API_URL + '/album/' + this.key + '/me');
    mePromise.then(res => {
      runInAction(() => {
        this.me = {...res.data, loaded: true};
      });
    });

    return mePromise;
  }

  updateSettings()
  {
    if(typeof window === 'undefined')
      return;

    const [promise] = this.store.user.FetchJSON(process.env.API_URL + '/album/' + this.key + '/settings');
    promise.then(res => {
      runInAction(() => {
        Object.assign(this.settings, {...res.data});
      });
    }).catch(() => {})
  }

  toggleBookmark()
  {
    this.setBookmark(!this.me.hasBookmarked);
  }

  setBookmark(state)
  {
    if(this.me.role === "owner")
      return;

    // Save current state
    const wasBookmarked = this.me.hasBookmarked;

    // Update local state
    this.me.hasBookmarked = state;

    // Update remote
    const method = state ? "POST" : "DELETE";
    const [promise] = this.store.user.FetchJSON(`${process.env.API_URL}/album/${this.key}/bookmark`, {
      method
    });
    promise.then(() => {
      this.updateMe();
    })
    .catch(e =>
    {
      console.error(e);
      this.me.hasBookmarked = wasBookmarked;
    });
  }

  get canDownload()
  {
    const hasDownloadFeature = this.store.user.subscription.features.download;
    const isOwner = this.me.role === "owner";

    return this.settings.canDownload || (isOwner && hasDownloadFeature);
  }

  get canContribute()
  {
    return (this.me.role !== "owner" && this.settings.canContribute);
  }

  canDownloadAsset(asset)
  {
    switch(asset.type)
    {
      case 'photo':
      case 'video':
        return true;
      default:
        return false;
    }
  }

  downloadAsset(assetKey)
  {
    const [promise] = this.store.user.FetchJSON(`${process.env.API_URL}/album/${this.key}/asset/${assetKey}/download`);
    promise.then(res =>
    {
      if(res.data.url)
      {
        downloadFile(res.data.url);
      }
    })
    .catch(e => console.error(e));
  }

  // Reload page en enable editmode
  reloadAndEnableEditMode() {
    Router.replace(Router.asPath).then(() => {
      this.store.assets.reloadAll();
    });
  }
}

export default AlbumStore;